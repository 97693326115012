import "unfetch/polyfill";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";

import moment from "moment";
import App from "./App";

import "./assets/styles/index.css";

moment.locale("en-nz");

const root = createRoot(document.getElementById("app"));
root.render(<App />);

if (!process.env.BRANCH) {
  module.hot.accept();
}
