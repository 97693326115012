import * as moment from 'moment'
import { Result, Ok, Err } from 'ts-results'
import { client, result } from './helpers'
import { Range } from '../hooks/useOrderTs'
import { dateString } from '../helpersTs'

type Report = {
  date: moment.Moment
  in_transfer: number
  in_stock: number
  value_on_hand: number
  value_in_transfer: number
  location?: {
    name: string
  } | null
}

const getReports = async (range: Range): Promise<Result<Report[], Error>> => {
  const data = await result((await client()).GetReports({ from: dateString(range.from), to: dateString(range.to) }))
  if (data.err) return data
  return Ok(data.val.reports)
}

export { Report, getReports }
