import { useEffect, useState, useRef } from "react";
import * as api from "../backend-v3/orders";

const useProducts = () => {
  const [allProducts, setAllProducts] = useState([] as api.ProductVariant[]);
  const [activeProducts, setActiveProducts] = useState([] as api.ProductVariant[]);

  function setUpProducts(prods: api.ProductVariant[]) {
    setAllProducts(prods);
    setActiveProducts(prods.filter(p => p.product.status === "ACTIVE" && p.deleted_at === undefined));
  }

  return {
    allProducts,
    setUpProducts,
    setAllProducts,
    activeProducts,
  };
};

export { useProducts };
