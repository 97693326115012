import React, { useState, useCallback, useRef, useEffect } from "react";
import * as PropTypes from "prop-types";
import toaster from "toasted-notes";

import { ErrorModal } from "../components/ErrorModal";
import { Toast } from "../components/Toast";

// import { getStatuses } from '../backend/status'

type Feedback = {
  setError: (err: string | undefined) => void;
  setToast: (m: string | undefined) => void;
  setSaved: (s: boolean | "error") => void;
  setLoading: (l: boolean) => void;
  saved: boolean | "error";
  loading: boolean;
};

const FeedbackContext = React.createContext<Feedback>({
  setToast: (m: string | undefined) => {},
  setError: (err: string | undefined) => {},
  setSaved: (s: boolean | "error") => {},
  setLoading: (l: boolean) => {},
  saved: true,
  loading: false,
});

const FeedbackProvider = ({ children }: any) => {
  const timer = useRef();
  const [error, setError] = useState<string>();
  const [saved, setSaved] = useState<boolean | "error">(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function setToast(message: string | undefined) {
    toaster.notify(({ onClose }) => <Toast message={message} onClose={onClose} />, {
      position: "bottom",
      duration: 5000,
    });
  }

  const contextValue = {
    setError,
    setToast: useCallback(message => setToast(message), []),
    setSaved,
    setLoading,
    loading,
    saved,
  };
  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
      {error && <ErrorModal onClose={() => setError(undefined)} error={error} />}
    </FeedbackContext.Provider>
  );
};

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Feedback, FeedbackContext, FeedbackProvider };
