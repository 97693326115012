import { useEffect, useState, useRef } from "react";
import * as api from "../backend-v3/orders";
import { useFeedback } from "./useFeedbackTs";
import * as moment from "moment";
import { Err, Result } from "ts-results";
import { OrderProductUpdate, ProductStatsRecord } from "../backend-v3/orders";
import { NewExpense, ExpenseUpdate } from "../backend-v3/invoices";
import { useProducts } from "./useProducts";

import { Invoice, InvoiceUpdate } from "../backend-v3/invoices";
import * as apiInvoice from "../backend-v3/invoices";
import { OrderProductsInvoiceWithVariantsFragment } from "../generated/graphql";

type Range = {
  from: moment.Moment;
  to: moment.Moment;
};

export type Search =
  | {
      status: "searching";
    }
  | { status: "found"; invoices: apiInvoice.InvoiceFastSearch[] }
  | { status: "not_initiated" };

export type Mapped = Record<number, OrderProductsInvoiceWithVariantsFragment>;

export function mapProducts(products: OrderProductsInvoiceWithVariantsFragment[] | undefined): Mapped | undefined {
  if (products) {
    let mapped = products.reduce((acc, cur) => {
      acc[cur.product_variant_id] = cur;
      return acc;
    }, <Mapped>{});

    return mapped;
  } else return undefined;
}

export type StatsQuery = {
  date: Range;
  location: number;
  supplier: number;
};

const useOrder = (orderId: number) => {
  const isMounted = useRef(true);
  const [isDeletingSingleProduct, setIsDeletingSingleProduct] = useState(false);
  const [isFetchingOrder, setIsFetchingOrder] = useState(false);
  const [isOrderModalLoading, setIsOrderModalLoading] = useState(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [order, setOrder] = useState<api.OrderExtended>();
  const { setError, setSaved, setToast, setLoading } = useFeedback();
  const [isUpdatingXero, setUpdatingXero] = useState(false);
  const [toRefresh, setRefresh] = useState(false);
  const [isFetchingPosition, setFetchingPosition] = useState(false);

  const [currentInvoice, setCurrentInvoice] = useState<apiInvoice.InvoiceWithProductsExpanded>();
  const [search, setSearch] = useState<Search>({ status: "not_initiated" });
  const [orderInvoices, setOrderInvoices] = useState<Invoice[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDeletingInvoice, setIsDeletingInvoice] = useState(false);
  const [orderRefresh, setOrderRefresh] = useState(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState(false);
  const [productStats, setProductStats] = useState<ProductStatsRecord>({});

  const initialDateRange: Range = {
    from: moment().subtract(30, "days"),
    to: moment(),
  };

  const [statsQuery, setStatsQuery] = useState<StatsQuery>();

  const { setAllProducts, activeProducts, setUpProducts } = useProducts();

  const [stockPositionTs, setStockPositionTs] = useState(initialDateRange);

  useEffect(() => {
    if (orderId) getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (order) {
      setOrderInvoices(order.invoices);
      setStatsQuery(statsQuery => {
        if (!statsQuery) {
          return {
            date: initialDateRange,
            location: order.location_id,
            supplier: order.supplier_id,
          };
        } else {
          return statsQuery;
        }
      });
    }
  }, [order]);

  useEffect(() => {
    if (toRefresh) {
      setAllProducts([]);
      getData();
      setRefresh(false);
    }
  }, [toRefresh]);

  async function refreshOrder() {
    setLoading(true);
    const res = await api.getOrder(orderId);
    if (!isMounted.current) return;
    if (res.err) {
      setNotFound(true);
    } else {
      setOrder(res.val);
    }
    setLoading(false);
  }

  async function refreshSalesForOrder(order: api.OrderExtended) {
    if (order.status === "draft") {
      refreshProductStats(stockPositionTs, order.order_id, order.location_id, order.supplier_id);
    }
  }

  async function getData() {
    setIsFetchingOrder(true);
    const res = await api.getOrder(orderId);
    if (!isMounted.current) return;
    if (res.err) {
      setNotFound(true);
    } else {
      const [_stats, allProdsData] = await Promise.all([
        refreshSalesForOrder(res.val),
        api.getAllAvailableProductsForSupplier(res.val.supplier_id),
      ]);
      if (allProdsData.err) {
        setNotFound(true);
      } else {
        setOrder(res.val);
        setUpProducts(allProdsData.val);
      }
    }
    setIsFetchingOrder(false);
  }

  async function sendToXero(invoiceId: number) {
    setUpdatingXero(true);

    const xero = await apiInvoice.sendToXero(invoiceId);
    if (xero.err) {
      setError(xero.val.message);
      return;
    } else {
      setCurrentInvoice(current => {
        if (current) {
          return { ...current, xero_invoice_id: xero.val };
        }
      });
    }
    setUpdatingXero(false);
  }

  async function receiveOrder() {
    setIsOrderModalLoading(true);
    await updateOrder(
      {
        orderStatus: api.OrderStatusUpdate.Received,
      },
      false,
      "Order received!"
    );
    setIsOrderModalLoading(false);
  }

  async function sendOrder() {
    await updateOrder(
      {
        orderStatus: api.OrderStatusUpdate.Sent,
      },
      false,
      "Order sent!"
    );
  }

  async function updateOrder(data: api.OrderUpdate, inline = false, message = "Order Updated!") {
    if (inline) {
      setSaved(false);
    } else {
      setIsOrderModalLoading(true);
    }
    let products: api.OrderProduct[] | undefined;
    let orderData: api.OrderExtra | undefined;
    let error: Error | undefined;

    if (order?.status === "draft" && data.orderStatus === "sent") {
      // if changing order from draft to sent we need  to refetch the products, as the backend filters out empty products.
      // it is a bit of a hackaround, ideally we shouldn't do this on a backend.
      const res = await api.updateOrderGerProducts(orderId, data);
      if (res.err) {
        error = res.val;
      } else {
        orderData = res.val;
        products = res.val.order_products;
      }
    } else {
      const res = await api.updateOrder(orderId, data);

      if (res.err) {
        error = res.val;
      } else {
        orderData = res.val;
      }
    }

    if (error) {
      setError(error.message);
      if (inline) {
        setSaved("error");
      }
    }

    if (orderData) {
      setOrder(x => {
        if (x) {
          if (products) {
            return { ...x, ...orderData, order_products: products };
          } else {
            return { ...x, ...orderData };
          }
        }

        return x;
      });
      if (inline) {
        setSaved(true);
      } else {
        setToast(message);
        setIsOrderModalLoading(false);
      }
    }
  }

  async function setStatus(orderStatus: api.OrderStatusUpdate) {
    updateOrder({ orderStatus });
  }

  async function refreshProductStats(range: Range, orderId: number, locationId: number, supplierId: number) {
    setFetchingPosition(true);
    const data =
      locationId === 0
        ? await api.getSalesAllLocations(orderId, range.from, range.to)
        : await api.getSales(orderId, locationId, range.from, range.to);
    if (!isMounted.current) return;
    if (data.err) setError(data.val.message);
    else {
      setProductStats(data.val);
    }
    setFetchingPosition(false);
  }

  async function addOrderProducts(pvIds: number[]) {
    setIsOrderModalLoading(true);
    const res = await api.addOrderProducts(orderId, pvIds);
    if (res.err) {
      setError(res.val.message);
    } else {
      if (order?.status === "draft" && statsQuery) {
        refreshProductStats(statsQuery.date, orderId, statsQuery.location, statsQuery.supplier);
      }
      setOrder(res.val);
      setToast("Products added!");
    }

    setIsOrderModalLoading(false);
  }

  async function deleteOrderProducts(opIds: number[], updatingOrder = true) {
    if (updatingOrder) setIsUpdatingOrder(true);

    const res = await api.deleteOrderProducts(opIds);
    if (res.err) {
      setError(res.val.message);
    } else {
      setOrder(order => {
        if (order) {
          const order_products = order.order_products.filter(op => !opIds.includes(op.id));
          return { ...order, order_products };
        }
        return order;
      });
    }

    setToast("Products removed!");

    if (updatingOrder) setIsUpdatingOrder(false);
  }

  async function deleteOrderProduct(opId: number, updatingOrder = true) {
    setIsDeletingSingleProduct(true);
    if (updatingOrder) setIsUpdatingOrder(true);
    let invoice: apiInvoice.InvoiceWithProductsExpanded | undefined = undefined;
    let success;
    if (currentInvoice) {
      const res = await api.deleteOrderProductGetInvoice(opId, currentInvoice.id, order?.order_id!);
      if (res.err) {
        setError(res.val.message);
        success = false;
      } else {
        invoice = res.val;
        success = true;
      }
    } else {
      const res = await api.deleteOrderProducts([opId]);
      if (res.err) {
        setError(res.val.message);
        success = false;
      } else {
        success = true;
      }
    }

    if (success) {
      setOrder(order => {
        if (order) {
          const order_products = order.order_products.filter(op => op.id !== opId);
          return { ...order, order_products };
        }
        return order;
      });
      if (invoice) {
        setCurrentInvoice(currentInvoice => {
          if (currentInvoice) {
            return invoice;
          }
        });
      }
      setToast("Products removed!");
    }

    if (updatingOrder) setIsUpdatingOrder(false);
    setIsDeletingSingleProduct(false);
  }

  async function updateOrderProduct(data: OrderProductUpdate) {
    setSaved(false);
    await updateOrderProducts([data], false);
    setSaved(true);
  }

  async function updateOrderProducts(data: OrderProductUpdate[], updating = true) {
    if (data.length > 0) {
      if (updating) setIsUpdatingOrder(true);
      const res = await api.updateOrderProducts(orderId, data);
      if (res.err) {
        setError(res.val.message);
      } else {
        setOrder(order => {
          if (order) {
            const new_order_products = order.order_products.map(op => {
              const update = data.find(u => u.id === op.id);
              if (update) {
                return {
                  ...op,
                  qty_ordered: update.qty_ordered !== undefined ? update.qty_ordered : op.qty_ordered,
                };
              }
              return op;
            });

            return { ...order, order_products: new_order_products };
          }
          return order;
        });
        if (updating) setIsUpdatingOrder(false);
      }
    }
  }

  async function processOrderExpenseQuery(
    f: () => Promise<Result<apiInvoice.InvoiceWithExpenses, Error>>,
    msg: string
  ) {
    setIsOrderModalLoading(true);

    const res = await f();

    if (res.err) {
      setError(res.val.message);
    } else {
      setCurrentInvoice(invoice => {
        if (invoice) {
          return { ...invoice, ...res.val };
        }
      });
      setToast(msg);
      setIsOrderModalLoading(false);
    }
  }

  async function createExpense(newExpense: NewExpense) {
    if (currentInvoice) {
      await processOrderExpenseQuery(() => apiInvoice.createExpense(currentInvoice.id, newExpense), "Expense created!");
    }
  }

  async function deleteExpense(expenseId: number) {
    await processOrderExpenseQuery(() => apiInvoice.deleteExpense(expenseId), "Expense removed!");
  }

  async function updateExpense(data: ExpenseUpdate) {
    if (data.amount !== undefined || data.description !== undefined) {
      setSaved(false);

      const res = await apiInvoice.updateExpense(data);
      if (res.err) {
        setError(res.val.message);
        setSaved("error");
      } else {
        setCurrentInvoice(invoice => {
          if (invoice) {
            return { ...invoice, ...res.val };
          }
        });
        setSaved(true);
      }
    }
  }

  async function loadInvoice(invoiceId?: number) {
    setLoading(true);
    if (invoiceId && order) {
      const res = await apiInvoice.getInvoiceForOrder(order.order_id, invoiceId);
      if (res.err) setError(res.val.message);
      else {
        setCurrentInvoice(res.val);
      }
    } else {
      setCurrentInvoice(undefined);
    }

    setLoading(false);
  }

  async function addExistingInvoice(invoiceId: number) {
    setLoading(true);
    if (order) {
      const res = await apiInvoice.insertOrderInvoice(invoiceId, order.order_id);
      if (res.err) setError(res.val.message);
      else {
        setSaved(true);
        setOrderRefresh(true);
        setOrderInvoices(prev => {
          return [...prev, res.val];
        });
        setCurrentInvoice(res.val);
      }
    }
    setLoading(false);
  }

  async function deleteOrderInvoice(invoiceId: number) {
    if (order) {
      setOrderRefresh(true);

      setIsDeletingInvoice(true);
      const res = await apiInvoice.deleteOrderInvoice(invoiceId, order.order_id);
      if (res.err) setError(res.val.message);
      else {
        setSaved(true);
        setOrderRefresh(true);
        setOrderInvoices(prev => {
          return prev.filter(i => i.id !== invoiceId);
        });
        setCurrentInvoice(undefined);
        // setMappedProducts(undefined);

        setToast("Invoice removed from the order");
      }
      setIsDeletingInvoice(false);
    }
  }

  async function createInvoice(invoiceNumber?: string) {
    if (order) {
      setSaved(false);
      const res = await apiInvoice.createInvoiceForOrder(order.order_id, invoiceNumber, order.supplier_id);
      if (res.err) setError(res.val.message);
      else {
        setOrderInvoices(prev => {
          return [...prev, res.val];
        });
        setCurrentInvoice(res.val);
        setOrderRefresh(true);

        setSaved(true);
      }
    }
  }

  async function searchInvoice(searchTermQuery: string) {
    if (order) {
      if (searchTermQuery === "") {
        setSearch({ status: "not_initiated" });
      } else {
        setSearch({ status: "searching" });
        const res = await apiInvoice.findInvoice(searchTermQuery, order.supplier_id);
        if (res.err) {
          setError(res.val.message);
          setSearch({ status: "not_initiated" });
        } else {
          setSearch({ status: "found", invoices: res.val });
        }
      }
    }
  }

  async function upsertInvoiceProducts(
    invoiceId: number,
    update: apiInvoice.UpdateOrderProductInvoice,
    type: "paid_per_unit" | "qty_received" | "both"
  ) {
    if (order) {
      setSaved(false);
      const res = await apiInvoice.upsertOrderInvoiceProducts(order.order_id, invoiceId, update, type);

      if (res.err) {
        setError(res.val.message);
      } else {
        setOrderRefresh(true);
        setCurrentInvoice(oldInvoice => {
          if (oldInvoice) {
            const orderProduct = res.val[0];
            const invoice = res.val[1];
            let updatedProduct = false;
            const productsUpdated = oldInvoice.products.map(oldProd => {
              if (orderProduct.product_variant_id === oldProd.product_variant_id) {
                updatedProduct = true;
                return orderProduct;
              } else return oldProd;
            });
            const products = updatedProduct ? productsUpdated : [...productsUpdated, orderProduct];
            return { ...oldInvoice, ...invoice, products };
          }
        });
        setOrder(order => {
          if (order) {
            const orderUpdated = res.val[2];
            const orderProductUpdated = res.val[3];
            return {
              ...order,
              ...orderUpdated,
              order_products: order.order_products.map(op => {
                return op.id === orderProductUpdated.id ? orderProductUpdated : op;
              }),
            };
          }
        });
      }
      setSaved(true);
    }
  }

  async function updateInvoice(id: number, update: InvoiceUpdate) {
    setSaved(false);
    setIsUpdatingInvoice(true);

    const res = await apiInvoice.updateInvoice(id, update);
    if (res.err) {
      setError(res.val.message);
    } else {
      setCurrentInvoice(invoice => {
        if (invoice && invoice.id === res.val.id) {
          return {
            ...res.val,
            products: invoice.products,
          };
        } else {
          return invoice;
        }
      });
      setOrderInvoices(prev => {
        return prev.map(invoice => {
          return invoice.id === res.val.id ? res.val : invoice;
        });
      });
      setSaved(true);
      setIsUpdatingInvoice(false);
    }
  }

  async function updateBinLocationOrderProduct(pvId: number, bin: string) {
    if (order) {
      setSaved(false);
      const res = await api.upsertBinLocation({
        product_variant_id: pvId,
        location_id: order.location_id,
        name: bin,
      });
      if (!isMounted.current) return;
      if (res.err) {
        setSaved("error");
        setError(res.val.message);
      }
      if (res.ok) {
        setSaved(true);
        setOrder(order => {
          if (order) {
            const orderUpdated: api.OrderExtended = {
              ...order,
              order_products: order.order_products.map(op => {
                if (op.product_variant.id === pvId) {
                  let updated_bin = { ...op.product_variant.bin_locations_record[order.location_id]!, name: bin };
                  let new_bin_record = {
                    ...op.product_variant.bin_locations_record,
                    [order.location_id]: updated_bin,
                  };

                  return {
                    ...op,
                    product_variant: { ...op.product_variant, bin_locations_record: new_bin_record },
                  };
                } else return op;
              }),
            };
            return orderUpdated;
          }
        });
      }
    }
  }

  return {
    isDeletingSingleProduct,
    isFetchingOrder,
    isOrderModalLoading,
    isUpdatingOrder,
    notFound,
    order,
    setStockPositionTs,
    stockPositionTs,
    sendToXero,
    isUpdatingXero,
    addOrderProducts,
    deleteOrderProduct,
    deleteOrderProducts,
    receiveOrder,
    sendOrder,
    setStatus,
    initialDateRange,
    updateOrderProducts,
    updateOrderProduct,
    updateOrder,
    activeProducts,
    refreshProductStats,

    createExpense,
    deleteExpense,
    updateExpense,
    isFetchingPosition,
    setOrder,

    refreshOrder,

    currentInvoice,
    loadInvoice,
    createInvoice,
    searchInvoice,
    search,
    orderInvoices,
    searchTerm,
    setSearchTerm,
    addExistingInvoice,
    deleteOrderInvoice,
    isDeletingInvoice,
    upsertInvoiceProducts,
    orderRefresh,
    setOrderRefresh,
    updateInvoice,
    isUpdatingInvoice,
    setCurrentInvoice,
    productStats,

    setRefresh,
    statsQuery,
    setStatsQuery,

    updateBinLocationOrderProduct,
  };
};

export { useOrder, Range, OrderProductUpdate };
