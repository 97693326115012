import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import { FaDownload, FaFileInvoiceDollar, FaEye, FaTrash } from "react-icons/fa";

import { OrdersFilter } from "./OrdersFilterTs";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableSortableColumnHeading } from "../../TableSortableColumnHeading";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { CONSTANTS } from "../../../common/constants";
import { roundToTwo } from "../../../helpers";
import { sortArrayByObjectKey, formatDate } from "../../../helpersTs";
import { PROP_TYPES } from "../../../common/propTypes";

import { Order } from "../../../backend-v3/orders";
import { Location } from "../../../backend-v3/locations";
import { Supplier, SupplierWithoutVendors } from "../../../backend-v3/suppliers";
import { Button } from "../../ButtonTs";
import { ExportToCsv } from "export-to-csv";
import { getInvoicesAction, invoicesQuery } from "../OrderTs/common";

type CsvData = {
  order_no: number;
  invoice_no: string;
  location: string;
  supplier: string;
  created: string;
  received: string;
  invoice_date: string;
  total_cost: number;
  url: string;
};

type Props = {
  currentTab: string;
  deleteOrder: (orderId: number) => void;
  locations: Location[];
  orders: Order[];
  sendToXero: (orderId: number) => void;
  processingXero: { [key: number]: boolean | undefined };
  suppliers: Supplier[];
};

const OrdersTable = ({ currentTab, deleteOrder, locations, orders, sendToXero, suppliers, processingXero }: Props) => {
  const [filteredOrders, setFilteredOrders] = useState(sortArrayByObjectKey(orders, "order_number", true));
  const [sorting, setSorting] = useState({
    key: "order_number" as any,
    ascending: true,
  });

  const isDraft = currentTab === CONSTANTS.STATUS.DRAFT ? true : null;
  const isSent = currentTab === CONSTANTS.STATUS.SENT ? true : null;
  const isReceived = currentTab === CONSTANTS.STATUS.RECEIVED ? true : null;
  const isPartiallyReceived = currentTab === CONSTANTS.STATUS.PARTIALLY_RECEIVED ? true : null;

  function sortedOrders() {
    const sorted = sortArrayByObjectKey(filteredOrders, sorting.key, true);
    return sorting.ascending ? sorted : sorted.reverse();
  }

  function handleSort(key: string) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    });
  }

  const csvExporter = new ExportToCsv({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  return (
    <>
      <OrdersFilter orders={orders} locations={locations} suppliers={suppliers} setFilteredOrders={setFilteredOrders} />
      <div className="mt-4 mb-4">
        <Button
          onClick={() => {
            const csvData: CsvData[] = filteredOrders.map(o => {
              const location = locations.find(l => l.id === o.location_id)?.name ?? "";
              return {
                order_no: o.order_number,
                invoice_no: o.invoices.map(x => x.invoice_number).join(" ") ?? "",
                location,
                supplier: o.supplier_short.name,
                created: o.order_created_at.toISOString(),
                received: o.received_at?.toISOString() ?? "",
                invoice_date: o.invoices.map(x => x.invoice_date?.toISOString()).join(" ") ?? "",
                total_cost: o.total,
                url: `https://skux-ui.groovy.cloud/orders/${o.order_id}`,
              };
            });
            csvExporter.generateCsv(csvData);
          }}
          iconBefore={<FaDownload />}
          rel="noopener noreferrer"
          value="Download"
          variant="ghost"
        ></Button>
      </div>

      <Table
        className="mt-7 max-w-7xl"
        emptyState={!orders.length && "No orders here..."}
        renderHead={() => (
          <TableRow>
            <TableHeadCell value="Order No." width="32" />
            {!isDraft && !isSent && <TableHeadCell value="Invoice No." width="32" />}
            <TableHeadCell value="Location" width="72" />
            {currentTab === "ALL" && (
              <TableHeadCell
                value={<TableSortableColumnHeading text="Status" onClick={() => handleSort("status")} />}
                width="72"
              />
            )}
            <TableHeadCell
              value={<TableSortableColumnHeading text="Supplier" onClick={() => handleSort("supplier_name")} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort("created_at")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Updated" onClick={() => handleSort("updated_at")} />}
              width="32"
            />

            {(isReceived || isPartiallyReceived) && (
              <TableHeadCell
                value={<TableSortableColumnHeading text="Received" onClick={() => handleSort("received_at")} />}
                width="72"
              />
            )}

            {isReceived && (
              <TableHeadCell
                value={<TableSortableColumnHeading text="Invoice date" onClick={() => handleSort("invoice_date")} />}
                width="72"
              />
            )}

            <TableHeadCell value="Total Cost" width="48" />
            {(isReceived || isDraft) && <TableHeadCell alignment="right" width="72" value="Actions" />}
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedOrders().map(order => {
              const {
                order_id,
                order_created_at,
                location_id,
                order_number,
                total,
                status,
                supplier_short,
                version_created_at,
                received_at,
                invoices,
              } = order;

              const sendInvoicesStatus = getInvoicesAction(invoices);

              const actions = {
                delete: {
                  id: "delete",
                  iconBefore: <FaTrash />,
                  onClick: (e: Event) => {
                    e.stopPropagation();
                    deleteOrder(order_id);
                  },
                },
                // send: {
                //   id: "sendToXero",
                //   loading: processingXero[order_id] === true,
                //   iconBefore: <FaFileInvoiceDollar />,
                //   onClick: (e: Event) => {
                //     e.stopPropagation();
                //     sendToXero(order_id);
                //   },
                //   value: sendInvoicesStatus,
                //   variant: sendInvoicesStatus === "Resend all" ? "ghost" : "secondary",
                // },
              };

              function getActions() {
                if (isDraft) return [actions.delete];
                // if (invoices.length > 0) return [actions.send];
                return [];
              }
              const locationName = locations.find(l => l.id === location_id)?.name;

              return (
                <TableRow key={order_id} onClick={() => navigate(`/orders/${order_id}`)}>
                  <TableCell value={{ type: "number", initial: order_number }} />
                  {/* todo: TableCell should accept array of values */}
                  {!isDraft && !isSent && (
                    <>
                      <TableCell
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(invoicesQuery(order.invoices));
                        }}
                        value={{ type: "string", initial: order.invoices.map(x => x.invoice_number).join(" ") }}
                      />
                    </>
                  )}
                  {location && <TableCell value={{ type: "string", initial: locationName }} />}
                  {currentTab === "ALL" && (
                    <TableCell value={{ type: "string", initial: status.toLowerCase().replace("_", " ") }} />
                  )}
                  <TableCell value={{ type: "string", initial: supplier_short.name }} />
                  <TableCell value={{ type: "string", initial: formatDate(order_created_at) }} />
                  <TableCell value={{ type: "string", initial: formatDate(version_created_at) }} />

                  {(isReceived || isPartiallyReceived) && (
                    <TableCell value={{ type: "string", initial: received_at ? formatDate(received_at) : undefined }} />
                  )}

                  {isReceived && (
                    <TableCell
                      value={{
                        type: "string",
                        initial:
                          invoices.length > 0
                            ? invoices
                                .map(x => (x.invoice_date !== undefined ? formatDate(x.invoice_date) : undefined))
                                .join(" ")
                            : undefined,
                      }}
                    />
                  )}
                  <TableCell value={{ type: "string", initial: `$${roundToTwo(total)}` }} />
                  {<TableRowActions actions={getActions()} />}
                </TableRow>
              );
            })}
          </>
        )}
      />
    </>
  );
};

export { OrdersTable };
