
import { useEffect, useState, useRef } from 'react'
import { navigate } from '@reach/router'
import * as api from '../backend-v3/stocktakes'
import { sortArrayByObjectKey } from '../helpersTs'
import { useFeedback } from './useFeedbackTs'

const useStocktake = (stocktakeId: number) => {
  const isMounted = useRef(true)
  const [isFetchingStocktake, setIsFetchingStocktake] = useState(false)
  const [isStocktakeModalLoading, setIsStocktakeModalLoading] = useState(false)
  const [isUpdatingStocktake, setIsUpdatingStocktake] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [stocktake, setStocktake] = useState<undefined | api.StocktakeWithProducts>(undefined)
  const { setError, setSaved, setToast } = useFeedback()

  useEffect(() => {
    if (stocktakeId) getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingStocktake(true)
    const res = await api.getStocktake(stocktakeId)
    if (!isMounted.current) return

    if (res.err) {
      setNotFound(true)
    } else {
      setStocktake(res.val)
    }

    setIsFetchingStocktake(false)
  }

  async function beginStocktake() {
    setIsStocktakeModalLoading(true)
    const res = await api.startStocktake(stocktakeId)

    if (!isMounted.current) return
    if (res.err) setError(res.val.message)
    if (res.ok) setStocktake(res.val)

    setIsStocktakeModalLoading(false)
  }

  async function completeStocktake() {
    setIsStocktakeModalLoading(true)

    const res = await api.stopStocktake(stocktakeId)
    if (!isMounted.current) return
    if (res.err) setError(res.val.message)
    if (res.ok) {
      navigate('/stocktakes?tab=counted')
      setToast('Stock counted!')
    }

    setIsStocktakeModalLoading(false)
  }

  //   async function updateStocktake(data, inline = false) {
  //     if (inline) {
  //       setSaved(false)
  //     } else {
  //       setIsStocktakeModalLoading(true)
  //     }

  //     const res = await update(stocktakeId, data)

  //     if (!isMounted.current) return
  //     if (!res.success) setError(res)
  //     if (!res.success && inline) setSaved('error')

  //     if (res.success) {
  //       setStocktake(prev => ({
  //         ...prev,
  //         status: res.data.status,
  //         ...data,
  //       }))
  //     }
  //     if (res.success && inline) setSaved(true)
  //     if (res.success && !inline) setToast('Stocktake updated!')

  //     if (!inline) setIsStocktakeModalLoading()
  //   }


  async function updateStocktakeProduct(update: api.StocktakeProductUpdate) {
    if (update.count < 0) {
      setError('No negative values allowed!')
      return
    }
    setSaved(false)
    const res = await api.updateStocktakeProduct(update)
    if (!isMounted.current) return
    if (res.err) {
      setSaved('error')
      setError(res.val.message)
    } else {
      setStocktake(prev => {
        if (prev === undefined) return prev
        return {
          ...prev, products: prev.products.map(product => (product.product_variant.id === update.product_variant_id ? { ...product, qty_counted : res.val} : product))
        }
      })
      setSaved(true)
    }
  }

  return {
    beginStocktake,
    completeStocktake,
    stocktake,
    isFetchingStocktake,
    isStocktakeModalLoading,
    isUpdatingStocktake,
    notFound,
    updateStocktakeProduct,
  }
}

export { useStocktake }
