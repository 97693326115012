import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";

import { AutoComplete } from "./AutoCompleteTs";
import { AutoCompleteResult } from "./AutoCompleteResult";
import { ErrorInline } from "./ErrorInline";
import { Loading } from "./Loading";
import { Modal } from "./Modal";

import { OrderProduct, ProductVariant, ProductVariantInfoWithSupplierCode } from "../backend-v3/orders";

import { sortArrayByObjectKey, sortArrayByObjectSubKey } from "../helpersTs";

type Props = {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (x: number[]) => void;
  currentProductsVariantIds: number[];
  allProducts: ProductVariant[];
};

const AddProductModal = ({ isOpen, loading, onClose, onSubmit, currentProductsVariantIds, allProducts }: Props) => {
  const [selections, setSelections] = useState<number[]>();
  const [onlyNewProducts, setOnlyNewProducts] = useState<ProductVariantInfoWithSupplierCode[]>();

  useEffect(() => {
    if (allProducts.length > 0) {
      let existingProductsInOrder: { [key: number]: boolean } = {};
      currentProductsVariantIds.map(product_variant_id => {
        existingProductsInOrder[product_variant_id] = true;
      });
      let results = allProducts.filter(product => !(product.id in existingProductsInOrder));
      const sorted = sortArrayByObjectKey(results, "full_title");
      setOnlyNewProducts(sorted);
    } else {
      setOnlyNewProducts([]);
    }
  }, [allProducts]);

  function getResults(q: string | undefined): ProductVariantInfoWithSupplierCode[] {
    if (!q || !onlyNewProducts) return onlyNewProducts ?? [];
    const result = onlyNewProducts.reduce(
      (acc, cur) =>
        cur.full_title.toLowerCase().includes(q) ||
        (cur.sku && cur.sku.toLowerCase().includes(q)) ||
        cur.product.vendor.name.toLowerCase().includes(q)
          ? [...acc, cur]
          : acc,
      [] as ProductVariantInfoWithSupplierCode[]
    );

    return result;
  }

  function handleSetSelections(product: ProductVariantInfoWithSupplierCode) {
    setSelections(prev => {
      if (!prev) return [product.id];

      return prev.some(s => s === product.id) ? prev.filter(s => s !== product.id) : [...prev, product.id];
    });
  }

  function renderResult(product: ProductVariantInfoWithSupplierCode) {
    const { id, sku } = product;

    return (
      <AutoCompleteResult
        key={id}
        selected={selections && selections.some(s => s === id)}
        onClick={() => handleSetSelections(product)}
      >
        <div className="w-64">{sku}</div>
        <div>{product.full_title}</div>
      </AutoCompleteResult>
    );
  }

  return (
    <Modal
      closeModal={onClose}
      disabled={!selections}
      heading="Add Products"
      loading={loading}
      isOpen={isOpen}
      maxWidth="6xl"
      onSubmit={() => {
        if (selections) onSubmit(selections);
      }}
      submitIcon={<FaPlus />}
      submitValue="Add Products"
    >
      {onlyNewProducts && (
        <AutoComplete
          className="mt-6 w-full"
          getResults={getResults}
          id="product-query"
          placeholder="Search by product name, sku or vendor"
          renderResult={renderResult}
        />
      )}
      {onlyNewProducts === undefined && <Loading loading />}
      {/* {error && <ErrorInline error={error} />} */}
    </Modal>
  );
};

export { AddProductModal };
