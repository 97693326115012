import moment from "moment";
import { Err, Ok, Result } from "ts-results";
import {
  InvoiceExpensesFieldsFragment,
  InvoiceFieldsFragment,
  OrderProductsInvoiceFragment,
  OrderProductsInvoiceWithVariantsFragment,
} from "../generated/graphql";
import { roundToTwo } from "../helpersTs";
import { getAuth } from "./auth";
import { client, result } from "./helpers";
import { OrderExtended, OrderExtra, OrderProduct, processOrder, processOrderProduct, processSupplier } from "./orders";

export type InvoiceExpense = {
  id: number;
  amount: number;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  description?: string;
};

export type Invoice = {
  created_at: moment.Moment;
  payment_due?: moment.Moment;
  invoice_number?: string;
  invoice_date?: moment.Moment;
  xero_invoice_id?: string;
  id: number;
  total_received: number;
  total_expenses: number;
  adjustments: number;
  total: number;
  total_with_gst: number;
  supplier: {
    id: number;
    name: string;
  };
};

export type InvoiceFastSearch = {
  id: number;
  invoice_number: string;
};

// export type InvoiceWithProducts = Invoice & {
//   expenses: InvoiceExpense[];
//   products: OrderProductsInvoiceFragment[];
// };

export type InvoiceWithExpenses = Invoice & {
  expenses: InvoiceExpense[];
};

export type InvoiceWithProductsExpanded = InvoiceWithExpenses & {
  products: OrderProductsInvoiceWithVariantsFragment[];
};

export type ExpenseUpdate = {
  id: number;
  description?: string;
  amount?: number;
};

export type NewExpense = {
  description: string;
  dollars: number;
  cents: string;
};

export function processInvoiceExpense(expense: InvoiceExpensesFieldsFragment): InvoiceExpense {
  return {
    ...expense,
    description: expense.description === null ? undefined : expense.description,
    created_at: moment(expense.created_at),
    updated_at: moment(expense.updated_at),
  };
}

export const processInvoice = (invoice: InvoiceFieldsFragment): Invoice => {
  return {
    id: invoice.id!,
    created_at: moment(invoice.created_at),
    payment_due: invoice.payment_due ? moment(invoice.payment_due) : undefined,
    invoice_date: invoice.invoice_date ? moment(invoice.invoice_date) : undefined,
    invoice_number: invoice.invoice_number ? invoice.invoice_number : undefined,
    xero_invoice_id: invoice.xero_invoice_id ? invoice.xero_invoice_id : undefined,
    total: invoice.total!,
    total_received: invoice.total_received!,
    total_expenses: invoice.total_expenses!,
    adjustments: invoice.adjustments!,
    total_with_gst: invoice.total_with_gst,
    supplier: invoice.supplier!,
  };
};

export const getInvoiceForOrder = async (
  orderId: number,
  id: number
): Promise<Result<InvoiceWithProductsExpanded, Error>> => {
  const data = await result((await client()).GetInvoiceForOrder({ orderId, id }));
  if (data.err) return data;
  const invoice = data.val.invoices_view.pop();
  if (invoice === undefined || invoice === null) return Err(Error("invoice doesnt exist"));
  let products = invoice.order_invoices.pop()?.order_products_invoices ?? [];
  // order_expenses: order.order_expenses.map(processOrderExpense),

  return Ok({ ...processInvoice(invoice), products, expenses: invoice.invoice_expenses.map(processInvoiceExpense) });
};

export const getInvoice = async (id: number): Promise<Result<InvoiceWithProductsExpanded, Error>> => {
  const data = await result((await client()).GetInvoice({ id }));
  if (data.err) return data;
  const invoice = data.val.invoices_view.pop();
  if (invoice === undefined || invoice === null) return Err(Error("invoice doesnt exist"));
  let products = invoice.order_products_invoices;
  return Ok({ ...processInvoice(invoice), products, expenses: invoice.invoice_expenses.map(processInvoiceExpense) });
};

export const getInvoices = async (): Promise<Result<Invoice[], Error>> => {
  const data = await result((await client()).GetInvoices());
  if (data.err) return data;
  return Ok(data.val.invoices_view.map(processInvoice));
};

export const deleteInvoice = async (id: number): Promise<Result<undefined, Error>> => {
  const data = await result((await client()).DeleteInvoice({ id }));
  if (data.err) return data;
  return Ok(undefined);
};

export const findInvoice = async (
  invoiceNoSearch: string,
  supplierId: number
): Promise<Result<InvoiceFastSearch[], Error>> => {
  const auth = await getAuth();
  if (auth.err) return auth;

  const data = await result(
    (await client()).FindInvoices({
      invoiceNoSearch: `%${invoiceNoSearch}%`,
      shopId: auth.val.user.shop?.id!,
      supplierId,
    })
  );
  if (data.err) return data;
  let filtered: InvoiceFastSearch[] = data.val.invoices.filter((i): i is InvoiceFastSearch => {
    return typeof i.invoice_number === "string";
  });
  return Ok(filtered);
};

export const createInvoiceForOrder = async (
  orderId: number,
  invoiceNumber: string | undefined,
  supplierId: number
): Promise<Result<InvoiceWithProductsExpanded, Error>> => {
  const auth = await getAuth();
  if (auth.err) return auth;
  const data = await result(
    (await client()).InsertInvoice({ orderId, shopId: auth.val.user.shop?.id!, invoiceNumber, supplierId })
  );
  if (data.err) return data;
  const invoice = data.val.insert_order_invoices_one;
  if (!invoice) return Err(Error("empty result when creating new invoice"));
  let products = invoice.order_products_invoices;
  let invoice_view = invoice.invoice.invoice_view!;

  return Ok({
    ...processInvoice(invoice_view),
    expenses: invoice_view.invoice_expenses.map(processInvoiceExpense),
    products,
  });
};

export const deleteOrderInvoice = async (invoiceId: number, orderId: number): Promise<Result<undefined, Error>> => {
  const data = await result((await client()).DeleteOrderInvoice({ orderId, invoiceId }));
  if (data.err) return data;
  return Ok(undefined);
};

export const insertOrderInvoice = async (
  invoiceId: number,
  orderId: number
): Promise<Result<InvoiceWithProductsExpanded, Error>> => {
  const data = await result((await client()).InsertOrderInvoice({ orderId, invoiceId }));
  if (data.err) return data;
  let invoiceData = data.val.insert_order_invoices_one!;
  let products = invoiceData.order_products_invoices;
  let invoice_view = invoiceData.invoice.invoice_view!;

  return Ok({
    ...processInvoice(invoice_view),
    products,
    expenses: invoice_view.invoice_expenses.map(processInvoiceExpense),
  });
};

export type UpdateOrderProductInvoice = {
  product_variant_id: number;
  qty_received: number;
  paid_per_unit: number;
};

export const upsertOrderInvoiceProducts = async (
  order_id: number,
  invoice_id: number,
  update: UpdateOrderProductInvoice,
  type: "paid_per_unit" | "qty_received" | "both"
): Promise<Result<[OrderProductsInvoiceWithVariantsFragment, Invoice, OrderExtra, OrderProduct], Error>> => {
  const object = { ...update, order_id, invoice_id };
  let data;

  if (type === "paid_per_unit") {
    data = await result((await client()).UpsertOrderProductInvoicesPaidPerUnit({ object }));
  } else if (type === "qty_received") {
    data = await result((await client()).UpsertOrderProductInvoicesQtyReceived({ object }));
  } else {
    data = await result((await client()).UpsertOrderProductInvoices({ object }));
  }

  if (data.err) return data;

  const order = data.val.insert_order_products_invoice_one?.order_view!;

  let orderProduct = processOrderProduct(data.val.insert_order_products_invoice_one!.order_product_view!);

  const orderExtended: OrderExtra = {
    ...processOrder(order),
    location: order.location!,
    supplier: processSupplier(order.supplier!),
  };

  return Ok([
    data.val.insert_order_products_invoice_one!,
    processInvoice(data.val.insert_order_products_invoice_one!.invoice_view!),
    orderExtended,
    orderProduct,
  ]);
};

export const deleteOrderInvoceProduct = async (
  orderId: number,
  invoiceId: number,
  pvId: number
): Promise<Result<Invoice, Error>> => {
  const data = await result((await client()).DeleteOrderProductInvoice({ orderId, invoiceId, pvId }));
  if (data.err) return data;
  return Ok(processInvoice(data.val.delete_order_products_invoice_by_pk?.invoice.invoice_view!));
};

export type InvoiceUpdate = {
  payment_due?: string | null;
  invoice_number?: string | null;
  invoice_date?: string | null;
  adjustments?: number | null;
};

export async function updateInvoice(id: number, set: InvoiceUpdate): Promise<Result<InvoiceWithExpenses, Error>> {
  const res = await result((await client()).UpdateInvoice({ id, set }));
  if (res.err) return res;
  let invoice = res.val.update_invoices_by_pk?.invoice_view!;

  return Ok({ ...processInvoice(invoice), expenses: invoice.invoice_expenses.map(processInvoiceExpense) });
}

export async function deleteExpense(id: number): Promise<Result<InvoiceWithExpenses, Error>> {
  const data = await result((await client()).DeleteExpense({ id }));
  if (data.err) return data;
  const invoice = data.val.delete_invoice_expenses_by_pk?.invoice_view!;
  return Ok({ ...processInvoice(invoice), expenses: invoice.invoice_expenses.map(processInvoiceExpense) });
}

export async function createExpense(invoiceId: number, data: NewExpense): Promise<Result<InvoiceWithExpenses, Error>> {
  const res = await result(
    (await client()).CreateExpenses({
      expense: {
        invoice_id: invoiceId,
        description: data.description,
        amount: Number(roundToTwo(parseFloat(`${data.dollars}.${data.cents}`))),
      },
    })
  );
  if (res.err) return res;

  let invoice = res.val.insert_invoice_expenses_one?.invoice.invoice_view!;

  return Ok({ ...processInvoice(invoice), expenses: invoice.invoice_expenses.map(processInvoiceExpense) });
}

export async function updateExpense(data: ExpenseUpdate): Promise<Result<InvoiceWithExpenses, Error>> {
  const expenseUpdate = {
    description: data.description,
    amount: data.amount,
  };

  const c = await result((await client()).UpdateExpense({ id: data.id, expenseUpdate }));
  if (c.err) return c;
  let invoice = c.val.update_invoice_expenses_by_pk?.invoice.invoice_view!;

  return Ok({ ...processInvoice(invoice), expenses: invoice.invoice_expenses.map(processInvoiceExpense) });
}

export async function sendToXero(invoiceId: number): Promise<Result<string, Error>> {
  const data = await result((await client()).SendToXero({ invoiceId }));
  if (data.err) return data;
  return Ok(data.val.xero.createInvoice);
}
