import { client, request, result } from "./helpers";
import { Result, Ok, Err } from "ts-results";
import { StocktakeFieldsFragment, StocktakeProductFieldsFragment } from "../generated/graphql";
import moment from "moment";
import { Location } from "./locations";
import { Vendor } from "./suppliers";
import { processProductVariant, ProductVariant } from "./orders";
import { getAuth } from "./auth";

type StocktakeStatus = "draft" | "in_progress" | "counted";

type Stocktake = {
  id: number;
  stocktake_number: number;
  created_at: moment.Moment;
  counted_at?: moment.Moment;
  location: Location;
  vendor: Vendor | undefined;
  vendor_name: string | undefined;
  status: StocktakeStatus;
  location_id: number;
  total: number;
  updated_at: moment.Moment;
};

type StocktakeProduct = {
  product_variant: ProductVariant;
  qty_expected: number;
  qty_counted: number | null;
  cost: number;
};

type StocktakeWithProducts = {
  stocktake: Stocktake;
  products: StocktakeProduct[];
};

type StocktakeCreate = {
  location_id: number;
  vendor_id: number | null;
};

type StocktakeProductUpdate = {
  product_variant_id: number;
  count: number;
  stocktake_id: number;
};

const processStocktake = (data: StocktakeFieldsFragment): Stocktake => {
  return {
    id: data.id!,
    stocktake_number: data.stocktake_number!,
    created_at: moment(data.created_at),
    counted_at: data.counted_at ? moment(data.counted_at) : undefined,
    location: data.location!,
    vendor: data.vendor === null ? undefined : data.vendor,
    status: data.status,
    vendor_name: data.vendor?.name,
    location_id: data.location!.id,
    total: data.total,
    updated_at: moment(data.updated_at),
  };
};

const processStocktakeProducts = (data: StocktakeProductFieldsFragment): StocktakeProduct => {
  return {
    product_variant: processProductVariant(data.product_variant!),
    qty_expected: data.qty_expected!,
    qty_counted: data.qty_counted == undefined || data.qty_counted == null ? null : data.qty_counted,
    cost: data.cost,
  };
};

const getStocktake = async (id: number): Promise<Result<StocktakeWithProducts, Error>> => {
  let c = await result((await client()).GetStocktake({ id }));
  if (c.err) return c;
  return Ok({
    stocktake: processStocktake(c.val.stocktake_view[0]!),
    products: c.val.stocktake_view[0]!.stocktake_products.map(processStocktakeProducts),
  });
};

const getStocktakes = async (): Promise<Result<Stocktake[], Error>> => {
  let c = await result((await client()).GetStocktakes());
  if (c.err) return c;
  return Ok(c.val.stocktake_view.map(processStocktake));
};

const startStocktake = async (id: number): Promise<Result<StocktakeWithProducts, Error>> => {
  let c = await result((await client()).StartStocktake({ id }));
  if (c.err) return c;
  return getStocktake(id);
};

const stopStocktake = async (id: number): Promise<Result<undefined, Error>> => {
  let c = await result((await client()).StopCounting({ id }));
  if (c.err) return c;
  return Ok(undefined);
};

const createStocktake = async (data: StocktakeCreate): Promise<Result<number, Error>> => {
  const auth = await getAuth();
  if (auth.err) return auth;

  let c = await result((await client()).CreateStocktake({ ...data, shop_id: Number(auth.val.user.shop!.id) }));
  if (c.err) return c;
  return Ok(c.val.insert_stocktakes_one!.id);
};

async function deleteStocktake(id: number): Promise<Result<undefined, Error>> {
  let c = await result((await client()).DeleteStocktake({ id }));
  if (c.err) return c;
  return Ok(undefined);
}

async function updateStocktakeProduct(update: StocktakeProductUpdate): Promise<Result<number, Error>> {
  let c = await result((await client()).UpdateStocktakeProduct(update));
  if (c.err) return c;
  return Ok(c.val.update_stocktake_locked?.returning[0]?.qty_counted!);
}

export {
  updateStocktakeProduct,
  stopStocktake,
  deleteStocktake,
  startStocktake,
  getStocktakes,
  getStocktake,
  createStocktake,
  StocktakeProductUpdate,
  StocktakeStatus,
  StocktakeCreate,
  StocktakeWithProducts,
  StocktakeProduct,
  Stocktake,
};
