import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { FaTrash } from 'react-icons/fa'

import { Table } from '../../TableTs'
import { TableCell } from '../../TableCellTs'
import { TableHeadCell } from '../../TableHeadCell'
import { TableSortableColumnHeading } from '../../TableSortableColumnHeading'
import { TableRow } from '../../TableRowTs'
import { TableRowActions } from '../../TableRowActions'
import { TransfersFilter } from './TransfersFilter'
import { formatDate, sortArrayByObjectKey, roundToTwo } from '../../../helpersTs'
import { Location } from '../../../backend-v3/locations'
import { Transfer, TransferStatus } from '../../../backend-v3/transfers'

type Props = {
  deleteTransfer: (transferId: number) => void,
  locations: Location[],
  transfers: Transfer[],
  status: TransferStatus
}

const TransfersTable = ({ deleteTransfer, locations, transfers, status }: Props) => {
  const [filtered, setFiltered] = useState(transfers)
  const [sorting, setSorting] = useState({
    key: ('updated_at' as keyof Transfer),
    ascending: true,
  })

  const isFulfilled = status === 'fulfilled'
  const isReceived = status === 'done'

  function sortedTransfers() {
    const sorted = sortArrayByObjectKey(filtered, sorting.key, true)
    return sorting.ascending ? sorted : sorted.reverse()
  }

  function handleSort(key: (keyof Transfer)) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    })
  }

  return (
    <>
      <TransfersFilter
        filterKeys={[
          { label: 'Location To', value: 'to_location_id' },
          { label: 'Location From', value: 'from_location_id' },
        ]}
        locations={locations}
        transfers={transfers}
        setFilteredTransfers={setFiltered}
      />
      <Table
        className="mt-6 max-w-5xl"
        emptyState={!transfers.length && 'No transfers here...'}
        renderHead={() => (
          <TableRow>
            <TableHeadCell value="No." width="32" />
            <TableHeadCell
              value={<TableSortableColumnHeading text="From Location" onClick={() => handleSort('from_location_id')} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="To Location" onClick={() => handleSort('to_location_id')} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort('created_at')} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Updated" onClick={() => handleSort('updated_at')} />}
              width="32"
            />
            <TableHeadCell value="Total Value" width="48" />
            {!isFulfilled && !isReceived && <TableHeadCell alignment="right" width="64" value="Actions" />}
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedTransfers().map(({ id, created_at, from_location_id, to_location_id, transfer_number, total, updated_at }) => (
              <TableRow key={id} onClick={() => navigate(`/transfers/${id}`)}>
                <TableCell value={{
                  type: 'number',
                  initial: transfer_number
                }} />
                <TableCell 
                  value={{
                    type: 'string',
                    initial: locations.find(location => location.id === from_location_id)?.name
                  }} />
                
                <TableCell 
                  value={{
                    type: 'string',
                    initial: locations.find(location => location.id === to_location_id)?.name
                  }} />

                <TableCell 
                  value={{
                    type: 'string',
                    initial: formatDate(created_at)
                  }} />

                <TableCell 
                  value={{
                    type: 'string',
                    initial: formatDate(updated_at)
                  }} />

                <TableCell 
                  value={{
                    type: 'string',
                    initial: `$${roundToTwo(total)}`
                  }} />

                {!isFulfilled && !isReceived && (
                  <TableRowActions
                    actions={[
                      {
                        id: 'delete',
                        iconBefore: <FaTrash />,
                        onClick: e => {
                          e.stopPropagation()
                          deleteTransfer(id)
                        },
                      },
                    ]}
                  />
                )}
              </TableRow>
            ))}
          </>
        )}
      />
    </>
  )
}


export { TransfersTable }
