import React, { useState, useCallback } from 'react'
import { debounce } from 'throttle-debounce'
import { FaExclamationTriangle } from 'react-icons/fa'

import { Textarea } from '../../Textarea'

type Props = {
  loading: boolean,
  notes?: string,
  updateOrder: (x: string, inline?: boolean) => void
}

const OrderNotes = ({ loading, notes = '', updateOrder }: Props) => {
  const [value, setValue] = useState(notes)

  const handleSaveNotes = useCallback(debounce(850, updateOrder), [])

  return (
    <div className="inline-block">
      <div className="flex justify-end flex-wrap mt-4">
        <Textarea
          disabled={loading}
          label="Notes"
          onChange={(e) => {
            setValue(e.target.value)
            handleSaveNotes(e.target.value, true)
          }}
          value={value}
        />
        <div className="w-full text-center">
          <p className="mt-4 rounded text-gray-400">
            <span className="mr-2 inline-block -mb-1 text-xl">
              <FaExclamationTriangle />
            </span>{' '}
            For internal use - not shown on purchase order
          </p>
        </div>
      </div>
    </div>
  )
}



export { OrderNotes }
