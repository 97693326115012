import { useEffect, useState, useRef } from "react";
import { navigate } from "@reach/router";
import { sendToXero as send } from "../backend-v3/invoices";

import { getOrders, createOrder as create, deleteOrder as destroy, Order, NewOrder } from "../backend-v3/orders";
import { useFeedback } from "./useFeedbackTs";
import { CONSTANTS } from "../common/constants";

const useOrders = () => {
  const isMounted = useRef(true);
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const [isUpdatingOrders, setIsUpdatingOrders] = useState(false);
  const [processingXero, setProcessingXero] = useState({} as { [key: number]: boolean | undefined });
  const [orders, setOrders] = useState([] as Order[]);
  const { setError, setToast } = useFeedback();

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setIsFetchingOrders(true);
    const orders = await getOrders();
    if (orders.err) {
      setError(orders.val.message);
      return;
    }
    setOrders(orders.val);
    setIsFetchingOrders(false);
  }

  async function createOrder(newOrder: NewOrder) {
    setIsUpdatingOrders(true);
    const orderId = await create(newOrder);

    if (orderId.err) {
      setError(orderId.val.message);
      return;
    }

    if (!isMounted.current) return;
    setToast(`Order created!`);
    navigate(`/orders/${orderId.val}`);
    setIsUpdatingOrders(false);
  }

  async function deleteOrder(orderId: number) {
    setIsUpdatingOrders(true);
    const res = await destroy(orderId);

    if (res.err) {
      setError(res.val.message);
      return;
    }

    if (!isMounted.current) return;
    setOrders(prevOrders => prevOrders.filter(order => order.order_id !== orderId));
    setToast("Deleted!");
    setIsUpdatingOrders(false);
  }

  async function sendToXero(orderId: number) {
    setProcessingXero(processingXero => {
      processingXero[orderId] = true;
      return processingXero;
    });
    const res = await send(orderId);

    if (res.err) {
      setError(res.val.message);
      return;
    }

    setOrders(prev => prev.map(order => (order.order_id === orderId ? { ...order, xero_invoice_id: res.val } : order)));
    setToast("Sent to Xero!");
    setProcessingXero(processingXero => {
      delete processingXero[orderId];
      return processingXero;
    });
  }

  return {
    createOrder,
    deleteOrder,
    isFetchingOrders,
    isUpdatingOrders,
    orders,
    sendToXero,
    processingXero,
  };
};

export { useOrders };
