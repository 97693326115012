import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import PropTypes from "prop-types";
import { debounce } from "throttle-debounce";

type StringType = {
  type: "string";
  initial?: string;
};

type NumberType = {
  type: "number";
  initial?: number;
  positiveOnly?: boolean;
  nullable?: boolean;
};

type Type = StringType | NumberType;

type Props = {
  className?: string;
  type: Type;
  // todo: reuse onChange for local state

  onChange: (x: string) => void;
  cacheDeps?: [any];
  [x: string]: any;
};

const TableCellInput = ({ className = "", onChange, type, cacheDeps, ...props }: Props) => {
  const [value, setValue] = useState(getInitialValue());

  // todo: remove debounce here?
  const debouncedOnChange = useCallback(debounce(700, onChange), cacheDeps ?? []);
  // const debouncedOnChange = debounce(700, onChange);

  function getInitialValue(): string | number | undefined {
    if (type.type == "string") return type.initial ?? "";
    else return type.initial ?? (type.nullable ? "" : 0);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (type.type == "number") {
      if (type.positiveOnly == true) {
        setValue(Math.abs(Number(e.target.value)));
      } else {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }

    debouncedOnChange(e.target.value);
  }

  useEffect(() => {
    if (type.initial !== value) setValue(getInitialValue());
  }, [type.initial]);

  return (
    <input
      className={`appearance-none focus:outline-none w-full block bg-orange-100 px-2 py-1 disabled:bg-gray-100 disabled:text-gray-300 ${className}`}
      onChange={handleChange}
      // todo: what is the proper type of e?
      onClick={(e: React.MouseEvent<HTMLInputElement>) => (e.target as HTMLInputElement).select()}
      type={type.type}
      value={value}
      tabIndex={props.tabIndex}
      // {...props}
    />
  );
};

export { TableCellInput, Type };
