import { Result, Err, Ok } from "ts-results";
import { GraphQLClient, gql } from "graphql-request";
import { Variables } from "graphql-request/dist/types";
import { getAuth, Jwt } from "./auth";
import * as camelcaseKeys from "camelcase-keys";

import * as sdk from "../generated/graphql";

function getUrls() {
  return {
    auth: (path: string) => `${process.env.AUTH_SERVER}/${path}`,
    base: process.env.HASURA_SERVER!,
  };
}

interface HttpResponse<T> {
  parsedBody: T;
  response: Response;
}

async function http<T>(request: RequestInfo): Promise<Result<HttpResponse<T>, Error>> {
  const response: Response = await fetch(request);
  let parsed: HttpResponse<T>;
  try {
    parsed = { response, parsedBody: await response.json() };
  } catch (ex) {
    return Err(Error("empty parsed body"));
  }
  if (!parsed.response.ok) {
    return Err(Error(response.statusText));
  }
  return Ok(parsed);
}

async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(body),
  }
): Promise<Result<HttpResponse<T>, Error>> {
  return await http<T>(new Request(path, args));
}

async function request<T = any>(query: string, vars?: Variables, jwt?: Jwt): Promise<Result<T, Error>> {
  let token;
  if (jwt) {
    token = jwt.token;
  } else {
    const auth = await getAuth();
    // todo something with this, show in UI?
    if (auth.err) return auth;
    token = auth.val.jwt.token;
  }

  const endpoint = getUrls().base;

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  let data;
  try {
    data = Ok(await graphQLClient.request<T>(query, vars));
  } catch (e) {
    data = Err(e);
  }

  return data;
}

async function result<T>(promise: Promise<T>): Promise<Result<T, Error>> {
  let data: Result<T, Error>;
  try {
    let x = await promise;
    data = Ok(x);
  } catch (e) {
    data = Err(e);
  }
  return data;
}

async function client(jwt?: Jwt): Promise<sdk.Sdk> {
  // todo: make this a blocking call
  let token;
  if (jwt) {
    token = jwt.token;
  } else {
    const auth = await getAuth();
    // todo something with this, show in UI?
    if (auth.err) {
      throw auth.val;
    }
    token = auth.val.jwt.token;
  }

  const endpoint = getUrls().base;

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return sdk.getSdk(graphQLClient);
}

type LegacyResponse<T> = {
  data: T | undefined;
  success: boolean;
};

async function legacyRequest<T = any>(query: string, vars?: Variables): Promise<LegacyResponse<T>> {
  let response = await request<T>(query, vars);
  if (response.ok) {
    return {
      data: camelcaseKeys(response.val, { deep: true }),
      success: true,
    };
  } else {
    return {
      data: undefined,
      success: false,
    };
  }
}

const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach(key => (obj[key] === undefined ? delete obj[key] : {}));
};

export { result, client, request, post, legacyRequest, getUrls, removeEmpty };
