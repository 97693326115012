import { EmptyTableRow } from "../../EmptyStates/EmptyTableRow";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";

import { useSupplierCode } from "../../../hooks/useSupplierCodeTs";
import { ProductVariantSupplier } from "../../../backend-v3/orders";
import { sortArrayByObjectKey } from "../../../helpersTs";
import { FaTrash } from "react-icons/fa";
import { TableRowActions } from "../../TableRowActions";
import { TableRow } from "../../TableRowTs";

type Props = {
  supplierId: number;
  products: ProductVariantSupplier[];
  loading: boolean;
  remove?: (productVariantId: number) => void;
};

const SupplierProductsTable = ({ loading, products, supplierId, remove }: Props) => {
  const { updateSupplierCode } = useSupplierCode();

  function handleSupplierCodeChange(productVariantId: number, value: string) {
    return updateSupplierCode({
      supplier_id: supplierId,
      product_variant_id: productVariantId,
      code: value,
    });
  }

  return (
    <Table
      className="mt-8 max-w-4xl"
      loading={loading}
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Products" width="72" />
          <TableHeadCell value="SKU" width="48" />
          <TableHeadCell value="Supplier Code" width="32" />
          {remove && <TableHeadCell alignment="right" width="32" value="Actions" />}
        </TableHeadRow>
      )}
      renderBody={() => (
        <>
          {sortArrayByObjectKey(products, "full_title").map(x => {
            return (
              <TableRow key={x.id}>
                <TableCell value={{ initial: x.full_title, type: "string" }} url={x.url} width="72" />
                <TableCell value={{ initial: x.sku, type: "string" }} width="48" />
                <TableCell
                  value={{ initial: x.code, type: "string" }}
                  onChange={value => handleSupplierCodeChange(x.id, value)}
                  width="32"
                />

                {remove && (
                  <TableRowActions
                    actions={[
                      {
                        id: "remove",
                        iconBefore: <FaTrash />,
                        onClick: () => {
                          remove(x.id);
                        },
                      },
                    ]}
                  />
                )}
              </TableRow>
            );
          })}
          {products.length < 1 && <EmptyTableRow columns={["products"]} />}
        </>
      )}
    />
  );
};

export { SupplierProductsTable };
