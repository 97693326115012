import * as moment from "moment";
import roundTo from "round-to";

function sortArrayByObjectKey<T = object>(array: Array<T>, key: keyof T, reverse = false) {
  return array.sort((prev: any, next: any) => {
    const prevKey = typeof prev[key] === "object" && "_isAMomentObject" in prev[key] ? prev[key].unix() : prev[key];
    const nextKey = typeof next[key] === "object" && "_isAMomentObject" in next[key] ? next[key].unix() : next[key];
    if (prevKey < nextKey) return reverse ? 1 : -1;
    if (prevKey > nextKey) return reverse ? -1 : 1;
    return 0;
  });
}

function sortArrayByObjectSubKey<T = any>(array: Array<T>, key: any, subkey: any, reverse = false) {
  return array.sort((prev: any, next: any) => {
    if (prev[key][subkey] < next[key][subkey]) return reverse ? 1 : -1;
    if (prev[key][subkey] > next[key][subkey]) return reverse ? -1 : 1;
    return 0;
  });
}

const dateString = (date: moment.Moment) => moment(date).format("YYYY-MM-DD");

const formatDate = (date: moment.Moment) => date.format("L");

const roundToTwo = (val: number) => roundTo(val, 2).toFixed(2);

const toWhole = (val: number) => roundTo(val, 0);

export { toWhole, sortArrayByObjectKey, sortArrayByObjectSubKey, formatDate, roundToTwo, dateString };
