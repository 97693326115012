import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import { FaDownload, FaFileInvoiceDollar, FaEye, FaTrash } from "react-icons/fa";

// import { OrdersFilter } from "./OrdersFilterTs";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableSortableColumnHeading } from "../../TableSortableColumnHeading";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { CONSTANTS } from "../../../common/constants";
import { roundToTwo } from "../../../helpers";
import { sortArrayByObjectKey, formatDate } from "../../../helpersTs";
import { PROP_TYPES } from "../../../common/propTypes";

import { Order } from "../../../backend-v3/orders";
import { Location } from "../../../backend-v3/locations";
import { Supplier } from "../../../backend-v3/suppliers";
import { Button } from "../../ButtonTs";
import { ExportToCsv } from "export-to-csv";
import { getInvoicesAction } from "../OrderTs/common";
import { Invoice } from "../../../backend-v3/invoices";
import { useInvoices } from "../../../hooks/useInvoices";
import { FilterKey, InvoicesFilter } from "./InvoicesFilter";
import { openInvoiceInXero } from "../Invoice/common";

type Props = {
  sendToXero: (invoiceId: number) => Promise<void>;
  status: "updating" | "fetching" | undefined;
  invoices: Invoice[];
  processingXero: { [key: number]: boolean | undefined };
  deleteInvoice: (invoiceId: number) => Promise<void>;
  locationQuery?: string;
  locationFilterKey?: FilterKey;
};

const InvoicesTable = ({
  sendToXero,
  status,
  invoices,
  processingXero,
  deleteInvoice,
  locationQuery,
  locationFilterKey,
}: Props) => {
  const defaultSort = {
    key: "invoice_number" as any,
    ascending: true,
  };
  const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
  const [sorting, setSorting] = useState(defaultSort);

  function sortedInvoices() {
    const sorted = sortArrayByObjectKey(filteredInvoices, sorting.key, true);
    return sorting.ascending ? sorted : sorted.reverse();
  }

  function handleSort(key: string) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    });
  }

  return (
    <>
      <div className="mb-4">
        <InvoicesFilter
          locationQuery={locationQuery}
          locationFilterKey={locationFilterKey}
          invoices={invoices}
          setFilteredInvoices={setFilteredInvoices}
        />
      </div>

      <Table
        className="mt-7 max-w-7xl"
        emptyState={!invoices.length && "No invoices here..."}
        renderHead={() => (
          <TableRow>
            <TableHeadCell
              value={<TableSortableColumnHeading text="Invoice Id" onClick={() => handleSort("id")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Invoice No." onClick={() => handleSort("invoice_number")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Supplier" onClick={() => handleSort("supplier")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Invoice Date" onClick={() => handleSort("invoice_date")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Payment Due" onClick={() => handleSort("payment_due")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort("created_at")} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Total Cost" onClick={() => handleSort("total")} />}
              width="48"
            />
            <TableHeadCell alignment="right" value="Actions" width="72" />
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedInvoices().map(invoice => {
              const { id, invoice_number, invoice_date, payment_due, created_at, xero_invoice_id } = invoice;

              const sendInvoicesStatus = getInvoicesAction([invoice]);

              const actions = {
                view: {
                  id: "viewInXero",
                  iconBefore: <FaEye />,
                  onClick: (e: Event) => {
                    e.stopPropagation();
                    openInvoiceInXero(invoice);
                  },
                  value: "View in Xero",
                },
                send: {
                  id: "sendToXero",
                  loading: processingXero[invoice.id] === true,
                  iconBefore: <FaFileInvoiceDollar />,
                  onClick: (e: Event) => {
                    e.stopPropagation();
                    sendToXero(invoice.id);
                  },
                  value: sendInvoicesStatus,
                  variant: sendInvoicesStatus === "Resend all" ? "ghost" : "ghost",
                },
              };

              function getActions() {
                return invoice.xero_invoice_id ? [actions.view, actions.send] : [actions.send];
              }

              return (
                <TableRow key={invoice.id} onClick={() => navigate(`/invoices/${invoice.id}`)}>
                  <TableCell value={{ type: "number", initial: invoice.id }} />
                  <TableCell value={{ type: "string", initial: invoice.invoice_number }} />
                  <TableCell value={{ type: "string", initial: invoice.supplier.name }} />
                  <TableCell
                    value={{
                      type: "string",
                      initial: invoice.invoice_date ? formatDate(invoice.invoice_date) : undefined,
                    }}
                  />
                  <TableCell
                    value={{
                      type: "string",
                      initial: invoice.payment_due ? formatDate(invoice.payment_due) : undefined,
                    }}
                  />
                  <TableCell value={{ type: "string", initial: formatDate(invoice.created_at) }} />
                  <TableCell value={{ type: "string", initial: `$${roundToTwo(invoice.total_with_gst)}` }} />
                  {<TableRowActions actions={getActions()} />}
                </TableRow>
              );
            })}
          </>
        )}
      />
    </>
  );
};

export { InvoicesTable };
