import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  bigint: any;
  date: any;
  json: any;
  numeric: any;
  order_status: any;
  order_status_set: any;
  stocktake_status: any;
  timestamptz: any;
  transfer_status: any;
  tstzrange: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type XeroMutation = {
  authorize: Scalars['String'];
  createInvoice: Scalars['String'];
  remove: Scalars['Boolean'];
};


export type XeroMutationCreateInvoiceArgs = {
  invoiceId: Scalars['Int'];
};



/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bin_locations" */
export type Bin_Locations = {
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "bin_locations" */
export type Bin_Locations_Aggregate_Order_By = {
  avg?: Maybe<Bin_Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Bin_Locations_Max_Order_By>;
  min?: Maybe<Bin_Locations_Min_Order_By>;
  stddev?: Maybe<Bin_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Bin_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Bin_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Bin_Locations_Sum_Order_By>;
  var_pop?: Maybe<Bin_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Bin_Locations_Var_Samp_Order_By>;
  variance?: Maybe<Bin_Locations_Variance_Order_By>;
};

/** order by avg() on columns of table "bin_locations" */
export type Bin_Locations_Avg_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bin_locations". All fields are combined with a logical 'AND'. */
export type Bin_Locations_Bool_Exp = {
  _and?: Maybe<Array<Bin_Locations_Bool_Exp>>;
  _not?: Maybe<Bin_Locations_Bool_Exp>;
  _or?: Maybe<Array<Bin_Locations_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bin_locations" */
export enum Bin_Locations_Constraint {
  /** unique or primary key constraint */
  BinLocationsLocationIdProductVariantIdKey = 'bin_locations_location_id_product_variant_id_key',
  /** unique or primary key constraint */
  BinLocationsPkey = 'bin_locations_pkey'
}

/** input type for incrementing numeric columns in table "bin_locations" */
export type Bin_Locations_Inc_Input = {
  location_id?: Maybe<Scalars['Int']>;
  product_variant_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bin_locations" */
export type Bin_Locations_Insert_Input = {
  location_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_variant_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bin_locations" */
export type Bin_Locations_Max_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "bin_locations" */
export type Bin_Locations_Min_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "bin_locations" */
export type Bin_Locations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bin_Locations>;
};

/** on_conflict condition type for table "bin_locations" */
export type Bin_Locations_On_Conflict = {
  constraint: Bin_Locations_Constraint;
  update_columns?: Array<Bin_Locations_Update_Column>;
  where?: Maybe<Bin_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "bin_locations". */
export type Bin_Locations_Order_By = {
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: bin_locations */
export type Bin_Locations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "bin_locations" */
export enum Bin_Locations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bin_locations" */
export type Bin_Locations_Set_Input = {
  location_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_variant_id?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "bin_locations" */
export type Bin_Locations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bin_locations" */
export type Bin_Locations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bin_locations" */
export type Bin_Locations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "bin_locations" */
export type Bin_Locations_Sum_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** update columns of table "bin_locations" */
export enum Bin_Locations_Update_Column {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductVariantId = 'product_variant_id'
}

/** order by var_pop() on columns of table "bin_locations" */
export type Bin_Locations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "bin_locations" */
export type Bin_Locations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "bin_locations" */
export type Bin_Locations_Variance_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

export type Create_And_Populate_Order_Args = {
  input_location_id?: Maybe<Scalars['Int']>;
  input_supplier_id?: Maybe<Scalars['Int']>;
};

export type Create_And_Populate_Order_Options_Args = {
  assigned?: Maybe<Scalars['Boolean']>;
  input_location_id?: Maybe<Scalars['Int']>;
  input_supplier_id?: Maybe<Scalars['Int']>;
  vendors?: Maybe<Scalars['Boolean']>;
};

export type Create_And_Populate_Order_Supplied_Args = {
  input_location_id?: Maybe<Scalars['Int']>;
  input_supplier_id?: Maybe<Scalars['Int']>;
  populate?: Maybe<Scalars['json']>;
};

export type Create_And_Populate_Order_Supplier_Products_Args = {
  input_location_id?: Maybe<Scalars['Int']>;
  input_supplier_id?: Maybe<Scalars['Int']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "fulfillments_view" */
export type Fulfillments_View = {
  location_id?: Maybe<Scalars['Int']>;
  processed_at?: Maybe<Scalars['timestamptz']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "fulfillments_view" */
export type Fulfillments_View_Aggregate = {
  aggregate?: Maybe<Fulfillments_View_Aggregate_Fields>;
  nodes: Array<Fulfillments_View>;
};

/** aggregate fields of "fulfillments_view" */
export type Fulfillments_View_Aggregate_Fields = {
  avg?: Maybe<Fulfillments_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fulfillments_View_Max_Fields>;
  min?: Maybe<Fulfillments_View_Min_Fields>;
  stddev?: Maybe<Fulfillments_View_Stddev_Fields>;
  stddev_pop?: Maybe<Fulfillments_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fulfillments_View_Stddev_Samp_Fields>;
  sum?: Maybe<Fulfillments_View_Sum_Fields>;
  var_pop?: Maybe<Fulfillments_View_Var_Pop_Fields>;
  var_samp?: Maybe<Fulfillments_View_Var_Samp_Fields>;
  variance?: Maybe<Fulfillments_View_Variance_Fields>;
};


/** aggregate fields of "fulfillments_view" */
export type Fulfillments_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fulfillments_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fulfillments_view" */
export type Fulfillments_View_Aggregate_Order_By = {
  avg?: Maybe<Fulfillments_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fulfillments_View_Max_Order_By>;
  min?: Maybe<Fulfillments_View_Min_Order_By>;
  stddev?: Maybe<Fulfillments_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Fulfillments_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fulfillments_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Fulfillments_View_Sum_Order_By>;
  var_pop?: Maybe<Fulfillments_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Fulfillments_View_Var_Samp_Order_By>;
  variance?: Maybe<Fulfillments_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Fulfillments_View_Avg_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fulfillments_view" */
export type Fulfillments_View_Avg_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fulfillments_view". All fields are combined with a logical 'AND'. */
export type Fulfillments_View_Bool_Exp = {
  _and?: Maybe<Array<Fulfillments_View_Bool_Exp>>;
  _not?: Maybe<Fulfillments_View_Bool_Exp>;
  _or?: Maybe<Array<Fulfillments_View_Bool_Exp>>;
  location_id?: Maybe<Int_Comparison_Exp>;
  processed_at?: Maybe<Timestamptz_Comparison_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Fulfillments_View_Max_Fields = {
  location_id?: Maybe<Scalars['Int']>;
  processed_at?: Maybe<Scalars['timestamptz']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "fulfillments_view" */
export type Fulfillments_View_Max_Order_By = {
  location_id?: Maybe<Order_By>;
  processed_at?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fulfillments_View_Min_Fields = {
  location_id?: Maybe<Scalars['Int']>;
  processed_at?: Maybe<Scalars['timestamptz']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "fulfillments_view" */
export type Fulfillments_View_Min_Order_By = {
  location_id?: Maybe<Order_By>;
  processed_at?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "fulfillments_view". */
export type Fulfillments_View_Order_By = {
  location_id?: Maybe<Order_By>;
  processed_at?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** select columns of table "fulfillments_view" */
export enum Fulfillments_View_Select_Column {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ShopId = 'shop_id'
}

/** aggregate stddev on columns */
export type Fulfillments_View_Stddev_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fulfillments_view" */
export type Fulfillments_View_Stddev_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fulfillments_View_Stddev_Pop_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fulfillments_view" */
export type Fulfillments_View_Stddev_Pop_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fulfillments_View_Stddev_Samp_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fulfillments_view" */
export type Fulfillments_View_Stddev_Samp_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Fulfillments_View_Sum_Fields = {
  location_id?: Maybe<Scalars['Int']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "fulfillments_view" */
export type Fulfillments_View_Sum_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Fulfillments_View_Var_Pop_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fulfillments_view" */
export type Fulfillments_View_Var_Pop_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fulfillments_View_Var_Samp_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fulfillments_view" */
export type Fulfillments_View_Var_Samp_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fulfillments_View_Variance_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fulfillments_view" */
export type Fulfillments_View_Variance_Order_By = {
  location_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** columns and relationships of "integrations_status" */
export type Integrations_Status = {
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  xero_enabled?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "integrations_status". All fields are combined with a logical 'AND'. */
export type Integrations_Status_Bool_Exp = {
  _and?: Maybe<Array<Integrations_Status_Bool_Exp>>;
  _not?: Maybe<Integrations_Status_Bool_Exp>;
  _or?: Maybe<Array<Integrations_Status_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  xero_enabled?: Maybe<Boolean_Comparison_Exp>;
};

/** response of any mutation on the table "integrations_status" */
export type Integrations_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integrations_Status>;
};

/** Ordering options when selecting data from "integrations_status". */
export type Integrations_Status_Order_By = {
  id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  xero_enabled?: Maybe<Order_By>;
};

/** select columns of table "integrations_status" */
export enum Integrations_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  XeroEnabled = 'xero_enabled'
}

export type Inventory_Days_Available_Product_Variants_Args = {
  from_ts?: Maybe<Scalars['timestamptz']>;
  loc_id?: Maybe<Scalars['Int']>;
  to_ts?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "inventory_history_view" */
export type Inventory_History_View = {
  available?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  previous_available?: Maybe<Scalars['Int']>;
  previous_date?: Maybe<Scalars['date']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
};

/** order by aggregate values of table "inventory_history_view" */
export type Inventory_History_View_Aggregate_Order_By = {
  avg?: Maybe<Inventory_History_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_History_View_Max_Order_By>;
  min?: Maybe<Inventory_History_View_Min_Order_By>;
  stddev?: Maybe<Inventory_History_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_History_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_History_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_History_View_Sum_Order_By>;
  var_pop?: Maybe<Inventory_History_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_History_View_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_History_View_Variance_Order_By>;
};

/** order by avg() on columns of table "inventory_history_view" */
export type Inventory_History_View_Avg_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_history_view". All fields are combined with a logical 'AND'. */
export type Inventory_History_View_Bool_Exp = {
  _and?: Maybe<Array<Inventory_History_View_Bool_Exp>>;
  _not?: Maybe<Inventory_History_View_Bool_Exp>;
  _or?: Maybe<Array<Inventory_History_View_Bool_Exp>>;
  available?: Maybe<Int_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  previous_available?: Maybe<Int_Comparison_Exp>;
  previous_date?: Maybe<Date_Comparison_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
};

/** order by max() on columns of table "inventory_history_view" */
export type Inventory_History_View_Max_Order_By = {
  available?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  previous_date?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "inventory_history_view" */
export type Inventory_History_View_Min_Order_By = {
  available?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  previous_date?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "inventory_history_view". */
export type Inventory_History_View_Order_By = {
  available?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  previous_date?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
};

/** select columns of table "inventory_history_view" */
export enum Inventory_History_View_Select_Column {
  /** column name */
  Available = 'available',
  /** column name */
  Date = 'date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PreviousAvailable = 'previous_available',
  /** column name */
  PreviousDate = 'previous_date',
  /** column name */
  ProductVariantId = 'product_variant_id'
}

/** order by stddev() on columns of table "inventory_history_view" */
export type Inventory_History_View_Stddev_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "inventory_history_view" */
export type Inventory_History_View_Stddev_Pop_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "inventory_history_view" */
export type Inventory_History_View_Stddev_Samp_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "inventory_history_view" */
export type Inventory_History_View_Sum_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "inventory_history_view" */
export type Inventory_History_View_Var_Pop_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "inventory_history_view" */
export type Inventory_History_View_Var_Samp_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "inventory_history_view" */
export type Inventory_History_View_Variance_Order_By = {
  available?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  previous_available?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
};

/** columns and relationships of "invoice_expenses" */
export type Invoice_Expenses = {
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  /** Expense description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars['Int'];
  /** An object relationship */
  invoice_view?: Maybe<Invoices_View>;
  sys_period: Scalars['tstzrange'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "invoice_expenses" */
export type Invoice_Expenses_Aggregate = {
  aggregate?: Maybe<Invoice_Expenses_Aggregate_Fields>;
  nodes: Array<Invoice_Expenses>;
};

/** aggregate fields of "invoice_expenses" */
export type Invoice_Expenses_Aggregate_Fields = {
  avg?: Maybe<Invoice_Expenses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Expenses_Max_Fields>;
  min?: Maybe<Invoice_Expenses_Min_Fields>;
  stddev?: Maybe<Invoice_Expenses_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Expenses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Expenses_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Expenses_Sum_Fields>;
  var_pop?: Maybe<Invoice_Expenses_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Expenses_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Expenses_Variance_Fields>;
};


/** aggregate fields of "invoice_expenses" */
export type Invoice_Expenses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_expenses" */
export type Invoice_Expenses_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Expenses_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Expenses_Max_Order_By>;
  min?: Maybe<Invoice_Expenses_Min_Order_By>;
  stddev?: Maybe<Invoice_Expenses_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Expenses_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Expenses_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Expenses_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Expenses_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Expenses_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Expenses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_expenses" */
export type Invoice_Expenses_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Expenses_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Invoice_Expenses_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Expenses_Avg_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_expenses". All fields are combined with a logical 'AND'. */
export type Invoice_Expenses_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Expenses_Bool_Exp>>;
  _not?: Maybe<Invoice_Expenses_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Expenses_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice?: Maybe<Invoices_Bool_Exp>;
  invoice_id?: Maybe<Int_Comparison_Exp>;
  invoice_view?: Maybe<Invoices_View_Bool_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_expenses" */
export enum Invoice_Expenses_Constraint {
  /** unique or primary key constraint */
  InvoiceExpensesPkey = 'invoice_expenses_pkey'
}

/** input type for incrementing numeric columns in table "invoice_expenses" */
export type Invoice_Expenses_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "invoice_expenses" */
export type Invoice_Expenses_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  /** Expense description */
  description?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Invoice_Expenses_Max_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Expense description */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Expense description */
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Expenses_Min_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Expense description */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Expense description */
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoice_expenses" */
export type Invoice_Expenses_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Expenses>;
};

/** on_conflict condition type for table "invoice_expenses" */
export type Invoice_Expenses_On_Conflict = {
  constraint: Invoice_Expenses_Constraint;
  update_columns?: Array<Invoice_Expenses_Update_Column>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_expenses". */
export type Invoice_Expenses_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice?: Maybe<Invoices_Order_By>;
  invoice_id?: Maybe<Order_By>;
  invoice_view?: Maybe<Invoices_View_Order_By>;
  sys_period?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice_expenses */
export type Invoice_Expenses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invoice_expenses" */
export enum Invoice_Expenses_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoice_expenses" */
export type Invoice_Expenses_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  /** Expense description */
  description?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Invoice_Expenses_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Expenses_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Expenses_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Invoice_Expenses_Sum_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** update columns of table "invoice_expenses" */
export enum Invoice_Expenses_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Description = 'description'
}

/** aggregate var_pop on columns */
export type Invoice_Expenses_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Expenses_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Expenses_Variance_Fields = {
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_expenses" */
export type Invoice_Expenses_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
};

/** Invoice */
export type Invoices = {
  adjustments: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  invoice_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  invoice_expenses: Array<Invoice_Expenses>;
  /** An aggregate relationship */
  invoice_expenses_aggregate: Invoice_Expenses_Aggregate;
  invoice_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  invoice_view?: Maybe<Invoices_View>;
  /** An array relationship */
  order_invoices: Array<Order_Invoices>;
  /** An array relationship */
  order_products_invoices: Array<Order_Products_Invoice>;
  payment_due?: Maybe<Scalars['date']>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  /** An object relationship */
  supplier: Suppliers;
  supplier_id: Scalars['Int'];
  xero_invoice_id?: Maybe<Scalars['String']>;
};


/** Invoice */
export type InvoicesInvoice_ExpensesArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


/** Invoice */
export type InvoicesInvoice_Expenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


/** Invoice */
export type InvoicesOrder_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Invoices_Order_By>>;
  where?: Maybe<Order_Invoices_Bool_Exp>;
};


/** Invoice */
export type InvoicesOrder_Products_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Products_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Invoice_Order_By>>;
  where?: Maybe<Order_Products_Invoice_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "invoices". All fields are combined with a logical 'AND'. */
export type Invoices_Bool_Exp = {
  _and?: Maybe<Array<Invoices_Bool_Exp>>;
  _not?: Maybe<Invoices_Bool_Exp>;
  _or?: Maybe<Array<Invoices_Bool_Exp>>;
  adjustments?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_date?: Maybe<Date_Comparison_Exp>;
  invoice_expenses?: Maybe<Invoice_Expenses_Bool_Exp>;
  invoice_number?: Maybe<String_Comparison_Exp>;
  invoice_view?: Maybe<Invoices_View_Bool_Exp>;
  order_invoices?: Maybe<Order_Invoices_Bool_Exp>;
  order_products_invoices?: Maybe<Order_Products_Invoice_Bool_Exp>;
  payment_due?: Maybe<Date_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  xero_invoice_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices" */
export enum Invoices_Constraint {
  /** unique or primary key constraint */
  InvoicesPkey = 'invoices_pkey'
}

/** input type for incrementing numeric columns in table "invoices" */
export type Invoices_Inc_Input = {
  adjustments?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "invoices" */
export type Invoices_Insert_Input = {
  adjustments?: Maybe<Scalars['numeric']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_expenses?: Maybe<Invoice_Expenses_Arr_Rel_Insert_Input>;
  invoice_number?: Maybe<Scalars['String']>;
  order_invoices?: Maybe<Order_Invoices_Arr_Rel_Insert_Input>;
  order_products_invoices?: Maybe<Order_Products_Invoice_Arr_Rel_Insert_Input>;
  payment_due?: Maybe<Scalars['date']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Suppliers_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "invoices" */
export type Invoices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices>;
};

/** input type for inserting object relation for remote table "invoices" */
export type Invoices_Obj_Rel_Insert_Input = {
  data: Invoices_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Invoices_On_Conflict>;
};

/** on_conflict condition type for table "invoices" */
export type Invoices_On_Conflict = {
  constraint: Invoices_Constraint;
  update_columns?: Array<Invoices_Update_Column>;
  where?: Maybe<Invoices_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices". */
export type Invoices_Order_By = {
  adjustments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_expenses_aggregate?: Maybe<Invoice_Expenses_Aggregate_Order_By>;
  invoice_number?: Maybe<Order_By>;
  invoice_view?: Maybe<Invoices_View_Order_By>;
  order_invoices_aggregate?: Maybe<Order_Invoices_Aggregate_Order_By>;
  order_products_invoices_aggregate?: Maybe<Order_Products_Invoice_Aggregate_Order_By>;
  payment_due?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
  xero_invoice_id?: Maybe<Order_By>;
};

/** primary key columns input for table: invoices */
export type Invoices_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invoices" */
export enum Invoices_Select_Column {
  /** column name */
  Adjustments = 'adjustments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  PaymentDue = 'payment_due',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  XeroInvoiceId = 'xero_invoice_id'
}

/** input type for updating data in table "invoices" */
export type Invoices_Set_Input = {
  adjustments?: Maybe<Scalars['numeric']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  payment_due?: Maybe<Scalars['date']>;
};

/** update columns of table "invoices" */
export enum Invoices_Update_Column {
  /** column name */
  Adjustments = 'adjustments',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  PaymentDue = 'payment_due'
}

/** columns and relationships of "invoices_view" */
export type Invoices_View = {
  adjustments?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  invoice_expenses: Array<Invoice_Expenses>;
  /** An aggregate relationship */
  invoice_expenses_aggregate: Invoice_Expenses_Aggregate;
  invoice_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_invoices: Array<Order_Invoices>;
  /** An array relationship */
  order_products_invoices: Array<Order_Products_Invoice>;
  payment_due?: Maybe<Scalars['date']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplier_id?: Maybe<Scalars['Int']>;
  sys_period?: Maybe<Scalars['tstzrange']>;
  total?: Maybe<Scalars['numeric']>;
  total_expenses?: Maybe<Scalars['numeric']>;
  total_received?: Maybe<Scalars['numeric']>;
  total_with_gst?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  xero_invoice_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "invoices_view" */
export type Invoices_ViewInvoice_ExpensesArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


/** columns and relationships of "invoices_view" */
export type Invoices_ViewInvoice_Expenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


/** columns and relationships of "invoices_view" */
export type Invoices_ViewOrder_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Invoices_Order_By>>;
  where?: Maybe<Order_Invoices_Bool_Exp>;
};


/** columns and relationships of "invoices_view" */
export type Invoices_ViewOrder_Products_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Products_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Invoice_Order_By>>;
  where?: Maybe<Order_Products_Invoice_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "invoices_view". All fields are combined with a logical 'AND'. */
export type Invoices_View_Bool_Exp = {
  _and?: Maybe<Array<Invoices_View_Bool_Exp>>;
  _not?: Maybe<Invoices_View_Bool_Exp>;
  _or?: Maybe<Array<Invoices_View_Bool_Exp>>;
  adjustments?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_date?: Maybe<Date_Comparison_Exp>;
  invoice_expenses?: Maybe<Invoice_Expenses_Bool_Exp>;
  invoice_number?: Maybe<String_Comparison_Exp>;
  order_invoices?: Maybe<Order_Invoices_Bool_Exp>;
  order_products_invoices?: Maybe<Order_Products_Invoice_Bool_Exp>;
  payment_due?: Maybe<Date_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  total_expenses?: Maybe<Numeric_Comparison_Exp>;
  total_received?: Maybe<Numeric_Comparison_Exp>;
  total_with_gst?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  xero_invoice_id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "invoices_view". */
export type Invoices_View_Order_By = {
  adjustments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_expenses_aggregate?: Maybe<Invoice_Expenses_Aggregate_Order_By>;
  invoice_number?: Maybe<Order_By>;
  order_invoices_aggregate?: Maybe<Order_Invoices_Aggregate_Order_By>;
  order_products_invoices_aggregate?: Maybe<Order_Products_Invoice_Aggregate_Order_By>;
  payment_due?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  total_expenses?: Maybe<Order_By>;
  total_received?: Maybe<Order_By>;
  total_with_gst?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  xero_invoice_id?: Maybe<Order_By>;
};

/** select columns of table "invoices_view" */
export enum Invoices_View_Select_Column {
  /** column name */
  Adjustments = 'adjustments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  PaymentDue = 'payment_due',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  Total = 'total',
  /** column name */
  TotalExpenses = 'total_expenses',
  /** column name */
  TotalReceived = 'total_received',
  /** column name */
  TotalWithGst = 'total_with_gst',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  XeroInvoiceId = 'xero_invoice_id'
}


/** Shopify Location */
export type Locations = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  shopify_location_id: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "locations" */
export type Locations_Aggregate_Order_By = {
  avg?: Maybe<Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Locations_Max_Order_By>;
  min?: Maybe<Locations_Min_Order_By>;
  stddev?: Maybe<Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Locations_Sum_Order_By>;
  var_pop?: Maybe<Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Locations_Var_Samp_Order_By>;
  variance?: Maybe<Locations_Variance_Order_By>;
};

/** order by avg() on columns of table "locations" */
export type Locations_Avg_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  address1?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  province?: Maybe<String_Comparison_Exp>;
  province_code?: Maybe<String_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  shopify_location_id?: Maybe<String_Comparison_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "locations" */
export type Locations_Max_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  province?: Maybe<Order_By>;
  province_code?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_location_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** order by min() on columns of table "locations" */
export type Locations_Min_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  province?: Maybe<Order_By>;
  province_code?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_location_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  province?: Maybe<Order_By>;
  province_code?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_location_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  ProvinceCode = 'province_code',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  ShopifyLocationId = 'shopify_location_id',
  /** column name */
  Zip = 'zip'
}

/** order by stddev() on columns of table "locations" */
export type Locations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "locations" */
export type Locations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "locations" */
export type Locations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "locations" */
export type Locations_Sum_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "locations" */
export type Locations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "locations" */
export type Locations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "locations" */
export type Locations_Variance_Order_By = {
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  /** execute VOLATILE function "create_and_populate_order" which returns "orders" */
  create_and_populate_order: Array<Orders>;
  /** execute VOLATILE function "create_and_populate_order_options" which returns "orders" */
  create_and_populate_order_options: Array<Orders>;
  /** execute VOLATILE function "create_and_populate_order_supplied" which returns "orders" */
  create_and_populate_order_supplied: Array<Orders>;
  /** execute VOLATILE function "create_and_populate_order_supplier_products" which returns "orders" */
  create_and_populate_order_supplier_products: Array<Orders>;
  /** delete data from the table: "bin_locations" */
  delete_bin_locations?: Maybe<Bin_Locations_Mutation_Response>;
  /** delete single row from the table: "bin_locations" */
  delete_bin_locations_by_pk?: Maybe<Bin_Locations>;
  /** delete data from the table: "integrations_status" */
  delete_integrations_status?: Maybe<Integrations_Status_Mutation_Response>;
  /** delete data from the table: "invoice_expenses" */
  delete_invoice_expenses?: Maybe<Invoice_Expenses_Mutation_Response>;
  /** delete single row from the table: "invoice_expenses" */
  delete_invoice_expenses_by_pk?: Maybe<Invoice_Expenses>;
  /** delete data from the table: "invoices" */
  delete_invoices?: Maybe<Invoices_Mutation_Response>;
  /** delete single row from the table: "invoices" */
  delete_invoices_by_pk?: Maybe<Invoices>;
  /** delete data from the table: "order_invoices" */
  delete_order_invoices?: Maybe<Order_Invoices_Mutation_Response>;
  /** delete single row from the table: "order_invoices" */
  delete_order_invoices_by_pk?: Maybe<Order_Invoices>;
  /** delete data from the table: "order_products" */
  delete_order_products?: Maybe<Order_Products_Mutation_Response>;
  /** delete single row from the table: "order_products" */
  delete_order_products_by_pk?: Maybe<Order_Products>;
  /** delete data from the table: "order_products_invoice" */
  delete_order_products_invoice?: Maybe<Order_Products_Invoice_Mutation_Response>;
  /** delete single row from the table: "order_products_invoice" */
  delete_order_products_invoice_by_pk?: Maybe<Order_Products_Invoice>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "stocktake_locked" */
  delete_stocktake_locked?: Maybe<Stocktake_Locked_Mutation_Response>;
  /** delete single row from the table: "stocktake_locked" */
  delete_stocktake_locked_by_pk?: Maybe<Stocktake_Locked>;
  /** delete data from the table: "stocktakes" */
  delete_stocktakes?: Maybe<Stocktakes_Mutation_Response>;
  /** delete single row from the table: "stocktakes" */
  delete_stocktakes_by_pk?: Maybe<Stocktakes>;
  /** delete data from the table: "suppliers" */
  delete_suppliers?: Maybe<Suppliers_Mutation_Response>;
  /** delete single row from the table: "suppliers" */
  delete_suppliers_by_pk?: Maybe<Suppliers>;
  /** delete data from the table: "suppliers_product_variants" */
  delete_suppliers_product_variants?: Maybe<Suppliers_Product_Variants_Mutation_Response>;
  /** delete data from the table: "suppliers_vendors" */
  delete_suppliers_vendors?: Maybe<Suppliers_Vendors_Mutation_Response>;
  /** delete single row from the table: "suppliers_vendors" */
  delete_suppliers_vendors_by_pk?: Maybe<Suppliers_Vendors>;
  /** delete data from the table: "transfer_products" */
  delete_transfer_products?: Maybe<Transfer_Products_Mutation_Response>;
  /** delete single row from the table: "transfer_products" */
  delete_transfer_products_by_pk?: Maybe<Transfer_Products>;
  /** delete data from the table: "transfers" */
  delete_transfers?: Maybe<Transfers_Mutation_Response>;
  /** delete single row from the table: "transfers" */
  delete_transfers_by_pk?: Maybe<Transfers>;
  /** insert data into the table: "bin_locations" */
  insert_bin_locations?: Maybe<Bin_Locations_Mutation_Response>;
  /** insert a single row into the table: "bin_locations" */
  insert_bin_locations_one?: Maybe<Bin_Locations>;
  /** insert data into the table: "invoice_expenses" */
  insert_invoice_expenses?: Maybe<Invoice_Expenses_Mutation_Response>;
  /** insert a single row into the table: "invoice_expenses" */
  insert_invoice_expenses_one?: Maybe<Invoice_Expenses>;
  /** insert data into the table: "invoices" */
  insert_invoices?: Maybe<Invoices_Mutation_Response>;
  /** insert a single row into the table: "invoices" */
  insert_invoices_one?: Maybe<Invoices>;
  /** insert data into the table: "order_invoices" */
  insert_order_invoices?: Maybe<Order_Invoices_Mutation_Response>;
  /** insert a single row into the table: "order_invoices" */
  insert_order_invoices_one?: Maybe<Order_Invoices>;
  /** insert data into the table: "order_products" */
  insert_order_products?: Maybe<Order_Products_Mutation_Response>;
  /** insert data into the table: "order_products_invoice" */
  insert_order_products_invoice?: Maybe<Order_Products_Invoice_Mutation_Response>;
  /** insert a single row into the table: "order_products_invoice" */
  insert_order_products_invoice_one?: Maybe<Order_Products_Invoice>;
  /** insert a single row into the table: "order_products" */
  insert_order_products_one?: Maybe<Order_Products>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "stocktakes" */
  insert_stocktakes?: Maybe<Stocktakes_Mutation_Response>;
  /** insert a single row into the table: "stocktakes" */
  insert_stocktakes_one?: Maybe<Stocktakes>;
  /** insert data into the table: "suppliers" */
  insert_suppliers?: Maybe<Suppliers_Mutation_Response>;
  /** insert a single row into the table: "suppliers" */
  insert_suppliers_one?: Maybe<Suppliers>;
  /** insert data into the table: "suppliers_product_variants" */
  insert_suppliers_product_variants?: Maybe<Suppliers_Product_Variants_Mutation_Response>;
  /** insert a single row into the table: "suppliers_product_variants" */
  insert_suppliers_product_variants_one?: Maybe<Suppliers_Product_Variants>;
  /** insert data into the table: "suppliers_vendors" */
  insert_suppliers_vendors?: Maybe<Suppliers_Vendors_Mutation_Response>;
  /** insert a single row into the table: "suppliers_vendors" */
  insert_suppliers_vendors_one?: Maybe<Suppliers_Vendors>;
  /** insert data into the table: "transfer_products" */
  insert_transfer_products?: Maybe<Transfer_Products_Mutation_Response>;
  /** insert a single row into the table: "transfer_products" */
  insert_transfer_products_one?: Maybe<Transfer_Products>;
  /** insert data into the table: "transfers" */
  insert_transfers?: Maybe<Transfers_Mutation_Response>;
  /** insert a single row into the table: "transfers" */
  insert_transfers_one?: Maybe<Transfers>;
  /** execute VOLATILE function "start_stocktake" which returns "stocktake_locked" */
  start_stocktake: Array<Stocktake_Locked>;
  /** update data of the table: "bin_locations" */
  update_bin_locations?: Maybe<Bin_Locations_Mutation_Response>;
  /** update single row of the table: "bin_locations" */
  update_bin_locations_by_pk?: Maybe<Bin_Locations>;
  /** update data of the table: "invoice_expenses" */
  update_invoice_expenses?: Maybe<Invoice_Expenses_Mutation_Response>;
  /** update single row of the table: "invoice_expenses" */
  update_invoice_expenses_by_pk?: Maybe<Invoice_Expenses>;
  /** update data of the table: "invoices" */
  update_invoices?: Maybe<Invoices_Mutation_Response>;
  /** update single row of the table: "invoices" */
  update_invoices_by_pk?: Maybe<Invoices>;
  /** update data of the table: "order_products" */
  update_order_products?: Maybe<Order_Products_Mutation_Response>;
  /** update single row of the table: "order_products" */
  update_order_products_by_pk?: Maybe<Order_Products>;
  /** update data of the table: "order_products_invoice" */
  update_order_products_invoice?: Maybe<Order_Products_Invoice_Mutation_Response>;
  /** update single row of the table: "order_products_invoice" */
  update_order_products_invoice_by_pk?: Maybe<Order_Products_Invoice>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update data of the table: "stocktake_locked" */
  update_stocktake_locked?: Maybe<Stocktake_Locked_Mutation_Response>;
  /** update single row of the table: "stocktake_locked" */
  update_stocktake_locked_by_pk?: Maybe<Stocktake_Locked>;
  /** update data of the table: "stocktakes" */
  update_stocktakes?: Maybe<Stocktakes_Mutation_Response>;
  /** update single row of the table: "stocktakes" */
  update_stocktakes_by_pk?: Maybe<Stocktakes>;
  /** update data of the table: "suppliers" */
  update_suppliers?: Maybe<Suppliers_Mutation_Response>;
  /** update single row of the table: "suppliers" */
  update_suppliers_by_pk?: Maybe<Suppliers>;
  /** update data of the table: "suppliers_product_variants" */
  update_suppliers_product_variants?: Maybe<Suppliers_Product_Variants_Mutation_Response>;
  /** update data of the table: "transfer_products" */
  update_transfer_products?: Maybe<Transfer_Products_Mutation_Response>;
  /** update single row of the table: "transfer_products" */
  update_transfer_products_by_pk?: Maybe<Transfer_Products>;
  /** update data of the table: "transfers" */
  update_transfers?: Maybe<Transfers_Mutation_Response>;
  /** update single row of the table: "transfers" */
  update_transfers_by_pk?: Maybe<Transfers>;
  /** execute VOLATILE function "upsert_supplier_product_variants_sku" which returns "suppliers_product_variants" */
  upsert_supplier_product_variants_sku: Array<Suppliers_Product_Variants>;
  xero: XeroMutation;
};


/** mutation root */
export type Mutation_RootCreate_And_Populate_OrderArgs = {
  args: Create_And_Populate_Order_Args;
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCreate_And_Populate_Order_OptionsArgs = {
  args: Create_And_Populate_Order_Options_Args;
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCreate_And_Populate_Order_SuppliedArgs = {
  args: Create_And_Populate_Order_Supplied_Args;
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCreate_And_Populate_Order_Supplier_ProductsArgs = {
  args: Create_And_Populate_Order_Supplier_Products_Args;
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Bin_LocationsArgs = {
  where: Bin_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bin_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Integrations_StatusArgs = {
  where: Integrations_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_ExpensesArgs = {
  where: Invoice_Expenses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_Expenses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InvoicesArgs = {
  where: Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_InvoicesArgs = {
  where: Order_Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Invoices_By_PkArgs = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_ProductsArgs = {
  where: Order_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Products_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Products_InvoiceArgs = {
  where: Order_Products_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Products_Invoice_By_PkArgs = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
  product_variant_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stocktake_LockedArgs = {
  where: Stocktake_Locked_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocktake_Locked_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StocktakesArgs = {
  where: Stocktakes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocktakes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SuppliersArgs = {
  where: Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Suppliers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Suppliers_Product_VariantsArgs = {
  where: Suppliers_Product_Variants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Suppliers_VendorsArgs = {
  where: Suppliers_Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Suppliers_Vendors_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Transfer_ProductsArgs = {
  where: Transfer_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transfer_Products_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TransfersArgs = {
  where: Transfers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transfers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Bin_LocationsArgs = {
  objects: Array<Bin_Locations_Insert_Input>;
  on_conflict?: Maybe<Bin_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bin_Locations_OneArgs = {
  object: Bin_Locations_Insert_Input;
  on_conflict?: Maybe<Bin_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_ExpensesArgs = {
  objects: Array<Invoice_Expenses_Insert_Input>;
  on_conflict?: Maybe<Invoice_Expenses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_Expenses_OneArgs = {
  object: Invoice_Expenses_Insert_Input;
  on_conflict?: Maybe<Invoice_Expenses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoicesArgs = {
  objects: Array<Invoices_Insert_Input>;
  on_conflict?: Maybe<Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_OneArgs = {
  object: Invoices_Insert_Input;
  on_conflict?: Maybe<Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_InvoicesArgs = {
  objects: Array<Order_Invoices_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Invoices_OneArgs = {
  object: Order_Invoices_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Order_ProductsArgs = {
  objects: Array<Order_Products_Insert_Input>;
  on_conflict?: Maybe<Order_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Products_InvoiceArgs = {
  objects: Array<Order_Products_Invoice_Insert_Input>;
  on_conflict?: Maybe<Order_Products_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Products_Invoice_OneArgs = {
  object: Order_Products_Invoice_Insert_Input;
  on_conflict?: Maybe<Order_Products_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Products_OneArgs = {
  object: Order_Products_Insert_Input;
  on_conflict?: Maybe<Order_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StocktakesArgs = {
  objects: Array<Stocktakes_Insert_Input>;
  on_conflict?: Maybe<Stocktakes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocktakes_OneArgs = {
  object: Stocktakes_Insert_Input;
  on_conflict?: Maybe<Stocktakes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SuppliersArgs = {
  objects: Array<Suppliers_Insert_Input>;
  on_conflict?: Maybe<Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suppliers_OneArgs = {
  object: Suppliers_Insert_Input;
  on_conflict?: Maybe<Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suppliers_Product_VariantsArgs = {
  objects: Array<Suppliers_Product_Variants_Insert_Input>;
  on_conflict?: Maybe<Suppliers_Product_Variants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suppliers_Product_Variants_OneArgs = {
  object: Suppliers_Product_Variants_Insert_Input;
  on_conflict?: Maybe<Suppliers_Product_Variants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suppliers_VendorsArgs = {
  objects: Array<Suppliers_Vendors_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Suppliers_Vendors_OneArgs = {
  object: Suppliers_Vendors_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Transfer_ProductsArgs = {
  objects: Array<Transfer_Products_Insert_Input>;
  on_conflict?: Maybe<Transfer_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transfer_Products_OneArgs = {
  object: Transfer_Products_Insert_Input;
  on_conflict?: Maybe<Transfer_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransfersArgs = {
  objects: Array<Transfers_Insert_Input>;
  on_conflict?: Maybe<Transfers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transfers_OneArgs = {
  object: Transfers_Insert_Input;
  on_conflict?: Maybe<Transfers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootStart_StocktakeArgs = {
  args: Start_Stocktake_Args;
  distinct_on?: Maybe<Array<Stocktake_Locked_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Locked_Order_By>>;
  where?: Maybe<Stocktake_Locked_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_Bin_LocationsArgs = {
  _inc?: Maybe<Bin_Locations_Inc_Input>;
  _set?: Maybe<Bin_Locations_Set_Input>;
  where: Bin_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bin_Locations_By_PkArgs = {
  _inc?: Maybe<Bin_Locations_Inc_Input>;
  _set?: Maybe<Bin_Locations_Set_Input>;
  pk_columns: Bin_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_ExpensesArgs = {
  _inc?: Maybe<Invoice_Expenses_Inc_Input>;
  _set?: Maybe<Invoice_Expenses_Set_Input>;
  where: Invoice_Expenses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Expenses_By_PkArgs = {
  _inc?: Maybe<Invoice_Expenses_Inc_Input>;
  _set?: Maybe<Invoice_Expenses_Set_Input>;
  pk_columns: Invoice_Expenses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoicesArgs = {
  _inc?: Maybe<Invoices_Inc_Input>;
  _set?: Maybe<Invoices_Set_Input>;
  where: Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_By_PkArgs = {
  _inc?: Maybe<Invoices_Inc_Input>;
  _set?: Maybe<Invoices_Set_Input>;
  pk_columns: Invoices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ProductsArgs = {
  _inc?: Maybe<Order_Products_Inc_Input>;
  _set?: Maybe<Order_Products_Set_Input>;
  where: Order_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Products_By_PkArgs = {
  _inc?: Maybe<Order_Products_Inc_Input>;
  _set?: Maybe<Order_Products_Set_Input>;
  pk_columns: Order_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Products_InvoiceArgs = {
  _inc?: Maybe<Order_Products_Invoice_Inc_Input>;
  _set?: Maybe<Order_Products_Invoice_Set_Input>;
  where: Order_Products_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Products_Invoice_By_PkArgs = {
  _inc?: Maybe<Order_Products_Invoice_Inc_Input>;
  _set?: Maybe<Order_Products_Invoice_Set_Input>;
  pk_columns: Order_Products_Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stocktake_LockedArgs = {
  _inc?: Maybe<Stocktake_Locked_Inc_Input>;
  _set?: Maybe<Stocktake_Locked_Set_Input>;
  where: Stocktake_Locked_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocktake_Locked_By_PkArgs = {
  _inc?: Maybe<Stocktake_Locked_Inc_Input>;
  _set?: Maybe<Stocktake_Locked_Set_Input>;
  pk_columns: Stocktake_Locked_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StocktakesArgs = {
  _inc?: Maybe<Stocktakes_Inc_Input>;
  _set?: Maybe<Stocktakes_Set_Input>;
  where: Stocktakes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocktakes_By_PkArgs = {
  _inc?: Maybe<Stocktakes_Inc_Input>;
  _set?: Maybe<Stocktakes_Set_Input>;
  pk_columns: Stocktakes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SuppliersArgs = {
  _set?: Maybe<Suppliers_Set_Input>;
  where: Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Suppliers_By_PkArgs = {
  _set?: Maybe<Suppliers_Set_Input>;
  pk_columns: Suppliers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Suppliers_Product_VariantsArgs = {
  _set?: Maybe<Suppliers_Product_Variants_Set_Input>;
  where: Suppliers_Product_Variants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_ProductsArgs = {
  _inc?: Maybe<Transfer_Products_Inc_Input>;
  _set?: Maybe<Transfer_Products_Set_Input>;
  where: Transfer_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_Products_By_PkArgs = {
  _inc?: Maybe<Transfer_Products_Inc_Input>;
  _set?: Maybe<Transfer_Products_Set_Input>;
  pk_columns: Transfer_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransfersArgs = {
  _inc?: Maybe<Transfers_Inc_Input>;
  _set?: Maybe<Transfers_Set_Input>;
  where: Transfers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transfers_By_PkArgs = {
  _inc?: Maybe<Transfers_Inc_Input>;
  _set?: Maybe<Transfers_Set_Input>;
  pk_columns: Transfers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpsert_Supplier_Product_Variants_SkuArgs = {
  args: Upsert_Supplier_Product_Variants_Sku_Args;
  distinct_on?: Maybe<Array<Suppliers_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Product_Variants_Order_By>>;
  where?: Maybe<Suppliers_Product_Variants_Bool_Exp>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_invoices" */
export type Order_Invoices = {
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars['Int'];
  /** An object relationship */
  invoice_view?: Maybe<Invoices_View>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  /** An array relationship */
  order_products_invoices: Array<Order_Products_Invoice>;
};


/** columns and relationships of "order_invoices" */
export type Order_InvoicesOrder_Products_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Products_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Invoice_Order_By>>;
  where?: Maybe<Order_Products_Invoice_Bool_Exp>;
};

/** order by aggregate values of table "order_invoices" */
export type Order_Invoices_Aggregate_Order_By = {
  avg?: Maybe<Order_Invoices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Invoices_Max_Order_By>;
  min?: Maybe<Order_Invoices_Min_Order_By>;
  stddev?: Maybe<Order_Invoices_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Invoices_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Invoices_Sum_Order_By>;
  var_pop?: Maybe<Order_Invoices_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Invoices_Var_Samp_Order_By>;
  variance?: Maybe<Order_Invoices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_invoices" */
export type Order_Invoices_Arr_Rel_Insert_Input = {
  data: Array<Order_Invoices_Insert_Input>;
};

/** order by avg() on columns of table "order_invoices" */
export type Order_Invoices_Avg_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_invoices". All fields are combined with a logical 'AND'. */
export type Order_Invoices_Bool_Exp = {
  _and?: Maybe<Array<Order_Invoices_Bool_Exp>>;
  _not?: Maybe<Order_Invoices_Bool_Exp>;
  _or?: Maybe<Array<Order_Invoices_Bool_Exp>>;
  invoice?: Maybe<Invoices_Bool_Exp>;
  invoice_id?: Maybe<Int_Comparison_Exp>;
  invoice_view?: Maybe<Invoices_View_Bool_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  order_products_invoices?: Maybe<Order_Products_Invoice_Bool_Exp>;
};

/** input type for inserting data into table "order_invoices" */
export type Order_Invoices_Insert_Input = {
  invoice?: Maybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['Int']>;
  order_products_invoices?: Maybe<Order_Products_Invoice_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "order_invoices" */
export type Order_Invoices_Max_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_invoices" */
export type Order_Invoices_Min_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_invoices" */
export type Order_Invoices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Invoices>;
};

/** input type for inserting object relation for remote table "order_invoices" */
export type Order_Invoices_Obj_Rel_Insert_Input = {
  data: Order_Invoices_Insert_Input;
};

/** Ordering options when selecting data from "order_invoices". */
export type Order_Invoices_Order_By = {
  invoice?: Maybe<Invoices_Order_By>;
  invoice_id?: Maybe<Order_By>;
  invoice_view?: Maybe<Invoices_View_Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  order_products_invoices_aggregate?: Maybe<Order_Products_Invoice_Aggregate_Order_By>;
};

/** select columns of table "order_invoices" */
export enum Order_Invoices_Select_Column {
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  OrderId = 'order_id'
}

/** order by stddev() on columns of table "order_invoices" */
export type Order_Invoices_Stddev_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_invoices" */
export type Order_Invoices_Stddev_Pop_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_invoices" */
export type Order_Invoices_Stddev_Samp_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "order_invoices" */
export type Order_Invoices_Sum_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "order_invoices" */
export type Order_Invoices_Var_Pop_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_invoices" */
export type Order_Invoices_Var_Samp_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_invoices" */
export type Order_Invoices_Variance_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** columns and relationships of "order_products" */
export type Order_Products = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['Int'];
  qty_ordered: Scalars['Int'];
  sys_period: Scalars['tstzrange'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "order_products" */
export type Order_Products_Aggregate_Order_By = {
  avg?: Maybe<Order_Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Products_Max_Order_By>;
  min?: Maybe<Order_Products_Min_Order_By>;
  stddev?: Maybe<Order_Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Products_Sum_Order_By>;
  var_pop?: Maybe<Order_Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Products_Var_Samp_Order_By>;
  variance?: Maybe<Order_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_products" */
export type Order_Products_Arr_Rel_Insert_Input = {
  data: Array<Order_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_Products_On_Conflict>;
};

/** order by avg() on columns of table "order_products" */
export type Order_Products_Avg_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_products". All fields are combined with a logical 'AND'. */
export type Order_Products_Bool_Exp = {
  _and?: Maybe<Array<Order_Products_Bool_Exp>>;
  _not?: Maybe<Order_Products_Bool_Exp>;
  _or?: Maybe<Array<Order_Products_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_ordered?: Maybe<Int_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_products" */
export enum Order_Products_Constraint {
  /** unique or primary key constraint */
  OrderProductsOrderIdProductVariantIdKey = 'order_products_order_id_product_variant_id_key',
  /** unique or primary key constraint */
  OrderProductsPkey = 'order_products_pkey'
}

/** input type for incrementing numeric columns in table "order_products" */
export type Order_Products_Inc_Input = {
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_products" */
export type Order_Products_Insert_Input = {
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['Int']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "order_products_invoice" */
export type Order_Products_Invoice = {
  created_at: Scalars['timestamptz'];
  first_received_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars['Int'];
  /** An object relationship */
  invoice_view?: Maybe<Invoices_View>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  /** An object relationship */
  order_invoice?: Maybe<Order_Invoices>;
  /** An object relationship */
  order_product?: Maybe<Order_Products>;
  /** An object relationship */
  order_product_view?: Maybe<Order_Products_View>;
  /** An object relationship */
  order_view?: Maybe<Orders_View>;
  paid_per_unit: Scalars['numeric'];
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['Int'];
  qty_received: Scalars['Int'];
  sys_period: Scalars['tstzrange'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "order_products_invoice" */
export type Order_Products_Invoice_Aggregate_Order_By = {
  avg?: Maybe<Order_Products_Invoice_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Products_Invoice_Max_Order_By>;
  min?: Maybe<Order_Products_Invoice_Min_Order_By>;
  stddev?: Maybe<Order_Products_Invoice_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Products_Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Products_Invoice_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Products_Invoice_Sum_Order_By>;
  var_pop?: Maybe<Order_Products_Invoice_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Products_Invoice_Var_Samp_Order_By>;
  variance?: Maybe<Order_Products_Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_products_invoice" */
export type Order_Products_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Order_Products_Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_Products_Invoice_On_Conflict>;
};

/** order by avg() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Avg_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_products_invoice". All fields are combined with a logical 'AND'. */
export type Order_Products_Invoice_Bool_Exp = {
  _and?: Maybe<Array<Order_Products_Invoice_Bool_Exp>>;
  _not?: Maybe<Order_Products_Invoice_Bool_Exp>;
  _or?: Maybe<Array<Order_Products_Invoice_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_received_at?: Maybe<Timestamptz_Comparison_Exp>;
  invoice?: Maybe<Invoices_Bool_Exp>;
  invoice_id?: Maybe<Int_Comparison_Exp>;
  invoice_view?: Maybe<Invoices_View_Bool_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  order_invoice?: Maybe<Order_Invoices_Bool_Exp>;
  order_product?: Maybe<Order_Products_Bool_Exp>;
  order_product_view?: Maybe<Order_Products_View_Bool_Exp>;
  order_view?: Maybe<Orders_View_Bool_Exp>;
  paid_per_unit?: Maybe<Numeric_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_received?: Maybe<Int_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_products_invoice" */
export enum Order_Products_Invoice_Constraint {
  /** unique or primary key constraint */
  OrderProductsInvoicePkey = 'order_products_invoice_pkey'
}

/** input type for incrementing numeric columns in table "order_products_invoice" */
export type Order_Products_Invoice_Inc_Input = {
  invoice_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_products_invoice" */
export type Order_Products_Invoice_Insert_Input = {
  invoice?: Maybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['Int']>;
  order_invoice?: Maybe<Order_Invoices_Obj_Rel_Insert_Input>;
  order_product?: Maybe<Order_Products_Obj_Rel_Insert_Input>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  first_received_at?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  first_received_at?: Maybe<Order_By>;
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_products_invoice" */
export type Order_Products_Invoice_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Products_Invoice>;
};

/** on_conflict condition type for table "order_products_invoice" */
export type Order_Products_Invoice_On_Conflict = {
  constraint: Order_Products_Invoice_Constraint;
  update_columns?: Array<Order_Products_Invoice_Update_Column>;
  where?: Maybe<Order_Products_Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "order_products_invoice". */
export type Order_Products_Invoice_Order_By = {
  created_at?: Maybe<Order_By>;
  first_received_at?: Maybe<Order_By>;
  invoice?: Maybe<Invoices_Order_By>;
  invoice_id?: Maybe<Order_By>;
  invoice_view?: Maybe<Invoices_View_Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  order_invoice?: Maybe<Order_Invoices_Order_By>;
  order_product?: Maybe<Order_Products_Order_By>;
  order_product_view?: Maybe<Order_Products_View_Order_By>;
  order_view?: Maybe<Orders_View_Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_products_invoice */
export type Order_Products_Invoice_Pk_Columns_Input = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
  product_variant_id: Scalars['Int'];
};

/** select columns of table "order_products_invoice" */
export enum Order_Products_Invoice_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstReceivedAt = 'first_received_at',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaidPerUnit = 'paid_per_unit',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyReceived = 'qty_received',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_products_invoice" */
export type Order_Products_Invoice_Set_Input = {
  invoice_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Stddev_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Stddev_Pop_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Stddev_Samp_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** order by sum() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Sum_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** update columns of table "order_products_invoice" */
export enum Order_Products_Invoice_Update_Column {
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaidPerUnit = 'paid_per_unit',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyReceived = 'qty_received'
}

/** order by var_pop() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Var_Pop_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Var_Samp_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_products_invoice" */
export type Order_Products_Invoice_Variance_Order_By = {
  invoice_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
};

/** order by max() on columns of table "order_products" */
export type Order_Products_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "order_products" */
export type Order_Products_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_products" */
export type Order_Products_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Products>;
};

/** input type for inserting object relation for remote table "order_products" */
export type Order_Products_Obj_Rel_Insert_Input = {
  data: Order_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_Products_On_Conflict>;
};

/** on_conflict condition type for table "order_products" */
export type Order_Products_On_Conflict = {
  constraint: Order_Products_Constraint;
  update_columns?: Array<Order_Products_Update_Column>;
  where?: Maybe<Order_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "order_products". */
export type Order_Products_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_products */
export type Order_Products_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_products" */
export enum Order_Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyOrdered = 'qty_ordered',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_products" */
export type Order_Products_Set_Input = {
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "order_products" */
export type Order_Products_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_products" */
export type Order_Products_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_products" */
export type Order_Products_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** order by sum() on columns of table "order_products" */
export type Order_Products_Sum_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** update columns of table "order_products" */
export enum Order_Products_Update_Column {
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyOrdered = 'qty_ordered'
}

/** order by var_pop() on columns of table "order_products" */
export type Order_Products_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "order_products" */
export type Order_Products_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** order by variance() on columns of table "order_products" */
export type Order_Products_Variance_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
};

/** columns and relationships of "order_products_view" */
export type Order_Products_View = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  inventory_history_all_locations: Array<Inventory_History_View>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order?: Maybe<Orders_View>;
  order_id?: Maybe<Scalars['Int']>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  sales: Array<Fulfillments_View>;
  /** An aggregate relationship */
  sales_aggregate: Fulfillments_View_Aggregate;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  stock_level_per_location?: Maybe<Stock_Levels_Per_Location>;
  /** An array relationship */
  stock_levels_all_locations: Array<Stock_Levels_Per_Location>;
  /** An aggregate relationship */
  stock_levels_all_locations_aggregate: Stock_Levels_Per_Location_Aggregate;
  supplier_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  supplier_product_variant?: Maybe<Suppliers_Product_Variants>;
  sys_period?: Maybe<Scalars['tstzrange']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "order_products_view" */
export type Order_Products_ViewInventory_History_All_LocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_History_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_History_View_Order_By>>;
  where?: Maybe<Inventory_History_View_Bool_Exp>;
};


/** columns and relationships of "order_products_view" */
export type Order_Products_ViewSalesArgs = {
  distinct_on?: Maybe<Array<Fulfillments_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fulfillments_View_Order_By>>;
  where?: Maybe<Fulfillments_View_Bool_Exp>;
};


/** columns and relationships of "order_products_view" */
export type Order_Products_ViewSales_AggregateArgs = {
  distinct_on?: Maybe<Array<Fulfillments_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fulfillments_View_Order_By>>;
  where?: Maybe<Fulfillments_View_Bool_Exp>;
};


/** columns and relationships of "order_products_view" */
export type Order_Products_ViewStock_Levels_All_LocationsArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


/** columns and relationships of "order_products_view" */
export type Order_Products_ViewStock_Levels_All_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};

/** aggregated selection of "order_products_view" */
export type Order_Products_View_Aggregate = {
  aggregate?: Maybe<Order_Products_View_Aggregate_Fields>;
  nodes: Array<Order_Products_View>;
};

/** aggregate fields of "order_products_view" */
export type Order_Products_View_Aggregate_Fields = {
  avg?: Maybe<Order_Products_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Products_View_Max_Fields>;
  min?: Maybe<Order_Products_View_Min_Fields>;
  stddev?: Maybe<Order_Products_View_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Products_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Products_View_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Products_View_Sum_Fields>;
  var_pop?: Maybe<Order_Products_View_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Products_View_Var_Samp_Fields>;
  variance?: Maybe<Order_Products_View_Variance_Fields>;
};


/** aggregate fields of "order_products_view" */
export type Order_Products_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Products_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_products_view" */
export type Order_Products_View_Aggregate_Order_By = {
  avg?: Maybe<Order_Products_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Products_View_Max_Order_By>;
  min?: Maybe<Order_Products_View_Min_Order_By>;
  stddev?: Maybe<Order_Products_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Products_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Products_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Products_View_Sum_Order_By>;
  var_pop?: Maybe<Order_Products_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Products_View_Var_Samp_Order_By>;
  variance?: Maybe<Order_Products_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Products_View_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_products_view" */
export type Order_Products_View_Avg_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_products_view". All fields are combined with a logical 'AND'. */
export type Order_Products_View_Bool_Exp = {
  _and?: Maybe<Array<Order_Products_View_Bool_Exp>>;
  _not?: Maybe<Order_Products_View_Bool_Exp>;
  _or?: Maybe<Array<Order_Products_View_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inventory_history_all_locations?: Maybe<Inventory_History_View_Bool_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_View_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  paid_per_unit?: Maybe<Numeric_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_ordered?: Maybe<Int_Comparison_Exp>;
  qty_received?: Maybe<Bigint_Comparison_Exp>;
  sales?: Maybe<Fulfillments_View_Bool_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  stock_level_per_location?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
  stock_levels_all_locations?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  supplier_product_variant?: Maybe<Suppliers_Product_Variants_Bool_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Products_View_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['bigint']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_products_view" */
export type Order_Products_View_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Products_View_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['bigint']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_products_view" */
export type Order_Products_View_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "order_products_view". */
export type Order_Products_View_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventory_history_all_locations_aggregate?: Maybe<Inventory_History_View_Aggregate_Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  order?: Maybe<Orders_View_Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  sales_aggregate?: Maybe<Fulfillments_View_Aggregate_Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  stock_level_per_location?: Maybe<Stock_Levels_Per_Location_Order_By>;
  stock_levels_all_locations_aggregate?: Maybe<Stock_Levels_Per_Location_Aggregate_Order_By>;
  supplier_id?: Maybe<Order_By>;
  supplier_product_variant?: Maybe<Suppliers_Product_Variants_Order_By>;
  sys_period?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "order_products_view" */
export enum Order_Products_View_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaidPerUnit = 'paid_per_unit',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyOrdered = 'qty_ordered',
  /** column name */
  QtyReceived = 'qty_received',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Order_Products_View_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_products_view" */
export type Order_Products_View_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Products_View_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_products_view" */
export type Order_Products_View_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Products_View_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_products_view" */
export type Order_Products_View_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Products_View_Sum_Fields = {
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  paid_per_unit?: Maybe<Scalars['numeric']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['bigint']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_products_view" */
export type Order_Products_View_Sum_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Products_View_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_products_view" */
export type Order_Products_View_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Products_View_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_products_view" */
export type Order_Products_View_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Products_View_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  paid_per_unit?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_products_view" */
export type Order_Products_View_Variance_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  paid_per_unit?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "order_status". All fields are combined with logical 'AND'. */
export type Order_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['order_status']>;
  _gt?: Maybe<Scalars['order_status']>;
  _gte?: Maybe<Scalars['order_status']>;
  _in?: Maybe<Array<Scalars['order_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['order_status']>;
  _lte?: Maybe<Scalars['order_status']>;
  _neq?: Maybe<Scalars['order_status']>;
  _nin?: Maybe<Array<Scalars['order_status']>>;
};


/** Boolean expression to compare columns of type "order_status_set". All fields are combined with logical 'AND'. */
export type Order_Status_Set_Comparison_Exp = {
  _eq?: Maybe<Scalars['order_status_set']>;
  _gt?: Maybe<Scalars['order_status_set']>;
  _gte?: Maybe<Scalars['order_status_set']>;
  _in?: Maybe<Array<Scalars['order_status_set']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['order_status_set']>;
  _lte?: Maybe<Scalars['order_status_set']>;
  _neq?: Maybe<Scalars['order_status_set']>;
  _nin?: Maybe<Array<Scalars['order_status_set']>>;
};

/** Purchase Order */
export type Orders = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  order_date: Scalars['date'];
  /** An array relationship */
  order_invoices: Array<Order_Invoices>;
  order_number: Scalars['Int'];
  /** An array relationship */
  order_products: Array<Order_Products>;
  /** An array relationship */
  order_products_extended: Array<Order_Products_View>;
  /** An aggregate relationship */
  order_products_extended_aggregate: Order_Products_View_Aggregate;
  /** An object relationship */
  order_view?: Maybe<Orders_View>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  status: Scalars['order_status_set'];
  /** An object relationship */
  supplier: Suppliers;
  supplier_id: Scalars['Int'];
  sys_period: Scalars['tstzrange'];
  updated_at: Scalars['timestamptz'];
};


/** Purchase Order */
export type OrdersOrder_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Invoices_Order_By>>;
  where?: Maybe<Order_Invoices_Bool_Exp>;
};


/** Purchase Order */
export type OrdersOrder_ProductsArgs = {
  distinct_on?: Maybe<Array<Order_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Order_By>>;
  where?: Maybe<Order_Products_Bool_Exp>;
};


/** Purchase Order */
export type OrdersOrder_Products_ExtendedArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};


/** Purchase Order */
export type OrdersOrder_Products_Extended_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Orders_Bool_Exp>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Orders_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  order_date?: Maybe<Date_Comparison_Exp>;
  order_invoices?: Maybe<Order_Invoices_Bool_Exp>;
  order_number?: Maybe<Int_Comparison_Exp>;
  order_products?: Maybe<Order_Products_Bool_Exp>;
  order_products_extended?: Maybe<Order_Products_View_Bool_Exp>;
  order_view?: Maybe<Orders_View_Bool_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Order_Status_Set_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint */
  OrderNumberShopIdUnique = 'order_number_shop_id_unique',
  /** unique or primary key constraint */
  OrdersPkey = 'orders_pkey'
}

/** input type for incrementing numeric columns in table "orders" */
export type Orders_Inc_Input = {
  location_id?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  location_id?: Maybe<Scalars['Int']>;
  order_invoices?: Maybe<Order_Invoices_Arr_Rel_Insert_Input>;
  order_products?: Maybe<Order_Products_Arr_Rel_Insert_Input>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Suppliers_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  data: Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns?: Array<Orders_Update_Column>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  archived_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  order_date?: Maybe<Order_By>;
  order_invoices_aggregate?: Maybe<Order_Invoices_Aggregate_Order_By>;
  order_number?: Maybe<Order_By>;
  order_products_aggregate?: Maybe<Order_Products_Aggregate_Order_By>;
  order_products_extended_aggregate?: Maybe<Order_Products_View_Aggregate_Order_By>;
  order_view?: Maybe<Orders_View_Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  location_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['date']>;
  order_number?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['order_status_set']>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id'
}

/** columns and relationships of "orders_view" */
export type Orders_View = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  first_received_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_created_at?: Maybe<Scalars['timestamptz']>;
  order_date?: Maybe<Scalars['date']>;
  order_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  order_invoices: Array<Order_Invoices>;
  order_number?: Maybe<Scalars['Int']>;
  /** An array relationship */
  order_products: Array<Order_Products_View>;
  /** An aggregate relationship */
  order_products_aggregate: Order_Products_View_Aggregate;
  paid?: Maybe<Scalars['numeric']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['order_status']>;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplier_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  version_created_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "orders_view" */
export type Orders_ViewOrder_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Invoices_Order_By>>;
  where?: Maybe<Order_Invoices_Bool_Exp>;
};


/** columns and relationships of "orders_view" */
export type Orders_ViewOrder_ProductsArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};


/** columns and relationships of "orders_view" */
export type Orders_ViewOrder_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};

/** aggregated selection of "orders_view" */
export type Orders_View_Aggregate = {
  aggregate?: Maybe<Orders_View_Aggregate_Fields>;
  nodes: Array<Orders_View>;
};

/** aggregate fields of "orders_view" */
export type Orders_View_Aggregate_Fields = {
  avg?: Maybe<Orders_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_View_Max_Fields>;
  min?: Maybe<Orders_View_Min_Fields>;
  stddev?: Maybe<Orders_View_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_View_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_View_Sum_Fields>;
  var_pop?: Maybe<Orders_View_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_View_Var_Samp_Fields>;
  variance?: Maybe<Orders_View_Variance_Fields>;
};


/** aggregate fields of "orders_view" */
export type Orders_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Orders_View_Avg_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "orders_view". All fields are combined with a logical 'AND'. */
export type Orders_View_Bool_Exp = {
  _and?: Maybe<Array<Orders_View_Bool_Exp>>;
  _not?: Maybe<Orders_View_Bool_Exp>;
  _or?: Maybe<Array<Orders_View_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_received_at?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  order_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  order_date?: Maybe<Date_Comparison_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  order_invoices?: Maybe<Order_Invoices_Bool_Exp>;
  order_number?: Maybe<Int_Comparison_Exp>;
  order_products?: Maybe<Order_Products_View_Bool_Exp>;
  paid?: Maybe<Numeric_Comparison_Exp>;
  qty_ordered?: Maybe<Int_Comparison_Exp>;
  qty_received?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Order_Status_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  version_created_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Orders_View_Max_Fields = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  first_received_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_created_at?: Maybe<Scalars['timestamptz']>;
  order_date?: Maybe<Scalars['date']>;
  order_id?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['numeric']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  version_created_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Orders_View_Min_Fields = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  first_received_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_created_at?: Maybe<Scalars['timestamptz']>;
  order_date?: Maybe<Scalars['date']>;
  order_id?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['numeric']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  version_created_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "orders_view". */
export type Orders_View_Order_By = {
  archived_at?: Maybe<Order_By>;
  first_received_at?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  order_created_at?: Maybe<Order_By>;
  order_date?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  order_invoices_aggregate?: Maybe<Order_Invoices_Aggregate_Order_By>;
  order_number?: Maybe<Order_By>;
  order_products_aggregate?: Maybe<Order_Products_View_Aggregate_Order_By>;
  paid?: Maybe<Order_By>;
  qty_ordered?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  version_created_at?: Maybe<Order_By>;
};

/** select columns of table "orders_view" */
export enum Orders_View_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  FirstReceivedAt = 'first_received_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderCreatedAt = 'order_created_at',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  Paid = 'paid',
  /** column name */
  QtyOrdered = 'qty_ordered',
  /** column name */
  QtyReceived = 'qty_received',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Total = 'total',
  /** column name */
  VersionCreatedAt = 'version_created_at'
}

/** aggregate stddev on columns */
export type Orders_View_Stddev_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Orders_View_Stddev_Pop_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Orders_View_Stddev_Samp_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Orders_View_Sum_Fields = {
  location_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['numeric']>;
  qty_ordered?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Orders_View_Var_Pop_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Orders_View_Var_Samp_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Orders_View_Variance_Fields = {
  location_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  qty_ordered?: Maybe<Scalars['Float']>;
  qty_received?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  supplier_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "product_variants" */
export type Product_Variants = {
  available_for_sale: Scalars['Boolean'];
  /** An array relationship */
  bin_locations: Array<Bin_Locations>;
  created_at: Scalars['timestamptz'];
  display_name: Scalars['String'];
  id: Scalars['Int'];
  inventory_days_available?: Maybe<Scalars['Int']>;
  inventory_id: Scalars['String'];
  price: Scalars['numeric'];
  /** An object relationship */
  product?: Maybe<Products>;
  /** An object relationship */
  product_variant_view?: Maybe<Product_Variants_View>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  shopify_product_id: Scalars['String'];
  shopify_product_variant_id: Scalars['String'];
  shopify_unit_cost?: Maybe<Scalars['numeric']>;
  sku?: Maybe<Scalars['String']>;
  /** An object relationship */
  stock_level?: Maybe<Stock_Levels>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "product_variants" */
export type Product_VariantsBin_LocationsArgs = {
  distinct_on?: Maybe<Array<Bin_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bin_Locations_Order_By>>;
  where?: Maybe<Bin_Locations_Bool_Exp>;
};


/** columns and relationships of "product_variants" */
export type Product_VariantsInventory_Days_AvailableArgs = {
  args: Inventory_Days_Available_Product_Variants_Args;
};

/** order by aggregate values of table "product_variants" */
export type Product_Variants_Aggregate_Order_By = {
  avg?: Maybe<Product_Variants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Variants_Max_Order_By>;
  min?: Maybe<Product_Variants_Min_Order_By>;
  stddev?: Maybe<Product_Variants_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Variants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Variants_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Variants_Sum_Order_By>;
  var_pop?: Maybe<Product_Variants_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Variants_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variants_Variance_Order_By>;
};

/** order by avg() on columns of table "product_variants" */
export type Product_Variants_Avg_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_variants". All fields are combined with a logical 'AND'. */
export type Product_Variants_Bool_Exp = {
  _and?: Maybe<Array<Product_Variants_Bool_Exp>>;
  _not?: Maybe<Product_Variants_Bool_Exp>;
  _or?: Maybe<Array<Product_Variants_Bool_Exp>>;
  available_for_sale?: Maybe<Boolean_Comparison_Exp>;
  bin_locations?: Maybe<Bin_Locations_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inventory_id?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  product_variant_view?: Maybe<Product_Variants_View_Bool_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  shopify_product_id?: Maybe<String_Comparison_Exp>;
  shopify_product_variant_id?: Maybe<String_Comparison_Exp>;
  shopify_unit_cost?: Maybe<Numeric_Comparison_Exp>;
  sku?: Maybe<String_Comparison_Exp>;
  stock_level?: Maybe<Stock_Levels_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "product_variants" */
export type Product_Variants_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventory_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_product_id?: Maybe<Order_By>;
  shopify_product_variant_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "product_variants" */
export type Product_Variants_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventory_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_product_id?: Maybe<Order_By>;
  shopify_product_variant_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "product_variants". */
export type Product_Variants_Order_By = {
  available_for_sale?: Maybe<Order_By>;
  bin_locations_aggregate?: Maybe<Bin_Locations_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventory_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  product_variant_view?: Maybe<Product_Variants_View_Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_product_id?: Maybe<Order_By>;
  shopify_product_variant_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
  sku?: Maybe<Order_By>;
  stock_level?: Maybe<Stock_Levels_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "product_variants" */
export enum Product_Variants_Select_Column {
  /** column name */
  AvailableForSale = 'available_for_sale',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryId = 'inventory_id',
  /** column name */
  Price = 'price',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  ShopifyProductId = 'shopify_product_id',
  /** column name */
  ShopifyProductVariantId = 'shopify_product_variant_id',
  /** column name */
  ShopifyUnitCost = 'shopify_unit_cost',
  /** column name */
  Sku = 'sku',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "product_variants" */
export type Product_Variants_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "product_variants" */
export type Product_Variants_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "product_variants" */
export type Product_Variants_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** order by sum() on columns of table "product_variants" */
export type Product_Variants_Sum_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "product_variants" */
export type Product_Variants_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "product_variants" */
export type Product_Variants_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** order by variance() on columns of table "product_variants" */
export type Product_Variants_Variance_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  shopify_unit_cost?: Maybe<Order_By>;
};

/** columns and relationships of "product_variants_view" */
export type Product_Variants_View = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "product_variants_view". All fields are combined with a logical 'AND'. */
export type Product_Variants_View_Bool_Exp = {
  _and?: Maybe<Array<Product_Variants_View_Bool_Exp>>;
  _not?: Maybe<Product_Variants_View_Bool_Exp>;
  _or?: Maybe<Array<Product_Variants_View_Bool_Exp>>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "product_variants_view". */
export type Product_Variants_View_Order_By = {
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
};

/** select columns of table "product_variants_view" */
export enum Product_Variants_View_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shop_id'
}

/** columns and relationships of "products" */
export type Products = {
  created_at: Scalars['timestamptz'];
  handle: Scalars['String'];
  id: Scalars['String'];
  online_store_preview_url?: Maybe<Scalars['String']>;
  online_store_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  product_variants: Array<Product_Variants>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  status: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['Int'];
};


/** columns and relationships of "products" */
export type ProductsProduct_VariantsArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  avg?: Maybe<Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
  stddev?: Maybe<Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Products_Sum_Order_By>;
  var_pop?: Maybe<Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Products_Var_Samp_Order_By>;
  variance?: Maybe<Products_Variance_Order_By>;
};

/** order by avg() on columns of table "products" */
export type Products_Avg_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: Maybe<Array<Products_Bool_Exp>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Products_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  online_store_preview_url?: Maybe<String_Comparison_Exp>;
  online_store_url?: Maybe<String_Comparison_Exp>;
  product_variants?: Maybe<Product_Variants_Bool_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vendor?: Maybe<Vendors_Bool_Exp>;
  vendor_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  online_store_preview_url?: Maybe<Order_By>;
  online_store_url?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  online_store_preview_url?: Maybe<Order_By>;
  online_store_url?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  created_at?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  online_store_preview_url?: Maybe<Order_By>;
  online_store_url?: Maybe<Order_By>;
  product_variants_aggregate?: Maybe<Product_Variants_Aggregate_Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vendor?: Maybe<Vendors_Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  OnlineStorePreviewUrl = 'online_store_preview_url',
  /** column name */
  OnlineStoreUrl = 'online_store_url',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** order by stddev() on columns of table "products" */
export type Products_Stddev_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "products" */
export type Products_Stddev_Pop_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "products" */
export type Products_Stddev_Samp_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "products" */
export type Products_Sum_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "products" */
export type Products_Var_Pop_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "products" */
export type Products_Var_Samp_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "products" */
export type Products_Variance_Order_By = {
  shop_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

export type Query_Root = {
  /** An array relationship */
  bin_locations: Array<Bin_Locations>;
  /** fetch data from the table: "bin_locations" using primary key columns */
  bin_locations_by_pk?: Maybe<Bin_Locations>;
  /** fetch data from the table: "fulfillments_view" */
  fulfillments_view: Array<Fulfillments_View>;
  /** fetch aggregated fields from the table: "fulfillments_view" */
  fulfillments_view_aggregate: Fulfillments_View_Aggregate;
  /** fetch data from the table: "integrations_status" */
  integrations_status: Array<Integrations_Status>;
  /** fetch data from the table: "inventory_history_view" */
  inventory_history_view: Array<Inventory_History_View>;
  /** An array relationship */
  invoice_expenses: Array<Invoice_Expenses>;
  /** An aggregate relationship */
  invoice_expenses_aggregate: Invoice_Expenses_Aggregate;
  /** fetch data from the table: "invoice_expenses" using primary key columns */
  invoice_expenses_by_pk?: Maybe<Invoice_Expenses>;
  /** fetch data from the table: "invoices" */
  invoices: Array<Invoices>;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** fetch data from the table: "invoices_view" */
  invoices_view: Array<Invoices_View>;
  /** An array relationship */
  locations: Array<Locations>;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  mock: Scalars['Boolean'];
  /** An array relationship */
  order_invoices: Array<Order_Invoices>;
  /** fetch data from the table: "order_invoices" using primary key columns */
  order_invoices_by_pk?: Maybe<Order_Invoices>;
  /** An array relationship */
  order_products: Array<Order_Products>;
  /** fetch data from the table: "order_products" using primary key columns */
  order_products_by_pk?: Maybe<Order_Products>;
  /** fetch data from the table: "order_products_invoice" */
  order_products_invoice: Array<Order_Products_Invoice>;
  /** fetch data from the table: "order_products_invoice" using primary key columns */
  order_products_invoice_by_pk?: Maybe<Order_Products_Invoice>;
  /** fetch data from the table: "order_products_view" */
  order_products_view: Array<Order_Products_View>;
  /** fetch aggregated fields from the table: "order_products_view" */
  order_products_view_aggregate: Order_Products_View_Aggregate;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "orders_view" */
  orders_view: Array<Orders_View>;
  /** fetch aggregated fields from the table: "orders_view" */
  orders_view_aggregate: Orders_View_Aggregate;
  /** An array relationship */
  product_variants: Array<Product_Variants>;
  /** fetch data from the table: "product_variants" using primary key columns */
  product_variants_by_pk?: Maybe<Product_Variants>;
  /** fetch data from the table: "product_variants_view" */
  product_variants_view: Array<Product_Variants_View>;
  /** An array relationship */
  products: Array<Products>;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table: "shops" */
  shops: Array<Shops>;
  /** fetch data from the table: "shops" using primary key columns */
  shops_by_pk?: Maybe<Shops>;
  /** fetch data from the table: "stock_levels" */
  stock_levels: Array<Stock_Levels>;
  /** fetch data from the table: "stock_levels_per_location" */
  stock_levels_per_location: Array<Stock_Levels_Per_Location>;
  /** fetch aggregated fields from the table: "stock_levels_per_location" */
  stock_levels_per_location_aggregate: Stock_Levels_Per_Location_Aggregate;
  /** execute function "stock_levels_per_location_period" which returns "stock_levels_per_location" */
  stock_levels_per_location_period: Array<Stock_Levels_Per_Location>;
  /** execute function "stock_levels_per_location_period" and query aggregates on result of table type "stock_levels_per_location" */
  stock_levels_per_location_period_aggregate: Stock_Levels_Per_Location_Aggregate;
  /** execute function "stock_levels_per_period" which returns "stock_levels" */
  stock_levels_per_period: Array<Stock_Levels>;
  /** fetch data from the table: "stocktake_locked" */
  stocktake_locked: Array<Stocktake_Locked>;
  /** fetch data from the table: "stocktake_locked" using primary key columns */
  stocktake_locked_by_pk?: Maybe<Stocktake_Locked>;
  /** An array relationship */
  stocktake_products: Array<Stocktake_Products>;
  /** fetch data from the table: "stocktake_view" */
  stocktake_view: Array<Stocktake_View>;
  /** fetch data from the table: "stocktakes" */
  stocktakes: Array<Stocktakes>;
  /** fetch data from the table: "stocktakes" using primary key columns */
  stocktakes_by_pk?: Maybe<Stocktakes>;
  /** fetch data from the table: "suppliers" */
  suppliers: Array<Suppliers>;
  /** fetch data from the table: "suppliers" using primary key columns */
  suppliers_by_pk?: Maybe<Suppliers>;
  /** fetch data from the table: "suppliers_product_variants" */
  suppliers_product_variants: Array<Suppliers_Product_Variants>;
  /** fetch data from the table: "suppliers_product_variants_all" */
  suppliers_product_variants_all: Array<Suppliers_Product_Variants_All>;
  /** An array relationship */
  suppliers_vendors: Array<Suppliers_Vendors>;
  /** fetch data from the table: "suppliers_vendors" using primary key columns */
  suppliers_vendors_by_pk?: Maybe<Suppliers_Vendors>;
  /** fetch data from the table: "suppliers_vendors_products" */
  suppliers_vendors_products: Array<Suppliers_Vendors_Products>;
  /** fetch data from the table: "transfer_products" */
  transfer_products: Array<Transfer_Products>;
  /** fetch data from the table: "transfer_products" using primary key columns */
  transfer_products_by_pk?: Maybe<Transfer_Products>;
  /** fetch data from the table: "transfer_products_view" */
  transfer_products_view: Array<Transfer_Products_View>;
  /** fetch data from the table: "transfers" */
  transfers: Array<Transfers>;
  /** fetch data from the table: "transfers" using primary key columns */
  transfers_by_pk?: Maybe<Transfers>;
  /** fetch data from the table: "transfers_view" */
  transfers_view: Array<Transfers_View>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vendors" */
  vendors: Array<Vendors>;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table: "xero_contacts" */
  xero_contacts: Array<Xero_Contacts>;
  /** fetch data from the table: "xero_contacts" using primary key columns */
  xero_contacts_by_pk?: Maybe<Xero_Contacts>;
};


export type Query_RootBin_LocationsArgs = {
  distinct_on?: Maybe<Array<Bin_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bin_Locations_Order_By>>;
  where?: Maybe<Bin_Locations_Bool_Exp>;
};


export type Query_RootBin_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFulfillments_ViewArgs = {
  distinct_on?: Maybe<Array<Fulfillments_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fulfillments_View_Order_By>>;
  where?: Maybe<Fulfillments_View_Bool_Exp>;
};


export type Query_RootFulfillments_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Fulfillments_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fulfillments_View_Order_By>>;
  where?: Maybe<Fulfillments_View_Bool_Exp>;
};


export type Query_RootIntegrations_StatusArgs = {
  distinct_on?: Maybe<Array<Integrations_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integrations_Status_Order_By>>;
  where?: Maybe<Integrations_Status_Bool_Exp>;
};


export type Query_RootInventory_History_ViewArgs = {
  distinct_on?: Maybe<Array<Inventory_History_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_History_View_Order_By>>;
  where?: Maybe<Inventory_History_View_Bool_Exp>;
};


export type Query_RootInvoice_ExpensesArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


export type Query_RootInvoice_Expenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


export type Query_RootInvoice_Expenses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Query_RootInvoices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoices_ViewArgs = {
  distinct_on?: Maybe<Array<Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_View_Order_By>>;
  where?: Maybe<Invoices_View_Bool_Exp>;
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Invoices_Order_By>>;
  where?: Maybe<Order_Invoices_Bool_Exp>;
};


export type Query_RootOrder_Invoices_By_PkArgs = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
};


export type Query_RootOrder_ProductsArgs = {
  distinct_on?: Maybe<Array<Order_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Order_By>>;
  where?: Maybe<Order_Products_Bool_Exp>;
};


export type Query_RootOrder_Products_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Products_InvoiceArgs = {
  distinct_on?: Maybe<Array<Order_Products_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Invoice_Order_By>>;
  where?: Maybe<Order_Products_Invoice_Bool_Exp>;
};


export type Query_RootOrder_Products_Invoice_By_PkArgs = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
  product_variant_id: Scalars['Int'];
};


export type Query_RootOrder_Products_ViewArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};


export type Query_RootOrder_Products_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};


export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrders_ViewArgs = {
  distinct_on?: Maybe<Array<Orders_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_View_Order_By>>;
  where?: Maybe<Orders_View_Bool_Exp>;
};


export type Query_RootOrders_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_View_Order_By>>;
  where?: Maybe<Orders_View_Bool_Exp>;
};


export type Query_RootProduct_VariantsArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Query_RootProduct_Variants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProduct_Variants_ViewArgs = {
  distinct_on?: Maybe<Array<Product_Variants_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_View_Order_By>>;
  where?: Maybe<Product_Variants_View_Bool_Exp>;
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootReportsArgs = {
  distinct_on?: Maybe<Array<Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reports_Order_By>>;
  where?: Maybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  date: Scalars['date'];
  location_id: Scalars['Int'];
};


export type Query_RootShopsArgs = {
  distinct_on?: Maybe<Array<Shops_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shops_Order_By>>;
  where?: Maybe<Shops_Bool_Exp>;
};


export type Query_RootShops_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStock_LevelsArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Order_By>>;
  where?: Maybe<Stock_Levels_Bool_Exp>;
};


export type Query_RootStock_Levels_Per_LocationArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Query_RootStock_Levels_Per_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Query_RootStock_Levels_Per_Location_PeriodArgs = {
  args: Stock_Levels_Per_Location_Period_Args;
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Query_RootStock_Levels_Per_Location_Period_AggregateArgs = {
  args: Stock_Levels_Per_Location_Period_Args;
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Query_RootStock_Levels_Per_PeriodArgs = {
  args: Stock_Levels_Per_Period_Args;
  distinct_on?: Maybe<Array<Stock_Levels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Order_By>>;
  where?: Maybe<Stock_Levels_Bool_Exp>;
};


export type Query_RootStocktake_LockedArgs = {
  distinct_on?: Maybe<Array<Stocktake_Locked_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Locked_Order_By>>;
  where?: Maybe<Stocktake_Locked_Bool_Exp>;
};


export type Query_RootStocktake_Locked_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStocktake_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocktake_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Products_Order_By>>;
  where?: Maybe<Stocktake_Products_Bool_Exp>;
};


export type Query_RootStocktake_ViewArgs = {
  distinct_on?: Maybe<Array<Stocktake_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_View_Order_By>>;
  where?: Maybe<Stocktake_View_Bool_Exp>;
};


export type Query_RootStocktakesArgs = {
  distinct_on?: Maybe<Array<Stocktakes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktakes_Order_By>>;
  where?: Maybe<Stocktakes_Bool_Exp>;
};


export type Query_RootStocktakes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSuppliersArgs = {
  distinct_on?: Maybe<Array<Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Order_By>>;
  where?: Maybe<Suppliers_Bool_Exp>;
};


export type Query_RootSuppliers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSuppliers_Product_VariantsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Product_Variants_Order_By>>;
  where?: Maybe<Suppliers_Product_Variants_Bool_Exp>;
};


export type Query_RootSuppliers_Product_Variants_AllArgs = {
  distinct_on?: Maybe<Array<Suppliers_Product_Variants_All_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Product_Variants_All_Order_By>>;
  where?: Maybe<Suppliers_Product_Variants_All_Bool_Exp>;
};


export type Query_RootSuppliers_VendorsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Vendors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Vendors_Order_By>>;
  where?: Maybe<Suppliers_Vendors_Bool_Exp>;
};


export type Query_RootSuppliers_Vendors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSuppliers_Vendors_ProductsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Vendors_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Vendors_Products_Order_By>>;
  where?: Maybe<Suppliers_Vendors_Products_Bool_Exp>;
};


export type Query_RootTransfer_ProductsArgs = {
  distinct_on?: Maybe<Array<Transfer_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Products_Order_By>>;
  where?: Maybe<Transfer_Products_Bool_Exp>;
};


export type Query_RootTransfer_Products_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransfer_Products_ViewArgs = {
  distinct_on?: Maybe<Array<Transfer_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Products_View_Order_By>>;
  where?: Maybe<Transfer_Products_View_Bool_Exp>;
};


export type Query_RootTransfersArgs = {
  distinct_on?: Maybe<Array<Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfers_Order_By>>;
  where?: Maybe<Transfers_Bool_Exp>;
};


export type Query_RootTransfers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransfers_ViewArgs = {
  distinct_on?: Maybe<Array<Transfers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfers_View_Order_By>>;
  where?: Maybe<Transfers_View_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVendorsArgs = {
  distinct_on?: Maybe<Array<Vendors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vendors_Order_By>>;
  where?: Maybe<Vendors_Bool_Exp>;
};


export type Query_RootVendors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootXero_ContactsArgs = {
  distinct_on?: Maybe<Array<Xero_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xero_Contacts_Order_By>>;
  where?: Maybe<Xero_Contacts_Bool_Exp>;
};


export type Query_RootXero_Contacts_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "reports" */
export type Reports = {
  date: Scalars['date'];
  in_stock: Scalars['Int'];
  in_transfer: Scalars['Int'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id: Scalars['Int'];
  value_in_transfer: Scalars['numeric'];
  value_on_hand: Scalars['numeric'];
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: Maybe<Array<Reports_Bool_Exp>>;
  _not?: Maybe<Reports_Bool_Exp>;
  _or?: Maybe<Array<Reports_Bool_Exp>>;
  date?: Maybe<Date_Comparison_Exp>;
  in_stock?: Maybe<Int_Comparison_Exp>;
  in_transfer?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  value_in_transfer?: Maybe<Numeric_Comparison_Exp>;
  value_on_hand?: Maybe<Numeric_Comparison_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  date?: Maybe<Order_By>;
  in_stock?: Maybe<Order_By>;
  in_transfer?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  InStock = 'in_stock',
  /** column name */
  InTransfer = 'in_transfer',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ValueInTransfer = 'value_in_transfer',
  /** column name */
  ValueOnHand = 'value_on_hand'
}

/** columns and relationships of "shops" */
export type Shops = {
  bin_locations_active: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An array relationship */
  locations: Array<Locations>;
  shop: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
};


/** columns and relationships of "shops" */
export type ShopsLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "shops" */
export type ShopsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "shops". All fields are combined with a logical 'AND'. */
export type Shops_Bool_Exp = {
  _and?: Maybe<Array<Shops_Bool_Exp>>;
  _not?: Maybe<Shops_Bool_Exp>;
  _or?: Maybe<Array<Shops_Bool_Exp>>;
  bin_locations_active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  locations?: Maybe<Locations_Bool_Exp>;
  shop?: Maybe<String_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "shops". */
export type Shops_Order_By = {
  bin_locations_active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Locations_Aggregate_Order_By>;
  shop?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** select columns of table "shops" */
export enum Shops_Select_Column {
  /** column name */
  BinLocationsActive = 'bin_locations_active',
  /** column name */
  Id = 'id',
  /** column name */
  Shop = 'shop'
}

export type Start_Stocktake_Args = {
  arg_1?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "stock_levels" */
export type Stock_Levels = {
  average_cost?: Maybe<Scalars['numeric']>;
  in_stock?: Maybe<Scalars['Int']>;
  in_stock_shopify?: Maybe<Scalars['Int']>;
  last_paid_per_unit?: Maybe<Scalars['numeric']>;
  orders_in_transfer?: Maybe<Scalars['Int']>;
  orders_received?: Maybe<Scalars['Int']>;
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  sold_qty?: Maybe<Scalars['Int']>;
  supplier_code?: Maybe<Scalars['String']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Int']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Int']>;
  transfers_fulfilled?: Maybe<Scalars['Int']>;
  transfers_in_transfer?: Maybe<Scalars['Int']>;
  transfers_received?: Maybe<Scalars['Int']>;
  unsynced_adjustments?: Maybe<Scalars['Int']>;
  value_in_transfer?: Maybe<Scalars['numeric']>;
  value_on_hand?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "stock_levels". All fields are combined with a logical 'AND'. */
export type Stock_Levels_Bool_Exp = {
  _and?: Maybe<Array<Stock_Levels_Bool_Exp>>;
  _not?: Maybe<Stock_Levels_Bool_Exp>;
  _or?: Maybe<Array<Stock_Levels_Bool_Exp>>;
  average_cost?: Maybe<Numeric_Comparison_Exp>;
  in_stock?: Maybe<Int_Comparison_Exp>;
  in_stock_shopify?: Maybe<Int_Comparison_Exp>;
  last_paid_per_unit?: Maybe<Numeric_Comparison_Exp>;
  orders_in_transfer?: Maybe<Int_Comparison_Exp>;
  orders_received?: Maybe<Int_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  sold_qty?: Maybe<Int_Comparison_Exp>;
  supplier_code?: Maybe<String_Comparison_Exp>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Int_Comparison_Exp>;
  transfers_awaiting_to_fulfill?: Maybe<Int_Comparison_Exp>;
  transfers_fulfilled?: Maybe<Int_Comparison_Exp>;
  transfers_in_transfer?: Maybe<Int_Comparison_Exp>;
  transfers_received?: Maybe<Int_Comparison_Exp>;
  unsynced_adjustments?: Maybe<Int_Comparison_Exp>;
  value_in_transfer?: Maybe<Numeric_Comparison_Exp>;
  value_on_hand?: Maybe<Numeric_Comparison_Exp>;
};

/** Ordering options when selecting data from "stock_levels". */
export type Stock_Levels_Order_By = {
  average_cost?: Maybe<Order_By>;
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  supplier_code?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** columns and relationships of "stock_levels_per_location" */
export type Stock_Levels_Per_Location = {
  in_stock?: Maybe<Scalars['Int']>;
  in_stock_shopify?: Maybe<Scalars['Int']>;
  last_paid_per_unit?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['Int']>;
  orders_in_transfer?: Maybe<Scalars['Int']>;
  orders_received?: Maybe<Scalars['Int']>;
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  sold_qty?: Maybe<Scalars['Int']>;
  supplier_code?: Maybe<Scalars['String']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Int']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Int']>;
  transfers_fulfilled?: Maybe<Scalars['Int']>;
  transfers_in_transfer?: Maybe<Scalars['Int']>;
  transfers_received?: Maybe<Scalars['Int']>;
  unsynced_adjustments?: Maybe<Scalars['Int']>;
  value_in_transfer?: Maybe<Scalars['numeric']>;
  value_on_hand?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Aggregate = {
  aggregate?: Maybe<Stock_Levels_Per_Location_Aggregate_Fields>;
  nodes: Array<Stock_Levels_Per_Location>;
};

/** aggregate fields of "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Aggregate_Fields = {
  avg?: Maybe<Stock_Levels_Per_Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Levels_Per_Location_Max_Fields>;
  min?: Maybe<Stock_Levels_Per_Location_Min_Fields>;
  stddev?: Maybe<Stock_Levels_Per_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Levels_Per_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Levels_Per_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Levels_Per_Location_Sum_Fields>;
  var_pop?: Maybe<Stock_Levels_Per_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Levels_Per_Location_Var_Samp_Fields>;
  variance?: Maybe<Stock_Levels_Per_Location_Variance_Fields>;
};


/** aggregate fields of "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Aggregate_Order_By = {
  avg?: Maybe<Stock_Levels_Per_Location_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Levels_Per_Location_Max_Order_By>;
  min?: Maybe<Stock_Levels_Per_Location_Min_Order_By>;
  stddev?: Maybe<Stock_Levels_Per_Location_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Levels_Per_Location_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Levels_Per_Location_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Levels_Per_Location_Sum_Order_By>;
  var_pop?: Maybe<Stock_Levels_Per_Location_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Levels_Per_Location_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Levels_Per_Location_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Stock_Levels_Per_Location_Avg_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Avg_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_levels_per_location". All fields are combined with a logical 'AND'. */
export type Stock_Levels_Per_Location_Bool_Exp = {
  _and?: Maybe<Array<Stock_Levels_Per_Location_Bool_Exp>>;
  _not?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
  _or?: Maybe<Array<Stock_Levels_Per_Location_Bool_Exp>>;
  in_stock?: Maybe<Int_Comparison_Exp>;
  in_stock_shopify?: Maybe<Int_Comparison_Exp>;
  last_paid_per_unit?: Maybe<Numeric_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  orders_in_transfer?: Maybe<Int_Comparison_Exp>;
  orders_received?: Maybe<Int_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  sold_qty?: Maybe<Int_Comparison_Exp>;
  supplier_code?: Maybe<String_Comparison_Exp>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Int_Comparison_Exp>;
  transfers_awaiting_to_fulfill?: Maybe<Int_Comparison_Exp>;
  transfers_fulfilled?: Maybe<Int_Comparison_Exp>;
  transfers_in_transfer?: Maybe<Int_Comparison_Exp>;
  transfers_received?: Maybe<Int_Comparison_Exp>;
  unsynced_adjustments?: Maybe<Int_Comparison_Exp>;
  value_in_transfer?: Maybe<Numeric_Comparison_Exp>;
  value_on_hand?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Stock_Levels_Per_Location_Max_Fields = {
  in_stock?: Maybe<Scalars['Int']>;
  in_stock_shopify?: Maybe<Scalars['Int']>;
  last_paid_per_unit?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['Int']>;
  orders_in_transfer?: Maybe<Scalars['Int']>;
  orders_received?: Maybe<Scalars['Int']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  sold_qty?: Maybe<Scalars['Int']>;
  supplier_code?: Maybe<Scalars['String']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Int']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Int']>;
  transfers_fulfilled?: Maybe<Scalars['Int']>;
  transfers_in_transfer?: Maybe<Scalars['Int']>;
  transfers_received?: Maybe<Scalars['Int']>;
  unsynced_adjustments?: Maybe<Scalars['Int']>;
  value_in_transfer?: Maybe<Scalars['numeric']>;
  value_on_hand?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Max_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  supplier_code?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Levels_Per_Location_Min_Fields = {
  in_stock?: Maybe<Scalars['Int']>;
  in_stock_shopify?: Maybe<Scalars['Int']>;
  last_paid_per_unit?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['Int']>;
  orders_in_transfer?: Maybe<Scalars['Int']>;
  orders_received?: Maybe<Scalars['Int']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  sold_qty?: Maybe<Scalars['Int']>;
  supplier_code?: Maybe<Scalars['String']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Int']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Int']>;
  transfers_fulfilled?: Maybe<Scalars['Int']>;
  transfers_in_transfer?: Maybe<Scalars['Int']>;
  transfers_received?: Maybe<Scalars['Int']>;
  unsynced_adjustments?: Maybe<Scalars['Int']>;
  value_in_transfer?: Maybe<Scalars['numeric']>;
  value_on_hand?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Min_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  supplier_code?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "stock_levels_per_location". */
export type Stock_Levels_Per_Location_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  supplier_code?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

export type Stock_Levels_Per_Location_Period_Args = {
  from_ts?: Maybe<Scalars['timestamptz']>;
  input_supplier_id?: Maybe<Scalars['Int']>;
  product_ids?: Maybe<Scalars['_int4']>;
  to_ts?: Maybe<Scalars['timestamptz']>;
};

/** select columns of table "stock_levels_per_location" */
export enum Stock_Levels_Per_Location_Select_Column {
  /** column name */
  InStock = 'in_stock',
  /** column name */
  InStockShopify = 'in_stock_shopify',
  /** column name */
  LastPaidPerUnit = 'last_paid_per_unit',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrdersInTransfer = 'orders_in_transfer',
  /** column name */
  OrdersReceived = 'orders_received',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  SoldQty = 'sold_qty',
  /** column name */
  SupplierCode = 'supplier_code',
  /** column name */
  TransfersAwaitingFulfillmentToReceive = 'transfers_awaiting_fulfillment_to_receive',
  /** column name */
  TransfersAwaitingToFulfill = 'transfers_awaiting_to_fulfill',
  /** column name */
  TransfersFulfilled = 'transfers_fulfilled',
  /** column name */
  TransfersInTransfer = 'transfers_in_transfer',
  /** column name */
  TransfersReceived = 'transfers_received',
  /** column name */
  UnsyncedAdjustments = 'unsynced_adjustments',
  /** column name */
  ValueInTransfer = 'value_in_transfer',
  /** column name */
  ValueOnHand = 'value_on_hand'
}

/** aggregate stddev on columns */
export type Stock_Levels_Per_Location_Stddev_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Stddev_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Levels_Per_Location_Stddev_Pop_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Stddev_Pop_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Levels_Per_Location_Stddev_Samp_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Stddev_Samp_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Levels_Per_Location_Sum_Fields = {
  in_stock?: Maybe<Scalars['Int']>;
  in_stock_shopify?: Maybe<Scalars['Int']>;
  last_paid_per_unit?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['Int']>;
  orders_in_transfer?: Maybe<Scalars['Int']>;
  orders_received?: Maybe<Scalars['Int']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  sold_qty?: Maybe<Scalars['Int']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Int']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Int']>;
  transfers_fulfilled?: Maybe<Scalars['Int']>;
  transfers_in_transfer?: Maybe<Scalars['Int']>;
  transfers_received?: Maybe<Scalars['Int']>;
  unsynced_adjustments?: Maybe<Scalars['Int']>;
  value_in_transfer?: Maybe<Scalars['numeric']>;
  value_on_hand?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Sum_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Stock_Levels_Per_Location_Var_Pop_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Var_Pop_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Levels_Per_Location_Var_Samp_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Var_Samp_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Levels_Per_Location_Variance_Fields = {
  in_stock?: Maybe<Scalars['Float']>;
  in_stock_shopify?: Maybe<Scalars['Float']>;
  last_paid_per_unit?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  orders_in_transfer?: Maybe<Scalars['Float']>;
  orders_received?: Maybe<Scalars['Float']>;
  product_variant_id?: Maybe<Scalars['Float']>;
  shop_id?: Maybe<Scalars['Float']>;
  sold_qty?: Maybe<Scalars['Float']>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Scalars['Float']>;
  transfers_awaiting_to_fulfill?: Maybe<Scalars['Float']>;
  transfers_fulfilled?: Maybe<Scalars['Float']>;
  transfers_in_transfer?: Maybe<Scalars['Float']>;
  transfers_received?: Maybe<Scalars['Float']>;
  unsynced_adjustments?: Maybe<Scalars['Float']>;
  value_in_transfer?: Maybe<Scalars['Float']>;
  value_on_hand?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_levels_per_location" */
export type Stock_Levels_Per_Location_Variance_Order_By = {
  in_stock?: Maybe<Order_By>;
  in_stock_shopify?: Maybe<Order_By>;
  last_paid_per_unit?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  orders_in_transfer?: Maybe<Order_By>;
  orders_received?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  sold_qty?: Maybe<Order_By>;
  transfers_awaiting_fulfillment_to_receive?: Maybe<Order_By>;
  transfers_awaiting_to_fulfill?: Maybe<Order_By>;
  transfers_fulfilled?: Maybe<Order_By>;
  transfers_in_transfer?: Maybe<Order_By>;
  transfers_received?: Maybe<Order_By>;
  unsynced_adjustments?: Maybe<Order_By>;
  value_in_transfer?: Maybe<Order_By>;
  value_on_hand?: Maybe<Order_By>;
};

export type Stock_Levels_Per_Period_Args = {
  from_ts?: Maybe<Scalars['timestamptz']>;
  input_supplier_id?: Maybe<Scalars['Int']>;
  product_ids?: Maybe<Scalars['_int4']>;
  to_ts?: Maybe<Scalars['timestamptz']>;
};

/** select columns of table "stock_levels" */
export enum Stock_Levels_Select_Column {
  /** column name */
  AverageCost = 'average_cost',
  /** column name */
  InStock = 'in_stock',
  /** column name */
  InStockShopify = 'in_stock_shopify',
  /** column name */
  LastPaidPerUnit = 'last_paid_per_unit',
  /** column name */
  OrdersInTransfer = 'orders_in_transfer',
  /** column name */
  OrdersReceived = 'orders_received',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  SoldQty = 'sold_qty',
  /** column name */
  SupplierCode = 'supplier_code',
  /** column name */
  TransfersAwaitingFulfillmentToReceive = 'transfers_awaiting_fulfillment_to_receive',
  /** column name */
  TransfersAwaitingToFulfill = 'transfers_awaiting_to_fulfill',
  /** column name */
  TransfersFulfilled = 'transfers_fulfilled',
  /** column name */
  TransfersInTransfer = 'transfers_in_transfer',
  /** column name */
  TransfersReceived = 'transfers_received',
  /** column name */
  UnsyncedAdjustments = 'unsynced_adjustments',
  /** column name */
  ValueInTransfer = 'value_in_transfer',
  /** column name */
  ValueOnHand = 'value_on_hand'
}

/** columns and relationships of "stocktake_locked" */
export type Stocktake_Locked = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['Int'];
  qty_counted?: Maybe<Scalars['Int']>;
  qty_expected: Scalars['Int'];
  /** An object relationship */
  stocktake: Stocktakes;
  stocktake_id: Scalars['Int'];
  sys_period: Scalars['tstzrange'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "stocktake_locked". All fields are combined with a logical 'AND'. */
export type Stocktake_Locked_Bool_Exp = {
  _and?: Maybe<Array<Stocktake_Locked_Bool_Exp>>;
  _not?: Maybe<Stocktake_Locked_Bool_Exp>;
  _or?: Maybe<Array<Stocktake_Locked_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_counted?: Maybe<Int_Comparison_Exp>;
  qty_expected?: Maybe<Int_Comparison_Exp>;
  stocktake?: Maybe<Stocktakes_Bool_Exp>;
  stocktake_id?: Maybe<Int_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "stocktake_locked" */
export type Stocktake_Locked_Inc_Input = {
  qty_counted?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "stocktake_locked" */
export type Stocktake_Locked_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocktake_Locked>;
};

/** Ordering options when selecting data from "stocktake_locked". */
export type Stocktake_Locked_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  stocktake?: Maybe<Stocktakes_Order_By>;
  stocktake_id?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: stocktake_locked */
export type Stocktake_Locked_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stocktake_locked" */
export enum Stocktake_Locked_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyCounted = 'qty_counted',
  /** column name */
  QtyExpected = 'qty_expected',
  /** column name */
  StocktakeId = 'stocktake_id',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stocktake_locked" */
export type Stocktake_Locked_Set_Input = {
  qty_counted?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "stocktake_products" */
export type Stocktake_Products = {
  cost?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_counted?: Maybe<Scalars['Int']>;
  qty_expected?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  stocktake?: Maybe<Stocktakes>;
  stocktake_id?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "stocktake_products" */
export type Stocktake_Products_Aggregate_Order_By = {
  avg?: Maybe<Stocktake_Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stocktake_Products_Max_Order_By>;
  min?: Maybe<Stocktake_Products_Min_Order_By>;
  stddev?: Maybe<Stocktake_Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Stocktake_Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stocktake_Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Stocktake_Products_Sum_Order_By>;
  var_pop?: Maybe<Stocktake_Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Stocktake_Products_Var_Samp_Order_By>;
  variance?: Maybe<Stocktake_Products_Variance_Order_By>;
};

/** order by avg() on columns of table "stocktake_products" */
export type Stocktake_Products_Avg_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stocktake_products". All fields are combined with a logical 'AND'. */
export type Stocktake_Products_Bool_Exp = {
  _and?: Maybe<Array<Stocktake_Products_Bool_Exp>>;
  _not?: Maybe<Stocktake_Products_Bool_Exp>;
  _or?: Maybe<Array<Stocktake_Products_Bool_Exp>>;
  cost?: Maybe<Numeric_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_counted?: Maybe<Int_Comparison_Exp>;
  qty_expected?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  stocktake?: Maybe<Stocktakes_Bool_Exp>;
  stocktake_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "stocktake_products" */
export type Stocktake_Products_Max_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "stocktake_products" */
export type Stocktake_Products_Min_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "stocktake_products". */
export type Stocktake_Products_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake?: Maybe<Stocktakes_Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** select columns of table "stocktake_products" */
export enum Stocktake_Products_Select_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyCounted = 'qty_counted',
  /** column name */
  QtyExpected = 'qty_expected',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  StocktakeId = 'stocktake_id'
}

/** order by stddev() on columns of table "stocktake_products" */
export type Stocktake_Products_Stddev_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "stocktake_products" */
export type Stocktake_Products_Stddev_Pop_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "stocktake_products" */
export type Stocktake_Products_Stddev_Samp_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "stocktake_products" */
export type Stocktake_Products_Sum_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "stocktake_products" */
export type Stocktake_Products_Var_Pop_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "stocktake_products" */
export type Stocktake_Products_Var_Samp_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "stocktake_products" */
export type Stocktake_Products_Variance_Order_By = {
  cost?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_counted?: Maybe<Order_By>;
  qty_expected?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  stocktake_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "stocktake_status". All fields are combined with logical 'AND'. */
export type Stocktake_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['stocktake_status']>;
  _gt?: Maybe<Scalars['stocktake_status']>;
  _gte?: Maybe<Scalars['stocktake_status']>;
  _in?: Maybe<Array<Scalars['stocktake_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['stocktake_status']>;
  _lte?: Maybe<Scalars['stocktake_status']>;
  _neq?: Maybe<Scalars['stocktake_status']>;
  _nin?: Maybe<Array<Scalars['stocktake_status']>>;
};

/** columns and relationships of "stocktake_view" */
export type Stocktake_View = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  counted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['stocktake_status']>;
  stocktake_number?: Maybe<Scalars['Int']>;
  /** An array relationship */
  stocktake_products: Array<Stocktake_Products>;
  sys_period?: Maybe<Scalars['tstzrange']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "stocktake_view" */
export type Stocktake_ViewStocktake_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocktake_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Products_Order_By>>;
  where?: Maybe<Stocktake_Products_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "stocktake_view". All fields are combined with a logical 'AND'. */
export type Stocktake_View_Bool_Exp = {
  _and?: Maybe<Array<Stocktake_View_Bool_Exp>>;
  _not?: Maybe<Stocktake_View_Bool_Exp>;
  _or?: Maybe<Array<Stocktake_View_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  counted_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Stocktake_Status_Comparison_Exp>;
  stocktake_number?: Maybe<Int_Comparison_Exp>;
  stocktake_products?: Maybe<Stocktake_Products_Bool_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vendor?: Maybe<Vendors_Bool_Exp>;
  vendor_id?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "stocktake_view". */
export type Stocktake_View_Order_By = {
  archived_at?: Maybe<Order_By>;
  counted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stocktake_number?: Maybe<Order_By>;
  stocktake_products_aggregate?: Maybe<Stocktake_Products_Aggregate_Order_By>;
  sys_period?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vendor?: Maybe<Vendors_Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** select columns of table "stocktake_view" */
export enum Stocktake_View_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CountedAt = 'counted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  StocktakeNumber = 'stocktake_number',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** columns and relationships of "stocktakes" */
export type Stocktakes = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  counted_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  status: Scalars['stocktake_status'];
  stocktake_number: Scalars['Int'];
  /** An array relationship */
  stocktake_products: Array<Stocktake_Products>;
  sys_period: Scalars['tstzrange'];
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "stocktakes" */
export type StocktakesStocktake_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocktake_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Products_Order_By>>;
  where?: Maybe<Stocktake_Products_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "stocktakes". All fields are combined with a logical 'AND'. */
export type Stocktakes_Bool_Exp = {
  _and?: Maybe<Array<Stocktakes_Bool_Exp>>;
  _not?: Maybe<Stocktakes_Bool_Exp>;
  _or?: Maybe<Array<Stocktakes_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  counted_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Stocktake_Status_Comparison_Exp>;
  stocktake_number?: Maybe<Int_Comparison_Exp>;
  stocktake_products?: Maybe<Stocktake_Products_Bool_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  vendor?: Maybe<Vendors_Bool_Exp>;
  vendor_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "stocktakes" */
export enum Stocktakes_Constraint {
  /** unique or primary key constraint */
  StocktakeNumberShopIdUnique = 'stocktake_number_shop_id_unique',
  /** unique or primary key constraint */
  StocktakesPkey = 'stocktakes_pkey',
  /** unique or primary key constraint */
  StocktakesShopIdStocktakeNumber = 'stocktakes_shop_id_stocktake_number'
}

/** input type for incrementing numeric columns in table "stocktakes" */
export type Stocktakes_Inc_Input = {
  location_id?: Maybe<Scalars['Int']>;
  vendor_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stocktakes" */
export type Stocktakes_Insert_Input = {
  location_id?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  vendor_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "stocktakes" */
export type Stocktakes_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocktakes>;
};

/** on_conflict condition type for table "stocktakes" */
export type Stocktakes_On_Conflict = {
  constraint: Stocktakes_Constraint;
  update_columns?: Array<Stocktakes_Update_Column>;
  where?: Maybe<Stocktakes_Bool_Exp>;
};

/** Ordering options when selecting data from "stocktakes". */
export type Stocktakes_Order_By = {
  archived_at?: Maybe<Order_By>;
  counted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stocktake_number?: Maybe<Order_By>;
  stocktake_products_aggregate?: Maybe<Stocktake_Products_Aggregate_Order_By>;
  sys_period?: Maybe<Order_By>;
  vendor?: Maybe<Vendors_Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stocktakes */
export type Stocktakes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stocktakes" */
export enum Stocktakes_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CountedAt = 'counted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  StocktakeNumber = 'stocktake_number',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "stocktakes" */
export type Stocktakes_Set_Input = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  counted_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['stocktake_status']>;
  vendor_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "stocktakes" */
export enum Stocktakes_Update_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CountedAt = 'counted_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Status = 'status',
  /** column name */
  VendorId = 'vendor_id'
}

export type Subscription_Root = {
  /** An array relationship */
  bin_locations: Array<Bin_Locations>;
  /** fetch data from the table: "bin_locations" using primary key columns */
  bin_locations_by_pk?: Maybe<Bin_Locations>;
  /** fetch data from the table: "fulfillments_view" */
  fulfillments_view: Array<Fulfillments_View>;
  /** fetch aggregated fields from the table: "fulfillments_view" */
  fulfillments_view_aggregate: Fulfillments_View_Aggregate;
  /** fetch data from the table: "integrations_status" */
  integrations_status: Array<Integrations_Status>;
  /** fetch data from the table: "inventory_history_view" */
  inventory_history_view: Array<Inventory_History_View>;
  /** An array relationship */
  invoice_expenses: Array<Invoice_Expenses>;
  /** An aggregate relationship */
  invoice_expenses_aggregate: Invoice_Expenses_Aggregate;
  /** fetch data from the table: "invoice_expenses" using primary key columns */
  invoice_expenses_by_pk?: Maybe<Invoice_Expenses>;
  /** fetch data from the table: "invoices" */
  invoices: Array<Invoices>;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** fetch data from the table: "invoices_view" */
  invoices_view: Array<Invoices_View>;
  /** An array relationship */
  locations: Array<Locations>;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  order_invoices: Array<Order_Invoices>;
  /** fetch data from the table: "order_invoices" using primary key columns */
  order_invoices_by_pk?: Maybe<Order_Invoices>;
  /** An array relationship */
  order_products: Array<Order_Products>;
  /** fetch data from the table: "order_products" using primary key columns */
  order_products_by_pk?: Maybe<Order_Products>;
  /** fetch data from the table: "order_products_invoice" */
  order_products_invoice: Array<Order_Products_Invoice>;
  /** fetch data from the table: "order_products_invoice" using primary key columns */
  order_products_invoice_by_pk?: Maybe<Order_Products_Invoice>;
  /** fetch data from the table: "order_products_view" */
  order_products_view: Array<Order_Products_View>;
  /** fetch aggregated fields from the table: "order_products_view" */
  order_products_view_aggregate: Order_Products_View_Aggregate;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "orders_view" */
  orders_view: Array<Orders_View>;
  /** fetch aggregated fields from the table: "orders_view" */
  orders_view_aggregate: Orders_View_Aggregate;
  /** An array relationship */
  product_variants: Array<Product_Variants>;
  /** fetch data from the table: "product_variants" using primary key columns */
  product_variants_by_pk?: Maybe<Product_Variants>;
  /** fetch data from the table: "product_variants_view" */
  product_variants_view: Array<Product_Variants_View>;
  /** An array relationship */
  products: Array<Products>;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table: "shops" */
  shops: Array<Shops>;
  /** fetch data from the table: "shops" using primary key columns */
  shops_by_pk?: Maybe<Shops>;
  /** fetch data from the table: "stock_levels" */
  stock_levels: Array<Stock_Levels>;
  /** fetch data from the table: "stock_levels_per_location" */
  stock_levels_per_location: Array<Stock_Levels_Per_Location>;
  /** fetch aggregated fields from the table: "stock_levels_per_location" */
  stock_levels_per_location_aggregate: Stock_Levels_Per_Location_Aggregate;
  /** execute function "stock_levels_per_location_period" which returns "stock_levels_per_location" */
  stock_levels_per_location_period: Array<Stock_Levels_Per_Location>;
  /** execute function "stock_levels_per_location_period" and query aggregates on result of table type "stock_levels_per_location" */
  stock_levels_per_location_period_aggregate: Stock_Levels_Per_Location_Aggregate;
  /** execute function "stock_levels_per_period" which returns "stock_levels" */
  stock_levels_per_period: Array<Stock_Levels>;
  /** fetch data from the table: "stocktake_locked" */
  stocktake_locked: Array<Stocktake_Locked>;
  /** fetch data from the table: "stocktake_locked" using primary key columns */
  stocktake_locked_by_pk?: Maybe<Stocktake_Locked>;
  /** An array relationship */
  stocktake_products: Array<Stocktake_Products>;
  /** fetch data from the table: "stocktake_view" */
  stocktake_view: Array<Stocktake_View>;
  /** fetch data from the table: "stocktakes" */
  stocktakes: Array<Stocktakes>;
  /** fetch data from the table: "stocktakes" using primary key columns */
  stocktakes_by_pk?: Maybe<Stocktakes>;
  /** fetch data from the table: "suppliers" */
  suppliers: Array<Suppliers>;
  /** fetch data from the table: "suppliers" using primary key columns */
  suppliers_by_pk?: Maybe<Suppliers>;
  /** fetch data from the table: "suppliers_product_variants" */
  suppliers_product_variants: Array<Suppliers_Product_Variants>;
  /** fetch data from the table: "suppliers_product_variants_all" */
  suppliers_product_variants_all: Array<Suppliers_Product_Variants_All>;
  /** An array relationship */
  suppliers_vendors: Array<Suppliers_Vendors>;
  /** fetch data from the table: "suppliers_vendors" using primary key columns */
  suppliers_vendors_by_pk?: Maybe<Suppliers_Vendors>;
  /** fetch data from the table: "suppliers_vendors_products" */
  suppliers_vendors_products: Array<Suppliers_Vendors_Products>;
  /** fetch data from the table: "transfer_products" */
  transfer_products: Array<Transfer_Products>;
  /** fetch data from the table: "transfer_products" using primary key columns */
  transfer_products_by_pk?: Maybe<Transfer_Products>;
  /** fetch data from the table: "transfer_products_view" */
  transfer_products_view: Array<Transfer_Products_View>;
  /** fetch data from the table: "transfers" */
  transfers: Array<Transfers>;
  /** fetch data from the table: "transfers" using primary key columns */
  transfers_by_pk?: Maybe<Transfers>;
  /** fetch data from the table: "transfers_view" */
  transfers_view: Array<Transfers_View>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vendors" */
  vendors: Array<Vendors>;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table: "xero_contacts" */
  xero_contacts: Array<Xero_Contacts>;
  /** fetch data from the table: "xero_contacts" using primary key columns */
  xero_contacts_by_pk?: Maybe<Xero_Contacts>;
};


export type Subscription_RootBin_LocationsArgs = {
  distinct_on?: Maybe<Array<Bin_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bin_Locations_Order_By>>;
  where?: Maybe<Bin_Locations_Bool_Exp>;
};


export type Subscription_RootBin_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFulfillments_ViewArgs = {
  distinct_on?: Maybe<Array<Fulfillments_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fulfillments_View_Order_By>>;
  where?: Maybe<Fulfillments_View_Bool_Exp>;
};


export type Subscription_RootFulfillments_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Fulfillments_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fulfillments_View_Order_By>>;
  where?: Maybe<Fulfillments_View_Bool_Exp>;
};


export type Subscription_RootIntegrations_StatusArgs = {
  distinct_on?: Maybe<Array<Integrations_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integrations_Status_Order_By>>;
  where?: Maybe<Integrations_Status_Bool_Exp>;
};


export type Subscription_RootInventory_History_ViewArgs = {
  distinct_on?: Maybe<Array<Inventory_History_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_History_View_Order_By>>;
  where?: Maybe<Inventory_History_View_Bool_Exp>;
};


export type Subscription_RootInvoice_ExpensesArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


export type Subscription_RootInvoice_Expenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Expenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Expenses_Order_By>>;
  where?: Maybe<Invoice_Expenses_Bool_Exp>;
};


export type Subscription_RootInvoice_Expenses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootInvoices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoices_ViewArgs = {
  distinct_on?: Maybe<Array<Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_View_Order_By>>;
  where?: Maybe<Invoices_View_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_InvoicesArgs = {
  distinct_on?: Maybe<Array<Order_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Invoices_Order_By>>;
  where?: Maybe<Order_Invoices_Bool_Exp>;
};


export type Subscription_RootOrder_Invoices_By_PkArgs = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
};


export type Subscription_RootOrder_ProductsArgs = {
  distinct_on?: Maybe<Array<Order_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Order_By>>;
  where?: Maybe<Order_Products_Bool_Exp>;
};


export type Subscription_RootOrder_Products_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Products_InvoiceArgs = {
  distinct_on?: Maybe<Array<Order_Products_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_Invoice_Order_By>>;
  where?: Maybe<Order_Products_Invoice_Bool_Exp>;
};


export type Subscription_RootOrder_Products_Invoice_By_PkArgs = {
  invoice_id: Scalars['Int'];
  order_id: Scalars['Int'];
  product_variant_id: Scalars['Int'];
};


export type Subscription_RootOrder_Products_ViewArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};


export type Subscription_RootOrder_Products_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Products_View_Order_By>>;
  where?: Maybe<Order_Products_View_Bool_Exp>;
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrders_ViewArgs = {
  distinct_on?: Maybe<Array<Orders_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_View_Order_By>>;
  where?: Maybe<Orders_View_Bool_Exp>;
};


export type Subscription_RootOrders_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_View_Order_By>>;
  where?: Maybe<Orders_View_Bool_Exp>;
};


export type Subscription_RootProduct_VariantsArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Subscription_RootProduct_Variants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProduct_Variants_ViewArgs = {
  distinct_on?: Maybe<Array<Product_Variants_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_View_Order_By>>;
  where?: Maybe<Product_Variants_View_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootReportsArgs = {
  distinct_on?: Maybe<Array<Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reports_Order_By>>;
  where?: Maybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  date: Scalars['date'];
  location_id: Scalars['Int'];
};


export type Subscription_RootShopsArgs = {
  distinct_on?: Maybe<Array<Shops_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shops_Order_By>>;
  where?: Maybe<Shops_Bool_Exp>;
};


export type Subscription_RootShops_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStock_LevelsArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Order_By>>;
  where?: Maybe<Stock_Levels_Bool_Exp>;
};


export type Subscription_RootStock_Levels_Per_LocationArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Subscription_RootStock_Levels_Per_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Subscription_RootStock_Levels_Per_Location_PeriodArgs = {
  args: Stock_Levels_Per_Location_Period_Args;
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Subscription_RootStock_Levels_Per_Location_Period_AggregateArgs = {
  args: Stock_Levels_Per_Location_Period_Args;
  distinct_on?: Maybe<Array<Stock_Levels_Per_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Per_Location_Order_By>>;
  where?: Maybe<Stock_Levels_Per_Location_Bool_Exp>;
};


export type Subscription_RootStock_Levels_Per_PeriodArgs = {
  args: Stock_Levels_Per_Period_Args;
  distinct_on?: Maybe<Array<Stock_Levels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Levels_Order_By>>;
  where?: Maybe<Stock_Levels_Bool_Exp>;
};


export type Subscription_RootStocktake_LockedArgs = {
  distinct_on?: Maybe<Array<Stocktake_Locked_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Locked_Order_By>>;
  where?: Maybe<Stocktake_Locked_Bool_Exp>;
};


export type Subscription_RootStocktake_Locked_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStocktake_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocktake_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_Products_Order_By>>;
  where?: Maybe<Stocktake_Products_Bool_Exp>;
};


export type Subscription_RootStocktake_ViewArgs = {
  distinct_on?: Maybe<Array<Stocktake_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktake_View_Order_By>>;
  where?: Maybe<Stocktake_View_Bool_Exp>;
};


export type Subscription_RootStocktakesArgs = {
  distinct_on?: Maybe<Array<Stocktakes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocktakes_Order_By>>;
  where?: Maybe<Stocktakes_Bool_Exp>;
};


export type Subscription_RootStocktakes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSuppliersArgs = {
  distinct_on?: Maybe<Array<Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Order_By>>;
  where?: Maybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSuppliers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSuppliers_Product_VariantsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Product_Variants_Order_By>>;
  where?: Maybe<Suppliers_Product_Variants_Bool_Exp>;
};


export type Subscription_RootSuppliers_Product_Variants_AllArgs = {
  distinct_on?: Maybe<Array<Suppliers_Product_Variants_All_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Product_Variants_All_Order_By>>;
  where?: Maybe<Suppliers_Product_Variants_All_Bool_Exp>;
};


export type Subscription_RootSuppliers_VendorsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Vendors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Vendors_Order_By>>;
  where?: Maybe<Suppliers_Vendors_Bool_Exp>;
};


export type Subscription_RootSuppliers_Vendors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSuppliers_Vendors_ProductsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Vendors_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Vendors_Products_Order_By>>;
  where?: Maybe<Suppliers_Vendors_Products_Bool_Exp>;
};


export type Subscription_RootTransfer_ProductsArgs = {
  distinct_on?: Maybe<Array<Transfer_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Products_Order_By>>;
  where?: Maybe<Transfer_Products_Bool_Exp>;
};


export type Subscription_RootTransfer_Products_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransfer_Products_ViewArgs = {
  distinct_on?: Maybe<Array<Transfer_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Products_View_Order_By>>;
  where?: Maybe<Transfer_Products_View_Bool_Exp>;
};


export type Subscription_RootTransfersArgs = {
  distinct_on?: Maybe<Array<Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfers_Order_By>>;
  where?: Maybe<Transfers_Bool_Exp>;
};


export type Subscription_RootTransfers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransfers_ViewArgs = {
  distinct_on?: Maybe<Array<Transfers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfers_View_Order_By>>;
  where?: Maybe<Transfers_View_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVendorsArgs = {
  distinct_on?: Maybe<Array<Vendors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vendors_Order_By>>;
  where?: Maybe<Vendors_Bool_Exp>;
};


export type Subscription_RootVendors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootXero_ContactsArgs = {
  distinct_on?: Maybe<Array<Xero_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xero_Contacts_Order_By>>;
  where?: Maybe<Xero_Contacts_Bool_Exp>;
};


export type Subscription_RootXero_Contacts_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "suppliers" */
export type Suppliers = {
  contact_first_name?: Maybe<Scalars['String']>;
  contact_last_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  /** An array relationship */
  suppliers_vendors: Array<Suppliers_Vendors>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  xero_contact?: Maybe<Xero_Contacts>;
  xero_contact_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "suppliers" */
export type SuppliersSuppliers_VendorsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Vendors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Vendors_Order_By>>;
  where?: Maybe<Suppliers_Vendors_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "suppliers". All fields are combined with a logical 'AND'. */
export type Suppliers_Bool_Exp = {
  _and?: Maybe<Array<Suppliers_Bool_Exp>>;
  _not?: Maybe<Suppliers_Bool_Exp>;
  _or?: Maybe<Array<Suppliers_Bool_Exp>>;
  contact_first_name?: Maybe<String_Comparison_Exp>;
  contact_last_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  suppliers_vendors?: Maybe<Suppliers_Vendors_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  xero_contact?: Maybe<Xero_Contacts_Bool_Exp>;
  xero_contact_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "suppliers" */
export enum Suppliers_Constraint {
  /** unique or primary key constraint */
  SuppliersPkey = 'suppliers_pkey'
}

/** input type for inserting data into table "suppliers" */
export type Suppliers_Insert_Input = {
  contact_first_name?: Maybe<Scalars['String']>;
  contact_last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shop_id?: Maybe<Scalars['Int']>;
  suppliers_vendors?: Maybe<Suppliers_Vendors_Arr_Rel_Insert_Input>;
  xero_contact_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "suppliers" */
export type Suppliers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Suppliers>;
};

/** input type for inserting object relation for remote table "suppliers" */
export type Suppliers_Obj_Rel_Insert_Input = {
  data: Suppliers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Suppliers_On_Conflict>;
};

/** on_conflict condition type for table "suppliers" */
export type Suppliers_On_Conflict = {
  constraint: Suppliers_Constraint;
  update_columns?: Array<Suppliers_Update_Column>;
  where?: Maybe<Suppliers_Bool_Exp>;
};

/** Ordering options when selecting data from "suppliers". */
export type Suppliers_Order_By = {
  contact_first_name?: Maybe<Order_By>;
  contact_last_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  suppliers_vendors_aggregate?: Maybe<Suppliers_Vendors_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  xero_contact?: Maybe<Xero_Contacts_Order_By>;
  xero_contact_id?: Maybe<Order_By>;
};

/** primary key columns input for table: suppliers */
export type Suppliers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "suppliers_product_variants" */
export type Suppliers_Product_Variants = {
  code?: Maybe<Scalars['String']>;
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['Int'];
  /** An object relationship */
  supplier: Suppliers;
  supplier_id: Scalars['Int'];
};

/** columns and relationships of "suppliers_product_variants_all" */
export type Suppliers_Product_Variants_All = {
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "suppliers_product_variants_all". All fields are combined with a logical 'AND'. */
export type Suppliers_Product_Variants_All_Bool_Exp = {
  _and?: Maybe<Array<Suppliers_Product_Variants_All_Bool_Exp>>;
  _not?: Maybe<Suppliers_Product_Variants_All_Bool_Exp>;
  _or?: Maybe<Array<Suppliers_Product_Variants_All_Bool_Exp>>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "suppliers_product_variants_all". */
export type Suppliers_Product_Variants_All_Order_By = {
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** select columns of table "suppliers_product_variants_all" */
export enum Suppliers_Product_Variants_All_Select_Column {
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  SupplierId = 'supplier_id'
}

/** Boolean expression to filter rows from the table "suppliers_product_variants". All fields are combined with a logical 'AND'. */
export type Suppliers_Product_Variants_Bool_Exp = {
  _and?: Maybe<Array<Suppliers_Product_Variants_Bool_Exp>>;
  _not?: Maybe<Suppliers_Product_Variants_Bool_Exp>;
  _or?: Maybe<Array<Suppliers_Product_Variants_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "suppliers_product_variants" */
export enum Suppliers_Product_Variants_Constraint {
  /** unique or primary key constraint */
  SuppliersProductVariantsPkey = 'suppliers_product_variants_pkey',
  /** unique or primary key constraint */
  SuppliersProductVariantsProductVariantIdSupplierIdKey = 'suppliers_product_variants_product_variant_id_supplier_id_key'
}

/** input type for inserting data into table "suppliers_product_variants" */
export type Suppliers_Product_Variants_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  product_variant_id?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Suppliers_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "suppliers_product_variants" */
export type Suppliers_Product_Variants_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Suppliers_Product_Variants>;
};

/** on_conflict condition type for table "suppliers_product_variants" */
export type Suppliers_Product_Variants_On_Conflict = {
  constraint: Suppliers_Product_Variants_Constraint;
  update_columns?: Array<Suppliers_Product_Variants_Update_Column>;
  where?: Maybe<Suppliers_Product_Variants_Bool_Exp>;
};

/** Ordering options when selecting data from "suppliers_product_variants". */
export type Suppliers_Product_Variants_Order_By = {
  code?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
};

/** select columns of table "suppliers_product_variants" */
export enum Suppliers_Product_Variants_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  SupplierId = 'supplier_id'
}

/** input type for updating data in table "suppliers_product_variants" */
export type Suppliers_Product_Variants_Set_Input = {
  code?: Maybe<Scalars['String']>;
};

/** update columns of table "suppliers_product_variants" */
export enum Suppliers_Product_Variants_Update_Column {
  /** column name */
  Code = 'code'
}

/** select columns of table "suppliers" */
export enum Suppliers_Select_Column {
  /** column name */
  ContactFirstName = 'contact_first_name',
  /** column name */
  ContactLastName = 'contact_last_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  XeroContactId = 'xero_contact_id'
}

/** input type for updating data in table "suppliers" */
export type Suppliers_Set_Input = {
  contact_first_name?: Maybe<Scalars['String']>;
  contact_last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  xero_contact_id?: Maybe<Scalars['String']>;
};

/** update columns of table "suppliers" */
export enum Suppliers_Update_Column {
  /** column name */
  ContactFirstName = 'contact_first_name',
  /** column name */
  ContactLastName = 'contact_last_name',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  XeroContactId = 'xero_contact_id'
}

/** columns and relationships of "suppliers_vendors" */
export type Suppliers_Vendors = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  supplier: Suppliers;
  supplier_id: Scalars['Int'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['Int'];
};

/** order by aggregate values of table "suppliers_vendors" */
export type Suppliers_Vendors_Aggregate_Order_By = {
  avg?: Maybe<Suppliers_Vendors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Suppliers_Vendors_Max_Order_By>;
  min?: Maybe<Suppliers_Vendors_Min_Order_By>;
  stddev?: Maybe<Suppliers_Vendors_Stddev_Order_By>;
  stddev_pop?: Maybe<Suppliers_Vendors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Suppliers_Vendors_Stddev_Samp_Order_By>;
  sum?: Maybe<Suppliers_Vendors_Sum_Order_By>;
  var_pop?: Maybe<Suppliers_Vendors_Var_Pop_Order_By>;
  var_samp?: Maybe<Suppliers_Vendors_Var_Samp_Order_By>;
  variance?: Maybe<Suppliers_Vendors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "suppliers_vendors" */
export type Suppliers_Vendors_Arr_Rel_Insert_Input = {
  data: Array<Suppliers_Vendors_Insert_Input>;
};

/** order by avg() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Avg_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "suppliers_vendors". All fields are combined with a logical 'AND'. */
export type Suppliers_Vendors_Bool_Exp = {
  _and?: Maybe<Array<Suppliers_Vendors_Bool_Exp>>;
  _not?: Maybe<Suppliers_Vendors_Bool_Exp>;
  _or?: Maybe<Array<Suppliers_Vendors_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  vendor?: Maybe<Vendors_Bool_Exp>;
  vendor_id?: Maybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "suppliers_vendors" */
export type Suppliers_Vendors_Insert_Input = {
  supplier?: Maybe<Suppliers_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['Int']>;
  vendor_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "suppliers_vendors" */
export type Suppliers_Vendors_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Suppliers_Vendors>;
};

/** Ordering options when selecting data from "suppliers_vendors". */
export type Suppliers_Vendors_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor?: Maybe<Vendors_Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** columns and relationships of "suppliers_vendors_products" */
export type Suppliers_Vendors_Products = {
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplier_code?: Maybe<Scalars['String']>;
  supplier_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "suppliers_vendors_products". All fields are combined with a logical 'AND'. */
export type Suppliers_Vendors_Products_Bool_Exp = {
  _and?: Maybe<Array<Suppliers_Vendors_Products_Bool_Exp>>;
  _not?: Maybe<Suppliers_Vendors_Products_Bool_Exp>;
  _or?: Maybe<Array<Suppliers_Vendors_Products_Bool_Exp>>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_code?: Maybe<String_Comparison_Exp>;
  supplier_id?: Maybe<Int_Comparison_Exp>;
  vendor?: Maybe<Vendors_Bool_Exp>;
  vendor_id?: Maybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "suppliers_vendors_products". */
export type Suppliers_Vendors_Products_Order_By = {
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_code?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor?: Maybe<Vendors_Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** select columns of table "suppliers_vendors_products" */
export enum Suppliers_Vendors_Products_Select_Column {
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  SupplierCode = 'supplier_code',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** select columns of table "suppliers_vendors" */
export enum Suppliers_Vendors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** order by stddev() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Sum_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "suppliers_vendors" */
export type Suppliers_Vendors_Variance_Order_By = {
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  vendor_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "transfer_products" */
export type Transfer_Products = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['Int'];
  qty_fulfilled: Scalars['Int'];
  qty_received: Scalars['Int'];
  qty_requested: Scalars['Int'];
  /** An object relationship */
  transfer: Transfers;
  transfer_id: Scalars['Int'];
  /** An object relationship */
  transfer_product_view?: Maybe<Transfer_Products_View>;
};

/** Boolean expression to filter rows from the table "transfer_products". All fields are combined with a logical 'AND'. */
export type Transfer_Products_Bool_Exp = {
  _and?: Maybe<Array<Transfer_Products_Bool_Exp>>;
  _not?: Maybe<Transfer_Products_Bool_Exp>;
  _or?: Maybe<Array<Transfer_Products_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_fulfilled?: Maybe<Int_Comparison_Exp>;
  qty_received?: Maybe<Int_Comparison_Exp>;
  qty_requested?: Maybe<Int_Comparison_Exp>;
  transfer?: Maybe<Transfers_Bool_Exp>;
  transfer_id?: Maybe<Int_Comparison_Exp>;
  transfer_product_view?: Maybe<Transfer_Products_View_Bool_Exp>;
};

/** unique or primary key constraints on table "transfer_products" */
export enum Transfer_Products_Constraint {
  /** unique or primary key constraint */
  TransferEventsProductsPkey = 'transfer_events_products_pkey'
}

/** input type for incrementing numeric columns in table "transfer_products" */
export type Transfer_Products_Inc_Input = {
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_fulfilled?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  qty_requested?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "transfer_products" */
export type Transfer_Products_Insert_Input = {
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_fulfilled?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  qty_requested?: Maybe<Scalars['Int']>;
  transfer?: Maybe<Transfers_Obj_Rel_Insert_Input>;
  transfer_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "transfer_products" */
export type Transfer_Products_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transfer_Products>;
};

/** on_conflict condition type for table "transfer_products" */
export type Transfer_Products_On_Conflict = {
  constraint: Transfer_Products_Constraint;
  update_columns?: Array<Transfer_Products_Update_Column>;
  where?: Maybe<Transfer_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "transfer_products". */
export type Transfer_Products_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  transfer?: Maybe<Transfers_Order_By>;
  transfer_id?: Maybe<Order_By>;
  transfer_product_view?: Maybe<Transfer_Products_View_Order_By>;
};

/** primary key columns input for table: transfer_products */
export type Transfer_Products_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transfer_products" */
export enum Transfer_Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyFulfilled = 'qty_fulfilled',
  /** column name */
  QtyReceived = 'qty_received',
  /** column name */
  QtyRequested = 'qty_requested',
  /** column name */
  TransferId = 'transfer_id'
}

/** input type for updating data in table "transfer_products" */
export type Transfer_Products_Set_Input = {
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_fulfilled?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  qty_requested?: Maybe<Scalars['Int']>;
};

/** update columns of table "transfer_products" */
export enum Transfer_Products_Update_Column {
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyFulfilled = 'qty_fulfilled',
  /** column name */
  QtyReceived = 'qty_received',
  /** column name */
  QtyRequested = 'qty_requested'
}

/** columns and relationships of "transfer_products_view" */
export type Transfer_Products_View = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  product_variant?: Maybe<Product_Variants>;
  product_variant_id?: Maybe<Scalars['Int']>;
  qty_fulfilled?: Maybe<Scalars['Int']>;
  qty_received?: Maybe<Scalars['Int']>;
  qty_requested?: Maybe<Scalars['Int']>;
  stock_at_destination?: Maybe<Scalars['Int']>;
  stock_at_origin?: Maybe<Scalars['Int']>;
  sys_period?: Maybe<Scalars['tstzrange']>;
  /** An object relationship */
  transfer?: Maybe<Transfers>;
  transfer_id?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "transfer_products_view" */
export type Transfer_Products_View_Aggregate_Order_By = {
  avg?: Maybe<Transfer_Products_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Transfer_Products_View_Max_Order_By>;
  min?: Maybe<Transfer_Products_View_Min_Order_By>;
  stddev?: Maybe<Transfer_Products_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Transfer_Products_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Transfer_Products_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Transfer_Products_View_Sum_Order_By>;
  var_pop?: Maybe<Transfer_Products_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Transfer_Products_View_Var_Samp_Order_By>;
  variance?: Maybe<Transfer_Products_View_Variance_Order_By>;
};

/** order by avg() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Avg_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transfer_products_view". All fields are combined with a logical 'AND'. */
export type Transfer_Products_View_Bool_Exp = {
  _and?: Maybe<Array<Transfer_Products_View_Bool_Exp>>;
  _not?: Maybe<Transfer_Products_View_Bool_Exp>;
  _or?: Maybe<Array<Transfer_Products_View_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Int_Comparison_Exp>;
  qty_fulfilled?: Maybe<Int_Comparison_Exp>;
  qty_received?: Maybe<Int_Comparison_Exp>;
  qty_requested?: Maybe<Int_Comparison_Exp>;
  stock_at_destination?: Maybe<Int_Comparison_Exp>;
  stock_at_origin?: Maybe<Int_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  transfer?: Maybe<Transfers_Bool_Exp>;
  transfer_id?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "transfer_products_view". */
export type Transfer_Products_View_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  transfer?: Maybe<Transfers_Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** select columns of table "transfer_products_view" */
export enum Transfer_Products_View_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  QtyFulfilled = 'qty_fulfilled',
  /** column name */
  QtyReceived = 'qty_received',
  /** column name */
  QtyRequested = 'qty_requested',
  /** column name */
  StockAtDestination = 'stock_at_destination',
  /** column name */
  StockAtOrigin = 'stock_at_origin',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  TransferId = 'transfer_id'
}

/** order by stddev() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Sum_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "transfer_products_view" */
export type Transfer_Products_View_Variance_Order_By = {
  id?: Maybe<Order_By>;
  product_variant_id?: Maybe<Order_By>;
  qty_fulfilled?: Maybe<Order_By>;
  qty_received?: Maybe<Order_By>;
  qty_requested?: Maybe<Order_By>;
  stock_at_destination?: Maybe<Order_By>;
  stock_at_origin?: Maybe<Order_By>;
  transfer_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "transfer_status". All fields are combined with logical 'AND'. */
export type Transfer_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['transfer_status']>;
  _gt?: Maybe<Scalars['transfer_status']>;
  _gte?: Maybe<Scalars['transfer_status']>;
  _in?: Maybe<Array<Scalars['transfer_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['transfer_status']>;
  _lte?: Maybe<Scalars['transfer_status']>;
  _neq?: Maybe<Scalars['transfer_status']>;
  _nin?: Maybe<Array<Scalars['transfer_status']>>;
};

/** columns and relationships of "transfers" */
export type Transfers = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  fromLocation: Locations;
  from_location_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  status: Scalars['transfer_status'];
  /** An object relationship */
  toLocation: Locations;
  to_location_id: Scalars['Int'];
  transfer_number: Scalars['Int'];
  /** An object relationship */
  transfer_view?: Maybe<Transfers_View>;
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "transfers". All fields are combined with a logical 'AND'. */
export type Transfers_Bool_Exp = {
  _and?: Maybe<Array<Transfers_Bool_Exp>>;
  _not?: Maybe<Transfers_Bool_Exp>;
  _or?: Maybe<Array<Transfers_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fromLocation?: Maybe<Locations_Bool_Exp>;
  from_location_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Transfer_Status_Comparison_Exp>;
  toLocation?: Maybe<Locations_Bool_Exp>;
  to_location_id?: Maybe<Int_Comparison_Exp>;
  transfer_number?: Maybe<Int_Comparison_Exp>;
  transfer_view?: Maybe<Transfers_View_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transfers" */
export enum Transfers_Constraint {
  /** unique or primary key constraint */
  TransferNumberShopIdUnique = 'transfer_number_shop_id_unique',
  /** unique or primary key constraint */
  TransferShopIdTransferNumberKey = 'transfer_shop_id_transfer_number_key',
  /** unique or primary key constraint */
  TransfersPkey = 'transfers_pkey'
}

/** input type for incrementing numeric columns in table "transfers" */
export type Transfers_Inc_Input = {
  from_location_id?: Maybe<Scalars['Int']>;
  to_location_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "transfers" */
export type Transfers_Insert_Input = {
  from_location_id?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['transfer_status']>;
  to_location_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "transfers" */
export type Transfers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transfers>;
};

/** input type for inserting object relation for remote table "transfers" */
export type Transfers_Obj_Rel_Insert_Input = {
  data: Transfers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Transfers_On_Conflict>;
};

/** on_conflict condition type for table "transfers" */
export type Transfers_On_Conflict = {
  constraint: Transfers_Constraint;
  update_columns?: Array<Transfers_Update_Column>;
  where?: Maybe<Transfers_Bool_Exp>;
};

/** Ordering options when selecting data from "transfers". */
export type Transfers_Order_By = {
  archived_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fromLocation?: Maybe<Locations_Order_By>;
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  toLocation?: Maybe<Locations_Order_By>;
  to_location_id?: Maybe<Order_By>;
  transfer_number?: Maybe<Order_By>;
  transfer_view?: Maybe<Transfers_View_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: transfers */
export type Transfers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transfers" */
export enum Transfers_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromLocationId = 'from_location_id',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  ToLocationId = 'to_location_id',
  /** column name */
  TransferNumber = 'transfer_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transfers" */
export type Transfers_Set_Input = {
  from_location_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['transfer_status']>;
  to_location_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "transfers" */
export enum Transfers_Update_Column {
  /** column name */
  FromLocationId = 'from_location_id',
  /** column name */
  Status = 'status',
  /** column name */
  ToLocationId = 'to_location_id'
}

/** columns and relationships of "transfers_view" */
export type Transfers_View = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_location_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shop?: Maybe<Shops>;
  shop_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['transfer_status']>;
  sys_period?: Maybe<Scalars['tstzrange']>;
  to_location_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  transfer_number?: Maybe<Scalars['Int']>;
  /** An array relationship */
  transfer_products: Array<Transfer_Products_View>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "transfers_view" */
export type Transfers_ViewTransfer_ProductsArgs = {
  distinct_on?: Maybe<Array<Transfer_Products_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Products_View_Order_By>>;
  where?: Maybe<Transfer_Products_View_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "transfers_view". All fields are combined with a logical 'AND'. */
export type Transfers_View_Bool_Exp = {
  _and?: Maybe<Array<Transfers_View_Bool_Exp>>;
  _not?: Maybe<Transfers_View_Bool_Exp>;
  _or?: Maybe<Array<Transfers_View_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_location_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Transfer_Status_Comparison_Exp>;
  sys_period?: Maybe<Tstzrange_Comparison_Exp>;
  to_location_id?: Maybe<Int_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  transfer_number?: Maybe<Int_Comparison_Exp>;
  transfer_products?: Maybe<Transfer_Products_View_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "transfers_view". */
export type Transfers_View_Order_By = {
  archived_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  sys_period?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  transfer_number?: Maybe<Order_By>;
  transfer_products_aggregate?: Maybe<Transfer_Products_View_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "transfers_view" */
export enum Transfers_View_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromLocationId = 'from_location_id',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  SysPeriod = 'sys_period',
  /** column name */
  ToLocationId = 'to_location_id',
  /** column name */
  Total = 'total',
  /** column name */
  TransferNumber = 'transfer_number',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "tstzrange". All fields are combined with logical 'AND'. */
export type Tstzrange_Comparison_Exp = {
  _eq?: Maybe<Scalars['tstzrange']>;
  _gt?: Maybe<Scalars['tstzrange']>;
  _gte?: Maybe<Scalars['tstzrange']>;
  _in?: Maybe<Array<Scalars['tstzrange']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['tstzrange']>;
  _lte?: Maybe<Scalars['tstzrange']>;
  _neq?: Maybe<Scalars['tstzrange']>;
  _nin?: Maybe<Array<Scalars['tstzrange']>>;
};

export type Upsert_Supplier_Product_Variants_Sku_Args = {
  input_supplier_id?: Maybe<Scalars['Int']>;
  populate?: Maybe<Scalars['json']>;
};

/** columns and relationships of "users" */
export type Users = {
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shop_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  shop_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  shop_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vendors" */
export type Vendors = {
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  /** An array relationship */
  suppliers_vendors: Array<Suppliers_Vendors>;
};


/** columns and relationships of "vendors" */
export type VendorsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "vendors" */
export type VendorsSuppliers_VendorsArgs = {
  distinct_on?: Maybe<Array<Suppliers_Vendors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Vendors_Order_By>>;
  where?: Maybe<Suppliers_Vendors_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "vendors". All fields are combined with a logical 'AND'. */
export type Vendors_Bool_Exp = {
  _and?: Maybe<Array<Vendors_Bool_Exp>>;
  _not?: Maybe<Vendors_Bool_Exp>;
  _or?: Maybe<Array<Vendors_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  suppliers_vendors?: Maybe<Suppliers_Vendors_Bool_Exp>;
};

/** Ordering options when selecting data from "vendors". */
export type Vendors_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  suppliers_vendors_aggregate?: Maybe<Suppliers_Vendors_Aggregate_Order_By>;
};

/** select columns of table "vendors" */
export enum Vendors_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShopId = 'shop_id'
}

/** columns and relationships of "xero_contacts" */
export type Xero_Contacts = {
  archived_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  tenant_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "xero_contacts". All fields are combined with a logical 'AND'. */
export type Xero_Contacts_Bool_Exp = {
  _and?: Maybe<Array<Xero_Contacts_Bool_Exp>>;
  _not?: Maybe<Xero_Contacts_Bool_Exp>;
  _or?: Maybe<Array<Xero_Contacts_Bool_Exp>>;
  archived_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email_address?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  number?: Maybe<String_Comparison_Exp>;
  shop?: Maybe<Shops_Bool_Exp>;
  shop_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  tenant_id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "xero_contacts". */
export type Xero_Contacts_Order_By = {
  archived_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_address?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  shop?: Maybe<Shops_Order_By>;
  shop_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** select columns of table "xero_contacts" */
export enum Xero_Contacts_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id'
}

export type UpsertBinLocationMutationVariables = Exact<{
  location_id: Scalars['Int'];
  name: Scalars['String'];
  product_variant_id: Scalars['Int'];
}>;


export type UpsertBinLocationMutation = { insert_bin_locations_one?: Maybe<Pick<Bin_Locations, 'location_id' | 'product_variant_id' | 'name'>> };

export type InvoiceExpensesFieldsFragment = Pick<Invoice_Expenses, 'amount' | 'created_at' | 'description' | 'id' | 'updated_at' | 'invoice_id'>;

export type InvoiceFieldsFragment = (
  Pick<Invoices_View, 'created_at' | 'id' | 'invoice_date' | 'invoice_number' | 'payment_due' | 'xero_invoice_id' | 'adjustments' | 'total_received' | 'total_expenses' | 'total' | 'total_with_gst'>
  & { supplier?: Maybe<ShortSupplierFieldsFragment> }
);

export type InvoiceFieldsWithExpensesFragment = (
  { invoice_expenses: Array<InvoiceExpensesFieldsFragment> }
  & InvoiceFieldsFragment
);

export type OrderProductsInvoiceFragment = Pick<Order_Products_Invoice, 'paid_per_unit' | 'product_variant_id' | 'qty_received' | 'updated_at' | 'created_at'>;

export type OrderProductsInvoiceWithVariantsFragment = (
  Pick<Order_Products_Invoice, 'order_id'>
  & { order: Pick<Orders, 'order_number'>, product_variant: ProductVariantFieldsFragment }
  & OrderProductsInvoiceFragment
);

export type GetInvoiceForOrderQueryVariables = Exact<{
  id: Scalars['Int'];
  orderId: Scalars['Int'];
}>;


export type GetInvoiceForOrderQuery = { invoices_view: Array<(
    { order_invoices: Array<{ order_products_invoices: Array<OrderProductsInvoiceWithVariantsFragment> }> }
    & InvoiceFieldsWithExpensesFragment
  )> };

export type GetInvoiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInvoiceQuery = { invoices_view: Array<(
    { order_products_invoices: Array<OrderProductsInvoiceWithVariantsFragment> }
    & InvoiceFieldsWithExpensesFragment
  )> };

export type FindInvoicesQueryVariables = Exact<{
  invoiceNoSearch: Scalars['String'];
  shopId: Scalars['Int'];
  supplierId: Scalars['Int'];
}>;


export type FindInvoicesQuery = { invoices: Array<Pick<Invoices, 'id' | 'invoice_number'>> };

export type InsertInvoiceMutationVariables = Exact<{
  orderId: Scalars['Int'];
  shopId: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['String']>;
  supplierId: Scalars['Int'];
}>;


export type InsertInvoiceMutation = { insert_order_invoices_one?: Maybe<{ invoice: { invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> }, order_products_invoices: Array<OrderProductsInvoiceWithVariantsFragment> }> };

export type DeleteOrderInvoiceMutationVariables = Exact<{
  orderId: Scalars['Int'];
  invoiceId: Scalars['Int'];
}>;


export type DeleteOrderInvoiceMutation = { delete_order_invoices_by_pk?: Maybe<(
    Pick<Order_Invoices, 'invoice_id' | 'order_id'>
    & { invoice: { invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> } }
  )> };

export type InsertOrderInvoiceMutationVariables = Exact<{
  orderId: Scalars['Int'];
  invoiceId: Scalars['Int'];
}>;


export type InsertOrderInvoiceMutation = { insert_order_invoices_one?: Maybe<{ order_products_invoices: Array<OrderProductsInvoiceWithVariantsFragment>, invoice: { invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> } }> };

export type DeleteOrderProductInvoiceMutationVariables = Exact<{
  orderId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  pvId: Scalars['Int'];
}>;


export type DeleteOrderProductInvoiceMutation = { delete_order_products_invoice_by_pk?: Maybe<(
    Pick<Order_Products_Invoice, 'invoice_id' | 'order_id' | 'product_variant_id'>
    & { invoice: { invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> } }
  )> };

export type SendToXeroMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
}>;


export type SendToXeroMutation = { xero: Pick<XeroMutation, 'createInvoice'> };

export type UpsertOrderProductInvoicesMutationVariables = Exact<{
  object: Order_Products_Invoice_Insert_Input;
}>;


export type UpsertOrderProductInvoicesMutation = { insert_order_products_invoice_one?: Maybe<(
    { order_product_view?: Maybe<OrderProductsFieldsFragment>, invoice_view?: Maybe<InvoiceFieldsFragment>, order_view?: Maybe<OrderFieldsExtendedFragment> }
    & OrderProductsInvoiceWithVariantsFragment
  )> };

export type UpsertOrderProductInvoicesPaidPerUnitMutationVariables = Exact<{
  object: Order_Products_Invoice_Insert_Input;
}>;


export type UpsertOrderProductInvoicesPaidPerUnitMutation = { insert_order_products_invoice_one?: Maybe<(
    { order_product_view?: Maybe<OrderProductsFieldsFragment>, invoice_view?: Maybe<InvoiceFieldsFragment>, order_view?: Maybe<OrderFieldsExtendedFragment> }
    & OrderProductsInvoiceWithVariantsFragment
  )> };

export type UpsertOrderProductInvoicesQtyReceivedMutationVariables = Exact<{
  object: Order_Products_Invoice_Insert_Input;
}>;


export type UpsertOrderProductInvoicesQtyReceivedMutation = { insert_order_products_invoice_one?: Maybe<(
    { order_product_view?: Maybe<OrderProductsFieldsFragment>, invoice_view?: Maybe<InvoiceFieldsFragment>, order_view?: Maybe<OrderFieldsExtendedFragment> }
    & OrderProductsInvoiceWithVariantsFragment
  )> };

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
  set: Invoices_Set_Input;
}>;


export type UpdateInvoiceMutation = { update_invoices_by_pk?: Maybe<{ invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> }> };

export type GetInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoicesQuery = { invoices_view: Array<InvoiceFieldsFragment> };

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvoiceMutation = { delete_invoices_by_pk?: Maybe<Pick<Invoices, 'id'>> };

export type DeleteExpenseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteExpenseMutation = { delete_invoice_expenses_by_pk?: Maybe<{ invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> }> };

export type CreateExpensesMutationVariables = Exact<{
  expense: Invoice_Expenses_Insert_Input;
}>;


export type CreateExpensesMutation = { insert_invoice_expenses_one?: Maybe<{ invoice: { invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> } }> };

export type UpdateExpenseMutationVariables = Exact<{
  id: Scalars['Int'];
  expenseUpdate: Invoice_Expenses_Set_Input;
}>;


export type UpdateExpenseMutation = { update_invoice_expenses_by_pk?: Maybe<{ invoice: { invoice_view?: Maybe<InvoiceFieldsWithExpensesFragment> } }> };

export type LocationFieldsFragment = Pick<Locations, 'id' | 'name' | 'address1' | 'address2' | 'city' | 'country' | 'phone' | 'zip'>;

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { locations: Array<LocationFieldsFragment> };

export type StockLevelFieldsFragment = Pick<Stock_Levels, 'sold_qty' | 'in_stock' | 'orders_in_transfer' | 'last_paid_per_unit'>;

export type StockLevelFieldsPerLocationFragment = Pick<Stock_Levels_Per_Location, 'sold_qty' | 'in_stock' | 'orders_in_transfer' | 'last_paid_per_unit'>;

export type BinLocationFragment = Pick<Bin_Locations, 'id' | 'location_id' | 'product_variant_id' | 'name'>;

export type ProductVariantFieldsFragment = (
  Pick<Product_Variants, 'created_at' | 'display_name' | 'id' | 'inventory_id' | 'price' | 'sku' | 'title' | 'updated_at' | 'shopify_product_id' | 'shopify_product_variant_id'>
  & { shop: Pick<Shops, 'shop'>, bin_locations: Array<BinLocationFragment>, product?: Maybe<(
    Pick<Products, 'title' | 'status'>
    & { vendor: Pick<Vendors, 'id' | 'name'> }
  )>, product_variant_view?: Maybe<Pick<Product_Variants_View, 'deleted_at'>> }
);

export type ShortSupplierFieldsFragment = Pick<Suppliers, 'id' | 'name'>;

export type OrderFieldsWithoutInvoicesFragment = (
  Pick<Orders_View, 'version_created_at' | 'order_created_at' | 'order_id' | 'location_id' | 'order_number' | 'total' | 'status' | 'first_received_at' | 'notes' | 'order_date' | 'supplier_id'>
  & { supplier?: Maybe<ShortSupplierFieldsFragment> }
);

export type OrderFieldsFragment = (
  { order_invoices: Array<{ invoice_view?: Maybe<InvoiceFieldsFragment> }> }
  & OrderFieldsWithoutInvoicesFragment
);

export type OrderFieldsExtendedFragment = (
  { location?: Maybe<LocationFieldsFragment>, supplier?: Maybe<SupplierFieldsWithVendorsFragment> }
  & OrderFieldsFragment
);

export type OrderProductsFieldsFragment = (
  Pick<Order_Products_View, 'id' | 'paid_per_unit' | 'qty_ordered' | 'qty_received'>
  & { last_unit_price?: Maybe<Pick<Stock_Levels_Per_Location, 'last_paid_per_unit'>>, product_variant?: Maybe<ProductVariantFieldsFragment>, supplier_product_variant?: Maybe<Pick<Suppliers_Product_Variants, 'code'>> }
);

export type GetOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrderQuery = { orders_view: Array<(
    { order_products: Array<OrderProductsFieldsFragment> }
    & OrderFieldsExtendedFragment
  )> };

export type GetStatsForOrderProductsQueryVariables = Exact<{
  id: Scalars['Int'];
  sales_location_id: Scalars['Int'];
  from_ts: Scalars['timestamptz'];
  to_ts: Scalars['timestamptz'];
}>;


export type GetStatsForOrderProductsQuery = { orders_view: Array<{ order_products: Array<{ product_variant?: Maybe<Pick<Product_Variants, 'id' | 'inventory_days_available'>>, stock_levels_all_locations: Array<Pick<Stock_Levels_Per_Location, 'in_stock' | 'orders_in_transfer'>>, sales_aggregate: { aggregate?: Maybe<{ sum?: Maybe<Pick<Fulfillments_View_Sum_Fields, 'quantity'>> }> } }> }> };

export type GetStatsForOrderProductsAllLocationsQueryVariables = Exact<{
  id: Scalars['Int'];
  from_ts: Scalars['timestamptz'];
  to_ts: Scalars['timestamptz'];
}>;


export type GetStatsForOrderProductsAllLocationsQuery = { orders_view: Array<{ order_products: Array<{ product_variant?: Maybe<Pick<Product_Variants, 'id' | 'inventory_days_available'>>, stock_levels_all_locations_aggregate: { aggregate?: Maybe<{ sum?: Maybe<Pick<Stock_Levels_Per_Location_Sum_Fields, 'in_stock' | 'orders_in_transfer'>> }> }, sales_aggregate: { aggregate?: Maybe<{ sum?: Maybe<Pick<Fulfillments_View_Sum_Fields, 'quantity'>> }> } }> }> };

export type GetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsQuery = { product_variants: Array<ProductVariantFieldsFragment> };

export type GetSupplierAllVendorsProductsQueryVariables = Exact<{
  supplier_id: Scalars['Int'];
}>;


export type GetSupplierAllVendorsProductsQuery = { suppliers_vendors: Array<{ vendor: { products: Array<{ product_variants: Array<ProductVariantFieldsFragment> }> } }> };

export type GetAllAvailableProductsQueryVariables = Exact<{
  supplier: Scalars['Int'];
}>;


export type GetAllAvailableProductsQuery = { suppliers_product_variants_all: Array<{ product_variant?: Maybe<ProductVariantFieldsFragment> }> };

export type GetSupplierVendorProductsQueryVariables = Exact<{
  supplier: Scalars['Int'];
  vendor: Scalars['Int'];
}>;


export type GetSupplierVendorProductsQuery = { suppliers_vendors_products: Array<(
    Pick<Suppliers_Vendors_Products, 'supplier_code'>
    & { product_variant?: Maybe<ProductVariantFieldsFragment> }
  )> };

export type GetSupplierAssignedProductsQueryVariables = Exact<{
  supplier_id: Scalars['Int'];
}>;


export type GetSupplierAssignedProductsQuery = { suppliers_product_variants: Array<(
    Pick<Suppliers_Product_Variants, 'code'>
    & { product_variant: ProductVariantFieldsFragment }
  )> };

export type InsertNewOrderPopulateOptionsMutationVariables = Exact<{
  supplier_id: Scalars['Int'];
  location_id: Scalars['Int'];
  vendors: Scalars['Boolean'];
  assigned: Scalars['Boolean'];
}>;


export type InsertNewOrderPopulateOptionsMutation = { create_and_populate_order_options: Array<Pick<Orders, 'id'>> };

export type InsertNewOrderPopulateVendorProductsMutationVariables = Exact<{
  supplier_id: Scalars['Int'];
  location_id: Scalars['Int'];
}>;


export type InsertNewOrderPopulateVendorProductsMutation = { create_and_populate_order: Array<Pick<Orders, 'id'>> };

export type InsertNewOrderPopulateSupplierProductsMutationVariables = Exact<{
  supplier_id: Scalars['Int'];
  location_id: Scalars['Int'];
}>;


export type InsertNewOrderPopulateSupplierProductsMutation = { create_and_populate_order_supplier_products: Array<Pick<Orders, 'id'>> };

export type InsertNewOrderPopulateCsvMutationVariables = Exact<{
  supplier_id: Scalars['Int'];
  location_id: Scalars['Int'];
  populate: Scalars['json'];
}>;


export type InsertNewOrderPopulateCsvMutation = { create_and_populate_order_supplied: Array<Pick<Orders, 'id'>> };

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOrderMutation = { delete_orders_by_pk?: Maybe<Pick<Orders, 'id'>> };

export type GetOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = { orders_view: Array<OrderFieldsFragment> };

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['Int'];
  set: Orders_Set_Input;
}>;


export type UpdateOrderMutation = { update_orders_by_pk?: Maybe<{ order_view?: Maybe<OrderFieldsExtendedFragment> }> };

export type UpdateOrderGetProductsMutationVariables = Exact<{
  id: Scalars['Int'];
  set: Orders_Set_Input;
}>;


export type UpdateOrderGetProductsMutation = { update_orders_by_pk?: Maybe<{ order_view?: Maybe<(
      { order_products: Array<OrderProductsFieldsFragment> }
      & OrderFieldsExtendedFragment
    )> }> };

export type DeleteOrderProductsMutationVariables = Exact<{
  products?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteOrderProductsMutation = { delete_order_products?: Maybe<Pick<Order_Products_Mutation_Response, 'affected_rows'>> };

export type DeleteOrderProductGetInvoiceMutationVariables = Exact<{
  orderId: Scalars['Int'];
  orderProductId: Scalars['Int'];
  invoiceId: Scalars['Int'];
}>;


export type DeleteOrderProductGetInvoiceMutation = { delete_order_products_by_pk?: Maybe<{ order: { order_invoices: Array<{ invoice_view?: Maybe<(
          { order_products_invoices: Array<OrderProductsInvoiceWithVariantsFragment> }
          & InvoiceFieldsWithExpensesFragment
        )> }> } }> };

export type AddOrderProductsMutationVariables = Exact<{
  products: Array<Order_Products_Insert_Input> | Order_Products_Insert_Input;
}>;


export type AddOrderProductsMutation = { insert_order_products?: Maybe<Pick<Order_Products_Mutation_Response, 'affected_rows'>> };

export type GetReportsQueryVariables = Exact<{
  from?: Maybe<Scalars['date']>;
  to?: Maybe<Scalars['date']>;
}>;


export type GetReportsQuery = { reports: Array<(
    Pick<Reports, 'date' | 'in_stock' | 'in_transfer' | 'location_id' | 'value_in_transfer' | 'value_on_hand'>
    & { location?: Maybe<Pick<Locations, 'name'>> }
  )> };

export type InsertStocktakeMutationVariables = Exact<{
  location_id: Scalars['Int'];
  shop_id: Scalars['Int'];
  vendor_id: Scalars['Int'];
}>;


export type InsertStocktakeMutation = { insert_stocktakes_one?: Maybe<Pick<Stocktakes, 'id'>> };

export type StocktakeFieldsFragment = (
  Pick<Stocktake_View, 'id' | 'stocktake_number' | 'archived_at' | 'counted_at' | 'created_at' | 'status' | 'location_id' | 'vendor_id' | 'total' | 'updated_at'>
  & { location?: Maybe<LocationFieldsFragment>, vendor?: Maybe<VendorFieldsFragment> }
);

export type StocktakeProductFieldsFragment = (
  Pick<Stocktake_Products, 'qty_counted' | 'qty_expected' | 'cost'>
  & { product_variant?: Maybe<ProductVariantFieldsFragment> }
);

export type GetStocktakeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetStocktakeQuery = { stocktake_view: Array<(
    { stocktake_products: Array<StocktakeProductFieldsFragment> }
    & StocktakeFieldsFragment
  )> };

export type GetStocktakesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStocktakesQuery = { stocktake_view: Array<StocktakeFieldsFragment> };

export type CreateStocktakeMutationVariables = Exact<{
  vendor_id?: Maybe<Scalars['Int']>;
  location_id: Scalars['Int'];
  shop_id: Scalars['Int'];
}>;


export type CreateStocktakeMutation = { insert_stocktakes_one?: Maybe<Pick<Stocktakes, 'id'>> };

export type StartStocktakeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StartStocktakeMutation = { start_stocktake: Array<Pick<Stocktake_Locked, 'id'>> };

export type StopCountingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StopCountingMutation = { update_stocktakes_by_pk?: Maybe<Pick<Stocktakes, 'id'>> };

export type DeleteStocktakeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStocktakeMutation = { delete_stocktake_locked?: Maybe<Pick<Stocktake_Locked_Mutation_Response, 'affected_rows'>>, delete_stocktakes_by_pk?: Maybe<Pick<Stocktakes, 'id'>> };

export type UpdateStocktakeProductMutationVariables = Exact<{
  stocktake_id: Scalars['Int'];
  product_variant_id: Scalars['Int'];
  count: Scalars['Int'];
}>;


export type UpdateStocktakeProductMutation = { update_stocktake_locked?: Maybe<{ returning: Array<Pick<Stocktake_Locked, 'qty_counted'>> }> };

export type SupplierFieldsFragment = Pick<Suppliers, 'id' | 'name' | 'email' | 'contact_first_name' | 'contact_last_name'>;

export type SupplierFieldsWithVendorsFragment = (
  { suppliers_vendors: Array<{ vendor: VendorFieldsFragment }> }
  & SupplierFieldsFragment
);

export type GetSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuppliersQuery = { suppliers: Array<SupplierFieldsWithVendorsFragment> };

export type CreateSupplierForVariantMutationVariables = Exact<{
  data: Array<Suppliers_Product_Variants_Insert_Input> | Suppliers_Product_Variants_Insert_Input;
}>;


export type CreateSupplierForVariantMutation = { insert_suppliers_product_variants?: Maybe<{ returning: Array<Pick<Suppliers_Product_Variants, 'supplier_id' | 'product_variant_id' | 'code'>> }> };

export type CreateSupplierForVariantReturningPvDataMutationVariables = Exact<{
  data: Array<Suppliers_Product_Variants_Insert_Input> | Suppliers_Product_Variants_Insert_Input;
}>;


export type CreateSupplierForVariantReturningPvDataMutation = { insert_suppliers_product_variants?: Maybe<{ returning: Array<(
      Pick<Suppliers_Product_Variants, 'supplier_id' | 'product_variant_id' | 'code'>
      & { product_variant: ProductVariantFieldsFragment }
    )> }> };

export type UpsertSupplierPvsWithSkuMutationVariables = Exact<{
  supplier_id: Scalars['Int'];
  data: Scalars['json'];
}>;


export type UpsertSupplierPvsWithSkuMutation = { upsert_supplier_product_variants_sku: Array<(
    Pick<Suppliers_Product_Variants, 'supplier_id' | 'product_variant_id' | 'code'>
    & { product_variant: ProductVariantFieldsFragment }
  )> };

export type DeleteSupplierProductsMutationVariables = Exact<{
  supplier_id: Scalars['Int'];
  product_variant_id: Scalars['Int'];
}>;


export type DeleteSupplierProductsMutation = { delete_suppliers_product_variants?: Maybe<Pick<Suppliers_Product_Variants_Mutation_Response, 'affected_rows'>> };

export type FindXeroContactQueryVariables = Exact<{
  query: Scalars['String'];
  shopId: Scalars['Int'];
}>;


export type FindXeroContactQuery = { xero_contacts: Array<Pick<Xero_Contacts, 'id' | 'name'>> };

export type TransferFieldsFragment = Pick<Transfers_View, 'to_location_id' | 'from_location_id' | 'id' | 'status' | 'transfer_number' | 'updated_at' | 'created_at' | 'total'>;

export type TransferProductFieldsFragment = (
  Pick<Transfer_Products_View, 'product_variant_id' | 'qty_fulfilled' | 'qty_received' | 'qty_requested' | 'id' | 'created_at' | 'stock_at_origin' | 'stock_at_destination'>
  & { product_variant?: Maybe<ProductVariantFieldsFragment> }
);

export type TransfersQueryVariables = Exact<{ [key: string]: never; }>;


export type TransfersQuery = { transfers_view: Array<TransferFieldsFragment> };

export type TransferQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TransferQuery = { transfers_view: Array<(
    { transfer_products: Array<TransferProductFieldsFragment> }
    & TransferFieldsFragment
  )> };

export type DeleteTransferMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTransferMutation = { delete_transfer_products?: Maybe<Pick<Transfer_Products_Mutation_Response, 'affected_rows'>>, delete_transfers_by_pk?: Maybe<Pick<Transfers, 'id'>> };

export type CreateTransferMutationVariables = Exact<{
  from_location_id: Scalars['Int'];
  to_location_id: Scalars['Int'];
  shop_id: Scalars['Int'];
}>;


export type CreateTransferMutation = { insert_transfers_one?: Maybe<Pick<Transfers, 'id'>> };

export type UpdateTransferMutationVariables = Exact<{
  set: Transfers_Set_Input;
  transfer_id: Scalars['Int'];
}>;


export type UpdateTransferMutation = { update_transfers_by_pk?: Maybe<{ transfer_view?: Maybe<TransferFieldsFragment> }> };

export type AddTransferProductsMutationVariables = Exact<{
  products: Array<Transfer_Products_Insert_Input> | Transfer_Products_Insert_Input;
}>;


export type AddTransferProductsMutation = { insert_transfer_products?: Maybe<Pick<Transfer_Products_Mutation_Response, 'affected_rows'>> };

export type UpdateTransferProductMutationVariables = Exact<{
  id: Scalars['Int'];
  update: Transfer_Products_Set_Input;
}>;


export type UpdateTransferProductMutation = { update_transfer_products_by_pk?: Maybe<{ transfer_product_view?: Maybe<TransferProductFieldsFragment> }> };

export type DeleteTransferProductsMutationVariables = Exact<{
  products?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteTransferProductsMutation = { delete_transfer_products?: Maybe<Pick<Transfer_Products_Mutation_Response, 'affected_rows'>> };

export type GetUserQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type GetUserQuery = { users_by_pk?: Maybe<(
    Pick<Users, 'id'>
    & { shop: Pick<Shops, 'id' | 'shop' | 'bin_locations_active'> }
  )> };

export type VendorFieldsWithSuppliersFragment = (
  { suppliers_vendors: Array<{ supplier: SupplierFieldsFragment }> }
  & VendorFieldsFragment
);

export type VendorFieldsFragment = Pick<Vendors, 'id' | 'name'>;

export type GetVendorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVendorsQuery = { vendors: Array<VendorFieldsWithSuppliersFragment> };

export const ShortSupplierFieldsFragmentDoc = gql`
    fragment shortSupplierFields on suppliers {
  id
  name
}
    `;
export const InvoiceFieldsFragmentDoc = gql`
    fragment invoiceFields on invoices_view {
  created_at
  id
  invoice_date
  invoice_number
  payment_due
  xero_invoice_id
  adjustments
  total_received
  total_expenses
  total
  total_with_gst
  supplier {
    ...shortSupplierFields
  }
}
    ${ShortSupplierFieldsFragmentDoc}`;
export const InvoiceExpensesFieldsFragmentDoc = gql`
    fragment invoiceExpensesFields on invoice_expenses {
  amount
  created_at
  description
  id
  updated_at
  invoice_id
}
    `;
export const InvoiceFieldsWithExpensesFragmentDoc = gql`
    fragment invoiceFieldsWithExpenses on invoices_view {
  ...invoiceFields
  invoice_expenses {
    ...invoiceExpensesFields
  }
}
    ${InvoiceFieldsFragmentDoc}
${InvoiceExpensesFieldsFragmentDoc}`;
export const OrderProductsInvoiceFragmentDoc = gql`
    fragment orderProductsInvoice on order_products_invoice {
  paid_per_unit
  product_variant_id
  qty_received
  updated_at
  created_at
}
    `;
export const BinLocationFragmentDoc = gql`
    fragment binLocation on bin_locations {
  id
  location_id
  product_variant_id
  name
}
    `;
export const ProductVariantFieldsFragmentDoc = gql`
    fragment productVariantFields on product_variants {
  created_at
  display_name
  id
  inventory_id
  price
  sku
  title
  updated_at
  shopify_product_id
  shopify_product_variant_id
  shop {
    shop
  }
  bin_locations {
    ...binLocation
  }
  product {
    title
    status
    vendor {
      id
      name
    }
  }
  product_variant_view {
    deleted_at
  }
}
    ${BinLocationFragmentDoc}`;
export const OrderProductsInvoiceWithVariantsFragmentDoc = gql`
    fragment orderProductsInvoiceWithVariants on order_products_invoice {
  ...orderProductsInvoice
  order_id
  order {
    order_number
  }
  product_variant {
    ...productVariantFields
  }
}
    ${OrderProductsInvoiceFragmentDoc}
${ProductVariantFieldsFragmentDoc}`;
export const StockLevelFieldsFragmentDoc = gql`
    fragment stockLevelFields on stock_levels {
  sold_qty
  in_stock
  orders_in_transfer
  last_paid_per_unit
}
    `;
export const StockLevelFieldsPerLocationFragmentDoc = gql`
    fragment stockLevelFieldsPerLocation on stock_levels_per_location {
  sold_qty
  in_stock
  orders_in_transfer
  last_paid_per_unit
}
    `;
export const OrderFieldsWithoutInvoicesFragmentDoc = gql`
    fragment orderFieldsWithoutInvoices on orders_view {
  version_created_at
  order_created_at
  order_id
  location_id
  order_number
  total
  status
  first_received_at
  notes
  order_date
  supplier_id
  supplier {
    ...shortSupplierFields
  }
}
    ${ShortSupplierFieldsFragmentDoc}`;
export const OrderFieldsFragmentDoc = gql`
    fragment orderFields on orders_view {
  ...orderFieldsWithoutInvoices
  order_invoices {
    invoice_view {
      ...invoiceFields
    }
  }
}
    ${OrderFieldsWithoutInvoicesFragmentDoc}
${InvoiceFieldsFragmentDoc}`;
export const LocationFieldsFragmentDoc = gql`
    fragment locationFields on locations {
  id
  name
  address1
  address2
  city
  country
  phone
  zip
}
    `;
export const SupplierFieldsFragmentDoc = gql`
    fragment supplierFields on suppliers {
  id
  name
  email
  contact_first_name
  contact_last_name
}
    `;
export const VendorFieldsFragmentDoc = gql`
    fragment vendorFields on vendors {
  id
  name
}
    `;
export const SupplierFieldsWithVendorsFragmentDoc = gql`
    fragment supplierFieldsWithVendors on suppliers {
  ...supplierFields
  suppliers_vendors {
    vendor {
      ...vendorFields
    }
  }
}
    ${SupplierFieldsFragmentDoc}
${VendorFieldsFragmentDoc}`;
export const OrderFieldsExtendedFragmentDoc = gql`
    fragment orderFieldsExtended on orders_view {
  ...orderFields
  location {
    ...locationFields
  }
  supplier {
    ...supplierFieldsWithVendors
  }
}
    ${OrderFieldsFragmentDoc}
${LocationFieldsFragmentDoc}
${SupplierFieldsWithVendorsFragmentDoc}`;
export const OrderProductsFieldsFragmentDoc = gql`
    fragment orderProductsFields on order_products_view {
  id
  paid_per_unit
  qty_ordered
  qty_received
  last_unit_price: stock_level_per_location {
    last_paid_per_unit
  }
  product_variant {
    ...productVariantFields
  }
  supplier_product_variant {
    code
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const StocktakeFieldsFragmentDoc = gql`
    fragment stocktakeFields on stocktake_view {
  id
  stocktake_number
  archived_at
  counted_at
  created_at
  status
  location_id
  vendor_id
  total
  updated_at
  location {
    ...locationFields
  }
  vendor {
    ...vendorFields
  }
}
    ${LocationFieldsFragmentDoc}
${VendorFieldsFragmentDoc}`;
export const StocktakeProductFieldsFragmentDoc = gql`
    fragment stocktakeProductFields on stocktake_products {
  qty_counted
  qty_expected
  cost
  product_variant {
    ...productVariantFields
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const TransferFieldsFragmentDoc = gql`
    fragment transferFields on transfers_view {
  to_location_id
  from_location_id
  id
  status
  transfer_number
  updated_at
  created_at
  total
}
    `;
export const TransferProductFieldsFragmentDoc = gql`
    fragment transferProductFields on transfer_products_view {
  product_variant_id
  qty_fulfilled
  qty_received
  qty_requested
  id
  created_at
  stock_at_origin
  stock_at_destination
  product_variant {
    ...productVariantFields
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const VendorFieldsWithSuppliersFragmentDoc = gql`
    fragment vendorFieldsWithSuppliers on vendors {
  ...vendorFields
  suppliers_vendors {
    supplier {
      ...supplierFields
    }
  }
}
    ${VendorFieldsFragmentDoc}
${SupplierFieldsFragmentDoc}`;
export const UpsertBinLocationDocument = gql`
    mutation UpsertBinLocation($location_id: Int!, $name: String!, $product_variant_id: Int!) {
  insert_bin_locations_one(
    object: {location_id: $location_id, name: $name, product_variant_id: $product_variant_id}
    on_conflict: {constraint: bin_locations_location_id_product_variant_id_key, update_columns: name}
  ) {
    location_id
    product_variant_id
    name
  }
}
    `;
export const GetInvoiceForOrderDocument = gql`
    query GetInvoiceForOrder($id: Int!, $orderId: Int!) {
  invoices_view(where: {id: {_eq: $id}}) {
    ...invoiceFieldsWithExpenses
    order_invoices(where: {order_id: {_eq: $orderId}}) {
      order_products_invoices(order_by: {product_variant: {display_name: asc}}) {
        ...orderProductsInvoiceWithVariants
      }
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}
${OrderProductsInvoiceWithVariantsFragmentDoc}`;
export const GetInvoiceDocument = gql`
    query GetInvoice($id: Int!) {
  invoices_view(where: {id: {_eq: $id}}) {
    ...invoiceFieldsWithExpenses
    order_products_invoices(order_by: {product_variant: {display_name: asc}}) {
      ...orderProductsInvoiceWithVariants
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}
${OrderProductsInvoiceWithVariantsFragmentDoc}`;
export const FindInvoicesDocument = gql`
    query FindInvoices($invoiceNoSearch: String!, $shopId: Int!, $supplierId: Int!) {
  invoices(
    where: {invoice_number: {_ilike: $invoiceNoSearch}, shop_id: {_eq: $shopId}, supplier_id: {_eq: $supplierId}}
  ) {
    id
    invoice_number
  }
}
    `;
export const InsertInvoiceDocument = gql`
    mutation InsertInvoice($orderId: Int!, $shopId: Int!, $invoiceNumber: String, $supplierId: Int!) {
  insert_order_invoices_one(
    object: {order_id: $orderId, invoice: {data: {invoice_number: $invoiceNumber, shop_id: $shopId, supplier_id: $supplierId}}}
  ) {
    invoice {
      invoice_view {
        ...invoiceFieldsWithExpenses
      }
    }
    order_products_invoices(order_by: {product_variant: {display_name: asc}}) {
      ...orderProductsInvoiceWithVariants
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}
${OrderProductsInvoiceWithVariantsFragmentDoc}`;
export const DeleteOrderInvoiceDocument = gql`
    mutation DeleteOrderInvoice($orderId: Int!, $invoiceId: Int!) {
  delete_order_invoices_by_pk(invoice_id: $invoiceId, order_id: $orderId) {
    invoice_id
    order_id
    invoice {
      invoice_view {
        ...invoiceFieldsWithExpenses
      }
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}`;
export const InsertOrderInvoiceDocument = gql`
    mutation InsertOrderInvoice($orderId: Int!, $invoiceId: Int!) {
  insert_order_invoices_one(object: {invoice_id: $invoiceId, order_id: $orderId}) {
    order_products_invoices(order_by: {product_variant: {display_name: asc}}) {
      ...orderProductsInvoiceWithVariants
    }
    invoice {
      invoice_view {
        ...invoiceFieldsWithExpenses
      }
    }
  }
}
    ${OrderProductsInvoiceWithVariantsFragmentDoc}
${InvoiceFieldsWithExpensesFragmentDoc}`;
export const DeleteOrderProductInvoiceDocument = gql`
    mutation DeleteOrderProductInvoice($orderId: Int!, $invoiceId: Int!, $pvId: Int!) {
  delete_order_products_invoice_by_pk(
    invoice_id: $invoiceId
    order_id: $orderId
    product_variant_id: $pvId
  ) {
    invoice_id
    order_id
    product_variant_id
    invoice {
      invoice_view {
        ...invoiceFieldsWithExpenses
      }
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}`;
export const SendToXeroDocument = gql`
    mutation SendToXero($invoiceId: Int!) {
  xero {
    createInvoice(invoiceId: $invoiceId)
  }
}
    `;
export const UpsertOrderProductInvoicesDocument = gql`
    mutation UpsertOrderProductInvoices($object: order_products_invoice_insert_input!) {
  insert_order_products_invoice_one(
    object: $object
    on_conflict: {constraint: order_products_invoice_pkey, update_columns: [paid_per_unit, qty_received]}
  ) {
    ...orderProductsInvoiceWithVariants
    order_product_view {
      ...orderProductsFields
    }
    invoice_view {
      ...invoiceFields
    }
    order_view {
      ...orderFieldsExtended
    }
  }
}
    ${OrderProductsInvoiceWithVariantsFragmentDoc}
${OrderProductsFieldsFragmentDoc}
${InvoiceFieldsFragmentDoc}
${OrderFieldsExtendedFragmentDoc}`;
export const UpsertOrderProductInvoicesPaidPerUnitDocument = gql`
    mutation UpsertOrderProductInvoicesPaidPerUnit($object: order_products_invoice_insert_input!) {
  insert_order_products_invoice_one(
    object: $object
    on_conflict: {constraint: order_products_invoice_pkey, update_columns: [paid_per_unit]}
  ) {
    ...orderProductsInvoiceWithVariants
    order_product_view {
      ...orderProductsFields
    }
    invoice_view {
      ...invoiceFields
    }
    order_view {
      ...orderFieldsExtended
    }
  }
}
    ${OrderProductsInvoiceWithVariantsFragmentDoc}
${OrderProductsFieldsFragmentDoc}
${InvoiceFieldsFragmentDoc}
${OrderFieldsExtendedFragmentDoc}`;
export const UpsertOrderProductInvoicesQtyReceivedDocument = gql`
    mutation UpsertOrderProductInvoicesQtyReceived($object: order_products_invoice_insert_input!) {
  insert_order_products_invoice_one(
    object: $object
    on_conflict: {constraint: order_products_invoice_pkey, update_columns: [qty_received]}
  ) {
    ...orderProductsInvoiceWithVariants
    order_product_view {
      ...orderProductsFields
    }
    invoice_view {
      ...invoiceFields
    }
    order_view {
      ...orderFieldsExtended
    }
  }
}
    ${OrderProductsInvoiceWithVariantsFragmentDoc}
${OrderProductsFieldsFragmentDoc}
${InvoiceFieldsFragmentDoc}
${OrderFieldsExtendedFragmentDoc}`;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($id: Int!, $set: invoices_set_input!) {
  update_invoices_by_pk(pk_columns: {id: $id}, _set: $set) {
    invoice_view {
      ...invoiceFieldsWithExpenses
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}`;
export const GetInvoicesDocument = gql`
    query GetInvoices {
  invoices_view {
    ...invoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($id: Int!) {
  delete_invoices_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($id: Int!) {
  delete_invoice_expenses_by_pk(id: $id) {
    invoice_view {
      ...invoiceFieldsWithExpenses
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}`;
export const CreateExpensesDocument = gql`
    mutation CreateExpenses($expense: invoice_expenses_insert_input!) {
  insert_invoice_expenses_one(object: $expense) {
    invoice {
      invoice_view {
        ...invoiceFieldsWithExpenses
      }
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}`;
export const UpdateExpenseDocument = gql`
    mutation UpdateExpense($id: Int!, $expenseUpdate: invoice_expenses_set_input!) {
  update_invoice_expenses_by_pk(pk_columns: {id: $id}, _set: $expenseUpdate) {
    invoice {
      invoice_view {
        ...invoiceFieldsWithExpenses
      }
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}`;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    ...locationFields
  }
}
    ${LocationFieldsFragmentDoc}`;
export const GetOrderDocument = gql`
    query GetOrder($id: Int!) {
  orders_view(where: {order_id: {_eq: $id}}) {
    ...orderFieldsExtended
    order_products(order_by: {product_variant: {display_name: asc}}) {
      ...orderProductsFields
    }
  }
}
    ${OrderFieldsExtendedFragmentDoc}
${OrderProductsFieldsFragmentDoc}`;
export const GetStatsForOrderProductsDocument = gql`
    query GetStatsForOrderProducts($id: Int!, $sales_location_id: Int!, $from_ts: timestamptz!, $to_ts: timestamptz!) {
  orders_view(where: {order_id: {_eq: $id}}) {
    order_products {
      product_variant {
        id
        inventory_days_available(
          args: {from_ts: $from_ts, loc_id: $sales_location_id, to_ts: $to_ts}
        )
      }
      stock_levels_all_locations(where: {location_id: {_eq: $sales_location_id}}) {
        in_stock
        orders_in_transfer
      }
      sales_aggregate(
        where: {location_id: {_eq: $sales_location_id}, processed_at: {_lte: $to_ts, _gte: $from_ts}}
      ) {
        aggregate {
          sum {
            quantity
          }
        }
      }
    }
  }
}
    `;
export const GetStatsForOrderProductsAllLocationsDocument = gql`
    query GetStatsForOrderProductsAllLocations($id: Int!, $from_ts: timestamptz!, $to_ts: timestamptz!) {
  orders_view(where: {order_id: {_eq: $id}}) {
    order_products {
      product_variant {
        id
        inventory_days_available(args: {from_ts: $from_ts, loc_id: null, to_ts: $to_ts})
      }
      stock_levels_all_locations_aggregate {
        aggregate {
          sum {
            in_stock
            orders_in_transfer
          }
        }
      }
      sales_aggregate(where: {processed_at: {_lte: $to_ts, _gte: $from_ts}}) {
        aggregate {
          sum {
            quantity
          }
        }
      }
    }
  }
}
    `;
export const GetProductsDocument = gql`
    query GetProducts {
  product_variants {
    ...productVariantFields
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const GetSupplierAllVendorsProductsDocument = gql`
    query GetSupplierAllVendorsProducts($supplier_id: Int!) {
  suppliers_vendors(where: {supplier_id: {_eq: $supplier_id}}) {
    vendor {
      products {
        product_variants {
          ...productVariantFields
        }
      }
    }
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const GetAllAvailableProductsDocument = gql`
    query GetAllAvailableProducts($supplier: Int!) {
  suppliers_product_variants_all(
    where: {supplier_id: {_eq: $supplier}}
    order_by: {product_variant: {display_name: asc}}
  ) {
    product_variant {
      ...productVariantFields
    }
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const GetSupplierVendorProductsDocument = gql`
    query GetSupplierVendorProducts($supplier: Int!, $vendor: Int!) {
  suppliers_vendors_products(
    where: {supplier_id: {_eq: $supplier}, vendor_id: {_eq: $vendor}}
    order_by: {product_variant: {display_name: asc}}
  ) {
    supplier_code
    product_variant {
      ...productVariantFields
    }
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const GetSupplierAssignedProductsDocument = gql`
    query GetSupplierAssignedProducts($supplier_id: Int!) {
  suppliers_product_variants(
    where: {supplier_id: {_eq: $supplier_id}}
    order_by: {product_variant: {display_name: asc}}
  ) {
    code
    product_variant {
      ...productVariantFields
    }
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const InsertNewOrderPopulateOptionsDocument = gql`
    mutation InsertNewOrderPopulateOptions($supplier_id: Int!, $location_id: Int!, $vendors: Boolean!, $assigned: Boolean!) {
  create_and_populate_order_options(
    args: {input_location_id: $location_id, input_supplier_id: $supplier_id, vendors: $vendors, assigned: $assigned}
  ) {
    id
  }
}
    `;
export const InsertNewOrderPopulateVendorProductsDocument = gql`
    mutation InsertNewOrderPopulateVendorProducts($supplier_id: Int!, $location_id: Int!) {
  create_and_populate_order(
    args: {input_location_id: $location_id, input_supplier_id: $supplier_id}
  ) {
    id
  }
}
    `;
export const InsertNewOrderPopulateSupplierProductsDocument = gql`
    mutation InsertNewOrderPopulateSupplierProducts($supplier_id: Int!, $location_id: Int!) {
  create_and_populate_order_supplier_products(
    args: {input_location_id: $location_id, input_supplier_id: $supplier_id}
  ) {
    id
  }
}
    `;
export const InsertNewOrderPopulateCsvDocument = gql`
    mutation InsertNewOrderPopulateCsv($supplier_id: Int!, $location_id: Int!, $populate: json!) {
  create_and_populate_order_supplied(
    args: {input_location_id: $location_id, input_supplier_id: $supplier_id, populate: $populate}
  ) {
    id
  }
}
    `;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($id: Int!) {
  delete_orders_by_pk(id: $id) {
    id
  }
}
    `;
export const GetOrdersDocument = gql`
    query GetOrders {
  orders_view {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($id: Int!, $set: orders_set_input!) {
  update_orders_by_pk(pk_columns: {id: $id}, _set: $set) {
    order_view {
      ...orderFieldsExtended
    }
  }
}
    ${OrderFieldsExtendedFragmentDoc}`;
export const UpdateOrderGetProductsDocument = gql`
    mutation UpdateOrderGetProducts($id: Int!, $set: orders_set_input!) {
  update_orders_by_pk(pk_columns: {id: $id}, _set: $set) {
    order_view {
      ...orderFieldsExtended
      order_products(order_by: {product_variant: {display_name: asc}}) {
        ...orderProductsFields
      }
    }
  }
}
    ${OrderFieldsExtendedFragmentDoc}
${OrderProductsFieldsFragmentDoc}`;
export const DeleteOrderProductsDocument = gql`
    mutation DeleteOrderProducts($products: [Int!]) {
  delete_order_products(where: {id: {_in: $products}}) {
    affected_rows
  }
}
    `;
export const DeleteOrderProductGetInvoiceDocument = gql`
    mutation DeleteOrderProductGetInvoice($orderId: Int!, $orderProductId: Int!, $invoiceId: Int!) {
  delete_order_products_by_pk(id: $orderProductId) {
    order {
      order_invoices(where: {invoice_id: {_eq: $invoiceId}}) {
        invoice_view {
          ...invoiceFieldsWithExpenses
          order_products_invoices(where: {order_id: {_eq: $orderId}}) {
            ...orderProductsInvoiceWithVariants
          }
        }
      }
    }
  }
}
    ${InvoiceFieldsWithExpensesFragmentDoc}
${OrderProductsInvoiceWithVariantsFragmentDoc}`;
export const AddOrderProductsDocument = gql`
    mutation AddOrderProducts($products: [order_products_insert_input!]!) {
  insert_order_products(objects: $products) {
    affected_rows
  }
}
    `;
export const GetReportsDocument = gql`
    query GetReports($from: date, $to: date) {
  reports(order_by: {date: asc}, where: {date: {_gte: $from, _lte: $to}}) {
    date
    in_stock
    in_transfer
    location_id
    value_in_transfer
    value_on_hand
    location {
      name
    }
  }
}
    `;
export const InsertStocktakeDocument = gql`
    mutation InsertStocktake($location_id: Int!, $shop_id: Int!, $vendor_id: Int!) {
  insert_stocktakes_one(
    object: {location_id: $location_id, shop_id: $shop_id, vendor_id: $vendor_id}
  ) {
    id
  }
}
    `;
export const GetStocktakeDocument = gql`
    query GetStocktake($id: Int!) {
  stocktake_view(where: {id: {_eq: $id}}) {
    ...stocktakeFields
    stocktake_products(order_by: {product_variant: {display_name: asc}}) {
      ...stocktakeProductFields
    }
  }
}
    ${StocktakeFieldsFragmentDoc}
${StocktakeProductFieldsFragmentDoc}`;
export const GetStocktakesDocument = gql`
    query GetStocktakes {
  stocktake_view {
    ...stocktakeFields
  }
}
    ${StocktakeFieldsFragmentDoc}`;
export const CreateStocktakeDocument = gql`
    mutation CreateStocktake($vendor_id: Int, $location_id: Int!, $shop_id: Int!) {
  insert_stocktakes_one(
    object: {vendor_id: $vendor_id, location_id: $location_id, shop_id: $shop_id}
  ) {
    id
  }
}
    `;
export const StartStocktakeDocument = gql`
    mutation StartStocktake($id: Int!) {
  start_stocktake(args: {arg_1: $id}) {
    id
  }
}
    `;
export const StopCountingDocument = gql`
    mutation StopCounting($id: Int!) {
  update_stocktakes_by_pk(
    pk_columns: {id: $id}
    _set: {status: "counted", counted_at: "now()"}
  ) {
    id
  }
}
    `;
export const DeleteStocktakeDocument = gql`
    mutation DeleteStocktake($id: Int!) {
  delete_stocktake_locked(where: {stocktake_id: {_eq: $id}}) {
    affected_rows
  }
  delete_stocktakes_by_pk(id: $id) {
    id
  }
}
    `;
export const UpdateStocktakeProductDocument = gql`
    mutation UpdateStocktakeProduct($stocktake_id: Int!, $product_variant_id: Int!, $count: Int!) {
  update_stocktake_locked(
    where: {stocktake_id: {_eq: $stocktake_id}, product_variant_id: {_eq: $product_variant_id}}
    _set: {qty_counted: $count}
  ) {
    returning {
      qty_counted
    }
  }
}
    `;
export const GetSuppliersDocument = gql`
    query GetSuppliers {
  suppliers {
    ...supplierFieldsWithVendors
  }
}
    ${SupplierFieldsWithVendorsFragmentDoc}`;
export const CreateSupplierForVariantDocument = gql`
    mutation CreateSupplierForVariant($data: [suppliers_product_variants_insert_input!]!) {
  insert_suppliers_product_variants(
    objects: $data
    on_conflict: {constraint: suppliers_product_variants_product_variant_id_supplier_id_key, update_columns: code}
  ) {
    returning {
      supplier_id
      product_variant_id
      code
    }
  }
}
    `;
export const CreateSupplierForVariantReturningPvDataDocument = gql`
    mutation CreateSupplierForVariantReturningPvData($data: [suppliers_product_variants_insert_input!]!) {
  insert_suppliers_product_variants(
    objects: $data
    on_conflict: {constraint: suppliers_product_variants_product_variant_id_supplier_id_key, update_columns: code}
  ) {
    returning {
      supplier_id
      product_variant_id
      code
      product_variant {
        ...productVariantFields
      }
    }
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const UpsertSupplierPvsWithSkuDocument = gql`
    mutation UpsertSupplierPvsWithSku($supplier_id: Int!, $data: json!) {
  upsert_supplier_product_variants_sku(
    args: {input_supplier_id: $supplier_id, populate: $data}
  ) {
    supplier_id
    product_variant_id
    code
    product_variant {
      ...productVariantFields
    }
  }
}
    ${ProductVariantFieldsFragmentDoc}`;
export const DeleteSupplierProductsDocument = gql`
    mutation DeleteSupplierProducts($supplier_id: Int!, $product_variant_id: Int!) {
  delete_suppliers_product_variants(
    where: {supplier_id: {_eq: $supplier_id}, product_variant_id: {_eq: $product_variant_id}}
  ) {
    affected_rows
  }
}
    `;
export const FindXeroContactDocument = gql`
    query FindXeroContact($query: String!, $shopId: Int!) {
  xero_contacts(
    where: {name: {_ilike: $query}, shop_id: {_eq: $shopId}}
    order_by: {name: asc}
  ) {
    id
    name
  }
}
    `;
export const TransfersDocument = gql`
    query Transfers {
  transfers_view {
    ...transferFields
  }
}
    ${TransferFieldsFragmentDoc}`;
export const TransferDocument = gql`
    query Transfer($id: Int!) {
  transfers_view(where: {id: {_eq: $id}}) {
    ...transferFields
    transfer_products {
      ...transferProductFields
    }
  }
}
    ${TransferFieldsFragmentDoc}
${TransferProductFieldsFragmentDoc}`;
export const DeleteTransferDocument = gql`
    mutation DeleteTransfer($id: Int!) {
  delete_transfer_products(where: {transfer_id: {_eq: $id}}) {
    affected_rows
  }
  delete_transfers_by_pk(id: $id) {
    id
  }
}
    `;
export const CreateTransferDocument = gql`
    mutation CreateTransfer($from_location_id: Int!, $to_location_id: Int!, $shop_id: Int!) {
  insert_transfers_one(
    object: {from_location_id: $from_location_id, to_location_id: $to_location_id, shop_id: $shop_id}
  ) {
    id
  }
}
    `;
export const UpdateTransferDocument = gql`
    mutation UpdateTransfer($set: transfers_set_input!, $transfer_id: Int!) {
  update_transfers_by_pk(pk_columns: {id: $transfer_id}, _set: $set) {
    transfer_view {
      ...transferFields
    }
  }
}
    ${TransferFieldsFragmentDoc}`;
export const AddTransferProductsDocument = gql`
    mutation AddTransferProducts($products: [transfer_products_insert_input!]!) {
  insert_transfer_products(objects: $products) {
    affected_rows
  }
}
    `;
export const UpdateTransferProductDocument = gql`
    mutation UpdateTransferProduct($id: Int!, $update: transfer_products_set_input!) {
  update_transfer_products_by_pk(pk_columns: {id: $id}, _set: $update) {
    transfer_product_view {
      ...transferProductFields
    }
  }
}
    ${TransferProductFieldsFragmentDoc}`;
export const DeleteTransferProductsDocument = gql`
    mutation DeleteTransferProducts($products: [Int!]) {
  delete_transfer_products(where: {id: {_in: $products}}) {
    affected_rows
  }
}
    `;
export const GetUserDocument = gql`
    query GetUser($user_id: uuid!) {
  users_by_pk(id: $user_id) {
    id
    shop {
      id
      shop
      bin_locations_active
    }
  }
}
    `;
export const GetVendorsDocument = gql`
    query GetVendors {
  vendors(order_by: {name: asc}) {
    ...vendorFieldsWithSuppliers
  }
}
    ${VendorFieldsWithSuppliersFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    UpsertBinLocation(variables: UpsertBinLocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertBinLocationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertBinLocationMutation>(UpsertBinLocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertBinLocation');
    },
    GetInvoiceForOrder(variables: GetInvoiceForOrderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetInvoiceForOrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInvoiceForOrderQuery>(GetInvoiceForOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetInvoiceForOrder');
    },
    GetInvoice(variables: GetInvoiceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetInvoiceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInvoiceQuery>(GetInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetInvoice');
    },
    FindInvoices(variables: FindInvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindInvoicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindInvoicesQuery>(FindInvoicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindInvoices');
    },
    InsertInvoice(variables: InsertInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertInvoiceMutation>(InsertInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertInvoice');
    },
    DeleteOrderInvoice(variables: DeleteOrderInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOrderInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrderInvoiceMutation>(DeleteOrderInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrderInvoice');
    },
    InsertOrderInvoice(variables: InsertOrderInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertOrderInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertOrderInvoiceMutation>(InsertOrderInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertOrderInvoice');
    },
    DeleteOrderProductInvoice(variables: DeleteOrderProductInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOrderProductInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrderProductInvoiceMutation>(DeleteOrderProductInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrderProductInvoice');
    },
    SendToXero(variables: SendToXeroMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendToXeroMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendToXeroMutation>(SendToXeroDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendToXero');
    },
    UpsertOrderProductInvoices(variables: UpsertOrderProductInvoicesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertOrderProductInvoicesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertOrderProductInvoicesMutation>(UpsertOrderProductInvoicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertOrderProductInvoices');
    },
    UpsertOrderProductInvoicesPaidPerUnit(variables: UpsertOrderProductInvoicesPaidPerUnitMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertOrderProductInvoicesPaidPerUnitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertOrderProductInvoicesPaidPerUnitMutation>(UpsertOrderProductInvoicesPaidPerUnitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertOrderProductInvoicesPaidPerUnit');
    },
    UpsertOrderProductInvoicesQtyReceived(variables: UpsertOrderProductInvoicesQtyReceivedMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertOrderProductInvoicesQtyReceivedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertOrderProductInvoicesQtyReceivedMutation>(UpsertOrderProductInvoicesQtyReceivedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertOrderProductInvoicesQtyReceived');
    },
    UpdateInvoice(variables: UpdateInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateInvoiceMutation>(UpdateInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateInvoice');
    },
    GetInvoices(variables?: GetInvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetInvoicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInvoicesQuery>(GetInvoicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetInvoices');
    },
    DeleteInvoice(variables: DeleteInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteInvoiceMutation>(DeleteInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteInvoice');
    },
    DeleteExpense(variables: DeleteExpenseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteExpenseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteExpenseMutation>(DeleteExpenseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteExpense');
    },
    CreateExpenses(variables: CreateExpensesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateExpensesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateExpensesMutation>(CreateExpensesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateExpenses');
    },
    UpdateExpense(variables: UpdateExpenseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateExpenseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateExpenseMutation>(UpdateExpenseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateExpense');
    },
    GetLocations(variables?: GetLocationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLocationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLocationsQuery>(GetLocationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetLocations');
    },
    GetOrder(variables: GetOrderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOrderQuery>(GetOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrder');
    },
    GetStatsForOrderProducts(variables: GetStatsForOrderProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetStatsForOrderProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStatsForOrderProductsQuery>(GetStatsForOrderProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetStatsForOrderProducts');
    },
    GetStatsForOrderProductsAllLocations(variables: GetStatsForOrderProductsAllLocationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetStatsForOrderProductsAllLocationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStatsForOrderProductsAllLocationsQuery>(GetStatsForOrderProductsAllLocationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetStatsForOrderProductsAllLocations');
    },
    GetProducts(variables?: GetProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductsQuery>(GetProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetProducts');
    },
    GetSupplierAllVendorsProducts(variables: GetSupplierAllVendorsProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSupplierAllVendorsProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSupplierAllVendorsProductsQuery>(GetSupplierAllVendorsProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSupplierAllVendorsProducts');
    },
    GetAllAvailableProducts(variables: GetAllAvailableProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllAvailableProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllAvailableProductsQuery>(GetAllAvailableProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAllAvailableProducts');
    },
    GetSupplierVendorProducts(variables: GetSupplierVendorProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSupplierVendorProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSupplierVendorProductsQuery>(GetSupplierVendorProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSupplierVendorProducts');
    },
    GetSupplierAssignedProducts(variables: GetSupplierAssignedProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSupplierAssignedProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSupplierAssignedProductsQuery>(GetSupplierAssignedProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSupplierAssignedProducts');
    },
    InsertNewOrderPopulateOptions(variables: InsertNewOrderPopulateOptionsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertNewOrderPopulateOptionsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertNewOrderPopulateOptionsMutation>(InsertNewOrderPopulateOptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertNewOrderPopulateOptions');
    },
    InsertNewOrderPopulateVendorProducts(variables: InsertNewOrderPopulateVendorProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertNewOrderPopulateVendorProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertNewOrderPopulateVendorProductsMutation>(InsertNewOrderPopulateVendorProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertNewOrderPopulateVendorProducts');
    },
    InsertNewOrderPopulateSupplierProducts(variables: InsertNewOrderPopulateSupplierProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertNewOrderPopulateSupplierProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertNewOrderPopulateSupplierProductsMutation>(InsertNewOrderPopulateSupplierProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertNewOrderPopulateSupplierProducts');
    },
    InsertNewOrderPopulateCsv(variables: InsertNewOrderPopulateCsvMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertNewOrderPopulateCsvMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertNewOrderPopulateCsvMutation>(InsertNewOrderPopulateCsvDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertNewOrderPopulateCsv');
    },
    DeleteOrder(variables: DeleteOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrderMutation>(DeleteOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrder');
    },
    GetOrders(variables?: GetOrdersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOrdersQuery>(GetOrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrders');
    },
    UpdateOrder(variables: UpdateOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOrderMutation>(UpdateOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOrder');
    },
    UpdateOrderGetProducts(variables: UpdateOrderGetProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOrderGetProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOrderGetProductsMutation>(UpdateOrderGetProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOrderGetProducts');
    },
    DeleteOrderProducts(variables?: DeleteOrderProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOrderProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrderProductsMutation>(DeleteOrderProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrderProducts');
    },
    DeleteOrderProductGetInvoice(variables: DeleteOrderProductGetInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOrderProductGetInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOrderProductGetInvoiceMutation>(DeleteOrderProductGetInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrderProductGetInvoice');
    },
    AddOrderProducts(variables: AddOrderProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddOrderProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddOrderProductsMutation>(AddOrderProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddOrderProducts');
    },
    GetReports(variables?: GetReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReportsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReportsQuery>(GetReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetReports');
    },
    InsertStocktake(variables: InsertStocktakeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertStocktakeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InsertStocktakeMutation>(InsertStocktakeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertStocktake');
    },
    GetStocktake(variables: GetStocktakeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetStocktakeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStocktakeQuery>(GetStocktakeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetStocktake');
    },
    GetStocktakes(variables?: GetStocktakesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetStocktakesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStocktakesQuery>(GetStocktakesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetStocktakes');
    },
    CreateStocktake(variables: CreateStocktakeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateStocktakeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateStocktakeMutation>(CreateStocktakeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateStocktake');
    },
    StartStocktake(variables: StartStocktakeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StartStocktakeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StartStocktakeMutation>(StartStocktakeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'StartStocktake');
    },
    StopCounting(variables: StopCountingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StopCountingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StopCountingMutation>(StopCountingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'StopCounting');
    },
    DeleteStocktake(variables: DeleteStocktakeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteStocktakeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteStocktakeMutation>(DeleteStocktakeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteStocktake');
    },
    UpdateStocktakeProduct(variables: UpdateStocktakeProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateStocktakeProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateStocktakeProductMutation>(UpdateStocktakeProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateStocktakeProduct');
    },
    GetSuppliers(variables?: GetSuppliersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSuppliersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSuppliersQuery>(GetSuppliersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSuppliers');
    },
    CreateSupplierForVariant(variables: CreateSupplierForVariantMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSupplierForVariantMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSupplierForVariantMutation>(CreateSupplierForVariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSupplierForVariant');
    },
    CreateSupplierForVariantReturningPvData(variables: CreateSupplierForVariantReturningPvDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSupplierForVariantReturningPvDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSupplierForVariantReturningPvDataMutation>(CreateSupplierForVariantReturningPvDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSupplierForVariantReturningPvData');
    },
    UpsertSupplierPvsWithSku(variables: UpsertSupplierPvsWithSkuMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertSupplierPvsWithSkuMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertSupplierPvsWithSkuMutation>(UpsertSupplierPvsWithSkuDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertSupplierPvsWithSku');
    },
    DeleteSupplierProducts(variables: DeleteSupplierProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteSupplierProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteSupplierProductsMutation>(DeleteSupplierProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteSupplierProducts');
    },
    FindXeroContact(variables: FindXeroContactQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindXeroContactQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindXeroContactQuery>(FindXeroContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindXeroContact');
    },
    Transfers(variables?: TransfersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransfersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransfersQuery>(TransfersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Transfers');
    },
    Transfer(variables: TransferQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransferQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransferQuery>(TransferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Transfer');
    },
    DeleteTransfer(variables: DeleteTransferMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteTransferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteTransferMutation>(DeleteTransferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTransfer');
    },
    CreateTransfer(variables: CreateTransferMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTransferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTransferMutation>(CreateTransferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTransfer');
    },
    UpdateTransfer(variables: UpdateTransferMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTransferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTransferMutation>(UpdateTransferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTransfer');
    },
    AddTransferProducts(variables: AddTransferProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddTransferProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddTransferProductsMutation>(AddTransferProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddTransferProducts');
    },
    UpdateTransferProduct(variables: UpdateTransferProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTransferProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTransferProductMutation>(UpdateTransferProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTransferProduct');
    },
    DeleteTransferProducts(variables?: DeleteTransferProductsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteTransferProductsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteTransferProductsMutation>(DeleteTransferProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTransferProducts');
    },
    GetUser(variables: GetUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserQuery>(GetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUser');
    },
    GetVendors(variables?: GetVendorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetVendorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetVendorsQuery>(GetVendorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetVendors');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;