import React, { useState, useEffect } from 'react'

import { Dropdown } from '../../DropdownTs'
import { Input } from '../../InputTs'

import { Button } from '../../ButtonTs'
import { Stocktake } from '../../../backend-v3/stocktakes'

type Data = {
  id: number | string,
  label: string
}

type FilterKey = {
  data?: Data[],
  label: string,
  value: keyof Stocktake
}

type Props = {
  filterKeys: FilterKey[],
  stocktakes: Stocktake[],
  setFilteredStocktakes: (x: Stocktake[]) => void
}

type FilterKeyValue = 'none' | keyof Stocktake

type Query = '' | string

const StocktakesFilter = ({ filterKeys, stocktakes, setFilteredStocktakes } : Props) => {
  const [filterKey, setFilterKey] = useState<FilterKeyValue>('none')
  const [query, setQuery] = useState<Query>('')
  
  type Options = {[Property in keyof Stocktake]?: Data[]}

  const dataOptions = getOptionsFromData()

  useEffect(() => {
    setFilteredStocktakes(getFilteredStocktakes(query, stocktakes))
  }, [stocktakes])

  function getFilteredStocktakes(newQuery: Query, stocktakesToFilter: Stocktake[]) {
    // Clear input filter with 'none' filterKey
    if (filterKey === 'none') return stocktakes
    // Clear dropdown filter with 'none' value
    if (newQuery === 'none' && typeof dataOptions[filterKey] !== undefined) return stocktakes
    
    const key = filterKeys.find(({ value }) => value === filterKey)?.value
    if (key === undefined) return stocktakes

    return stocktakesToFilter.filter(stocktake =>
      stocktake[key]?.toString().trim().toLowerCase().includes(newQuery.trim().toLowerCase()),
    )
  }

  function getPlaceholderText() {
    return filterKey !== 'none' ? `Enter a ${filterKey.toLowerCase()}` : 'First select a filter'
  }

  function reset() {
    setFilterKey('none')
    setQuery('none')
    setFilteredStocktakes(stocktakes)
  }

  function onFilterKeyChange(newKey: string) {
    let newKeyTyped = newKey as FilterKeyValue
    if (newKeyTyped === 'none') reset()

    setFilterKey(newKeyTyped)
  }

  function onQueryChange(newQuery: Query) {
    setFilteredStocktakes(getFilteredStocktakes(newQuery, stocktakes))
    setQuery(newQuery)
  }

  function getOptionsFromData() : Options {
    return filterKeys.reduce(
      (acc, cur) => (typeof cur.data !== 'undefined' ? { ...acc, [cur.value]: cur.data } : acc),
      {},
    )
  }

  return (
    <div className="flex items-end">
      <Dropdown
        className="mr-2 w-48"
        id="filter-key"
        label="Filter By"
        onChange={onFilterKeyChange}
        options={[
          { name: 'None', value: 'none' },
          ...filterKeys.map(({ label, value }) => ({ name: label, value })),
        ]}
        value={filterKey}
      />
      {filterKey !== 'none' && dataOptions[filterKey] !== undefined ? (
        <Dropdown
          className="w-64 mr-2"
          id="filter-query"
          label="Query"
          onChange={onQueryChange}
          options={[
            { name: '', value: 'none' },
            ...dataOptions[filterKey]?.map(({ id, label }) => ({ name: label, value: (id.toString()) })) || [],
          ]}
          value={query}
        />
      ) : (
        <Input
          className="w-64 mr-2"
          disabled={filterKey === 'none'}
          id="filter-query"
          label="Query"
          onChange={onQueryChange}
          placeholder={getPlaceholderText()}
          value={query}
        />
      )}
      <div>
        <Button onClick={reset} value="Reset Filter" variant="secondary" />
      </div>
    </div>
  )
}



export { StocktakesFilter }
