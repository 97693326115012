import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { FaSignOutAlt, FaUserCircle } from "react-icons/fa";

import { Logo } from "./SVG/Logo";
import { MenuItem } from "./MenuItem";
import { Routes } from "./Routes";
import { SidebarLink } from "./SidebarLink";

import { logout } from "../backend-v3/auth";
import { useFeedback } from "../hooks/useFeedbackTs";
import { useOverlay, ModalState } from "../hooks/useOverlayTs";

const PageLayout = ({ onLogout, shopUrl }: any) => {
  const { ref, overlay, setOverlay }: ModalState<"menu" | undefined> = useOverlay();
  const { setError } = useFeedback();

  function onMenuClick(e: any) {
    e.stopPropagation();
    setOverlay("menu");
  }

  async function handleLogout() {
    setOverlay(undefined);
    const res = await logout();

    if (res.err) setError(res.val.message);
    if (res.ok) onLogout();
  }

  return (
    <>
      <header className="fixed top-0 left-0 w-full z-20">
        <ul
          ref={ref}
          className={`
            list-none text-white absolute top-full right-0 bg-orange rounded-bl transition duration-200 ease-linear transform min-w-72
            ${overlay === "menu" ? "translate-y-0" : "-translate-y-full"}
          `}
        >
          <MenuItem
            className="capitalize"
            onClick={handleLogout}
            icon={<FaSignOutAlt />}
            value={`Logout of ${shopUrl.split(".my")[0].replace("-", " ")}`}
          />
        </ul>
        <div className="w-full bg-black flex justify-between items-stretch relative">
          <Link className="h-10 text-white px-3 py-1 inline-block w-24 flex items-center" to="/orders">
            <Logo fill={"#fff"} />
          </Link>
          <div className="px-2 flex items-center">
            <div
              className="px-3 cursor-pointer h-full flex items-center transition duration-200 ease-linear text-xl text-white"
              onClick={onMenuClick}
            >
              <FaUserCircle />
            </div>
          </div>
        </div>
      </header>
      <aside className="bg-gray-100 fixed h-screen top-0 w-64 z-10 flex flex-col justify-between pt-10">
        <nav className="w-full">
          <SidebarLink className="" to="/orders">
            Purchase Orders
          </SidebarLink>
          <SidebarLink className="" to="/invoices">
            Invoices
          </SidebarLink>
          <SidebarLink className="" to="/transfers">
            Transfers
          </SidebarLink>
          <SidebarLink className="" to="/stocktakes">
            Stocktakes
          </SidebarLink>
          <SidebarLink className="" to="/reports">
            Reports
          </SidebarLink>
          <SidebarLink className="" to="/suppliers">
            Suppliers
          </SidebarLink>
          <SidebarLink className="" to="/vendors">
            Vendors
          </SidebarLink>
          <SidebarLink className="" to="/products">
            Products
          </SidebarLink>
          <SidebarLink className="" to="/integrations">
            Integrations
          </SidebarLink>
        </nav>
      </aside>
      <div className="pl-64 mt-10">
        <div className="px-5 py-4">
          <Routes />
        </div>
      </div>
    </>
  );
};

export { PageLayout };
