import React from "react";
import { Router, Redirect } from "@reach/router";

import { ControlPanel } from "../pages/ControlPanel";
import { Integrations } from "../pages/Integrations";
import { NotFound } from "../pages/NotFound";
import { Order } from "../pages/OrderTs";
import { Orders } from "../pages/Orders";
import { Invoice } from "../pages/Invoice";
import { Stocktake } from "../pages/Stocktake";
import { Stocktakes } from "../pages/Stocktakes";
import { Supplier } from "../pages/Supplier";
import { Suppliers } from "../pages/Suppliers";
import { SupplierVendor } from "../pages/SupplierVendor";
import { Transfers } from "../pages/TransfersTs";
import { Transfer } from "../pages/TransferTs";
import { Reports } from "../pages/Reports";
import { Vendor } from "../pages/Vendor";
import { Vendors } from "../pages/Vendors";
import { Invoices } from "../pages/Invoices";
import { Products } from "../pages/Products";

const Routes = () => (
  <Router>
    <NotFound default />
    <Redirect from="/" to="/orders" noThrow />
    <Orders path="/orders" />
    <Order path="/orders/:orderId" parent={{ to: "/orders", value: "Orders" }} />
    <Invoices path="/invoices" />
    <Invoice path="/invoices/:invoiceId" parent={{ to: "/invoices", value: "Invoices" }} />
    <Stocktakes path="/stocktakes" />
    <Stocktake path="/stocktakes/:stocktakeId" parent={{ to: "/stocktakes", value: "Stocktakes" }} />
    <Suppliers path="/suppliers" />
    <Supplier path="/suppliers/:supplierId" parent={{ to: "/suppliers", value: "Suppliers" }} />
    <SupplierVendor path="/suppliers/:supplierId/:vendorId" />
    <Transfers path="/transfers" />
    <Transfer path="/transfers/:transferId" parent={{ to: "/transfers", value: "Transfers" }} />
    <Reports path="/reports" />
    <Vendors path="/vendors" parent={{ to: "/vendors", value: "Vendors" }} />
    <Vendor path="/vendors/:vendorId" parent={{ to: "/vendors", value: "Vendors" }} />
    <Products path="/products" />
    <Integrations path="/integrations" parent={{ to: "/integrations", value: "Integrations" }} />
  </Router>
);

export { Routes };
