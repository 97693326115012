import React from 'react'
import PropTypes from 'prop-types'
import { FaAngleDown } from 'react-icons/fa'

// todo: allow any type for onChange x arg and options value + stricter typing
type Props = {
  disabled?: boolean,
  className?: string,
  id: string,
  label: string,
  onChange: (x: string) => void,
  options: { disabled?: boolean, name: string, value: string }[],
  value: string | undefined
}
const Dropdown = ({ className = '', disabled = false, id, label, onChange, options, ...props } : Props ) =>  {
  return (
    <div className={className}>
      <label className="font-bold text-sm" htmlFor={id}>
        {label}
      </label>
      <div className="relative mt-1">
        <select
          id={id}
          onChange={e => onChange(e.target.value)}
          className={`block capitalize rounded cursor-${disabled ? 'default' : 'pointer'
            } appearance-none bg-white w-full border py-2 pl-3 pr-8 focus:outline-none focus:border-black disabled:border-gray-300 disabled:text-gray-300`}
          disabled={disabled}
          {...props}
        >
          <option value="" disabled hidden></option>

          {options.map(({ disabled, name, value }) => (
            <option disabled={disabled} key={name} value={value}>
              {name}
            </option>
          ))}
        </select>
        <div
          className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 ${disabled ? 'text-gray-300' : ''
            }`}
        >
          <FaAngleDown />
        </div>
      </div>
    </div>
  )
}


export { Dropdown }
