import React, { useEffect, useState } from "react";

import { Loading } from "../components/Loading";
import { LoadingGlobal } from "../components/LoadingGlobal";
import { NotFound } from "./NotFound";
import { StocktakeHeader } from "../components/Layouts/Stocktake/StocktakeHeader";
import { StocktakeBeginModal } from "../components/Layouts/Stocktake/StocktakeBeginModal";
import { StocktakeCompleteModal } from "../components/Layouts/Stocktake/StocktakeCompleteModal";
import { StocktakePrintable } from "../components/Layouts/Stocktake/StocktakePrintable";
import { StocktakeTable } from "../components/Layouts/Stocktake/StocktakeTable";
import { StocktakeTotals } from "../components/Layouts/Stocktake/StocktakeTotals";

import { useLocations } from "../hooks/useLocationsTs";
import { useStocktake } from "../hooks/useStocktakeTs";
import { ModalState, useOverlay } from "../hooks/useOverlayTs";
import { Parent } from "../common/types";

export type OverlayState = "begin" | "complete" | undefined;

const Stocktake = ({ stocktakeId, parent }: { stocktakeId: number; parent: Parent }) => {
  const [print, setPrint] = useState(false);
  const { ref, overlay, setOverlay }: ModalState<OverlayState> = useOverlay();
  const { locations, isFetchingLocations } = useLocations();

  const {
    beginStocktake,
    completeStocktake,
    stocktake,
    isFetchingStocktake,
    isStocktakeModalLoading,
    isUpdatingStocktake,
    notFound,
    updateStocktakeProduct,
  } = useStocktake(stocktakeId);

  const isDraft = stocktake && stocktake.stocktake.status === "draft";

  useEffect(() => {
    if (stocktake && !isStocktakeModalLoading) setOverlay(undefined);
  }, [isStocktakeModalLoading]);

  if (notFound) return <NotFound />;
  if (!stocktake || !locations || isFetchingStocktake || isFetchingLocations) return <Loading />;

  const Headings = () => (
    <div className="mt-8 mb-4">
      <h2 className="mb-2">
        <span className="font-bold">Stock Location</span>:{" "}
        <span className="capitalize">{stocktake.stocktake.location.name}</span>
      </h2>
      <h2>
        <span className="font-bold">Vendor</span>:{" "}
        <span className="capitalize">{stocktake.stocktake.vendor?.name ?? "All Vendors"}</span>
      </h2>
    </div>
  );

  const Totals = () => (
    <div className="flex justify-end">
      <div className="ml-4 w-full max-w-lg">
        <StocktakeTotals stocktake={stocktake} />
      </div>
    </div>
  );

  return !print ? (
    <div className="max-w-site mb-12">
      <StocktakeHeader
        onPrintClick={() => setPrint(prev => (prev ? false : true))}
        parent={parent}
        stocktake={stocktake}
        setModal={setOverlay}
      />
      <Headings />
      <StocktakeTable stocktake={stocktake} updateStocktakeProduct={updateStocktakeProduct} />
      {!isDraft && <Totals />}
      {overlay && (
        <div ref={ref} className="fixed top-0 right-0 w-full h-screen z-50">
          <StocktakeBeginModal
            isOpen={overlay === "begin"}
            loading={isStocktakeModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={beginStocktake}
          />
          <StocktakeCompleteModal
            isOpen={overlay === "complete"}
            loading={isStocktakeModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={completeStocktake}
          />
        </div>
      )}
      <LoadingGlobal loading={isUpdatingStocktake} />
    </div>
  ) : (
    <StocktakePrintable onClose={() => setPrint(false)}>
      <Headings />
      <StocktakeTable printable stocktake={stocktake} updateStocktakeProduct={updateStocktakeProduct} />
    </StocktakePrintable>
  );
};

export { Stocktake };
